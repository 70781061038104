import React, { useContext, useState } from "react";
import AddImageForBanner from "../../../../components/Admin/AddImage/AddImagesForbanner";
import { Button } from "@mui/material";
import SliderContext from "../../../../context/SlideContext";

export default function CreateBanner(props) {
  //context
  const bannerCtx = useContext(SliderContext);
  //states
  const [selectedImage, setSelectedImage] = useState(null);

  const getFile = (file) => {
    setSelectedImage(file);
    // bannerCtx.createBanners(file);
  };

  const onSubmit = () => {
    bannerCtx.createBanners(selectedImage);
    props.handleViews("createBanner");
  };
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Шинэ Баннер үүсгэх</h1>
      <AddImageForBanner height={"450px"} getFile={getFile} />
      <Button
        className="mt-3 text-center"
        variant="contained"
        onClick={() => {
          onSubmit();
        }}
      >
        Үүсгэх
      </Button>
    </div>
  );
}
