import React, { useContext, useState, useRef } from "react";
import css from "./style.module.css";

import GlobalContext from "../../../context/GlobalContext";
import ProductContext from "../../../context/ProductContext";
import BucketContext from "../../../context/BucketContext";
import UserContext from "../../../context/UserContext";

import ProductDetails from "../ProductDetail";
import UserLogin from "../UserLogin";
import noImageIcon from "../../../assets/images/product/no-img.png";

const Product = (props) => {
  // console.log(props.Product);
  //states
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);

  //refs
  const mouseOverRef = useRef();

  //contexts
  const myBucketCtx = useContext(BucketContext);
  const globalCtx = useContext(GlobalContext);
  const productCtx = useContext(ProductContext);
  const userCtx = useContext(UserContext);

  const toggleLoginForm = () => {
    setShowLoginForm((prevState) => !prevState);
  };
  const toggleProductDetail = () => {
    setShowProductDetail((prevState) => !prevState);
  };
  const showAlertBox = () => {
    mouseOverRef.current.style.display = "block";
  };
  const hideAlertBox = () => {
    mouseOverRef.current.style.display = "none";
  };
  const toggleRegisterForm = () => {
    window.open(
      "https://b24-tuh947.bitrix24.site/",
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
    );
  };
  const createBucket = (
    id,
    piece,
    QtyPerpackage,
    listPrice,
    code,
    image,
    name,
    categoryName,
    brandName,
    Qty,
    Percentage,
    WholeQty,
    pre_order,
    type,
    scheduledDate,
  ) => {
    if (pre_order) {
      if (piece > 0) {
        productCtx.clearProduct(id);
        myBucketCtx.addBucket(
          id,
          piece,
          QtyPerpackage,
          listPrice,
          code,
          image,
          name,
          categoryName,
          brandName,
          Qty,
          Percentage,
          WholeQty,
          pre_order,
          type,
          scheduledDate
        );
      }
    }
    else {
      if (piece > 0) {
        productCtx.clearProduct(id);
        myBucketCtx.addBucket(
          id,
          piece,
          QtyPerpackage,
          listPrice,
          code,
          image,
          name,
          categoryName,
          brandName,
          Qty,
          Percentage,
          WholeQty,
          pre_order,
          type,
          // ScheduledDate
        );
      }
    }

  };
  // useEffect(() => {
  //   if (props.Product.Qty === "-") {
  //     productCtx.getProductBalance(props.Product.Id);
  //   }
  // }, [globalCtx.state.searchValue]);
  let percent
  if (props.Product.WholePrice != 0) {
    percent = Math.round(
      ((props.Product.ListPrice - props.Product.WholePrice) / props.Product.ListPrice) * 100
    )
  }
  else {
    percent = 0
  }


  return (
    <div
      style={
        (props.Product.Qty && props.Product.Qty > 0) || props.Product.Piece > 0
          ? {}
          : { opacity: "0.5", cursor: "not-allowed" }
      }
      className={globalCtx.state.threeView ? css.Product : css.ProductSmall}
    >
      {/* <div ref={mouseOverRef} className={css.CustomWaring}>
        Та {props.Product.WholeQty}-аас дээш бүтээгдхүүн авбал нэгж үнэ{" "}
        {props.Product.WholePrice.toLocaleString()}₮-өөр авах боломжтой.
      </div> */}
      <img
        style={
          (props.Product.Qty && props.Product.Qty > 0) ||
            props.Product.Piece > 0
            ? {}
            : { cursor: "not-allowed" }
        }
        className={
          globalCtx.state.threeView ? css.ProductImage : css.ProductImageSmall
        }
        src={
          props.Product.DetailImages &&
            props.Product.DetailImages.length > 0
            ? process.env.REACT_APP_BASE_URL +
            "/public/image/products/" +
            props.Product.DetailImages[0]
            : noImageIcon
        }
        alt="product"
        onClick={() => {
          if (props.Product.Qty && props.Product.Qty > 0) {
            toggleProductDetail();
          }
        }}
      />
      <p className={css.ProductCode}>
        Код:<span> {props.Product.Code}</span>
      </p>
      {props.Product.ScheduledDate && (
        <p className={css.ProductSchedule}>
          Ирэх өдөр:<span> {props.Product.ScheduledDate.substring(0, 10)}</span>
        </p>
      )}
      {props.Product.DiscountPercentage ? (
        <p className={css.ProductDiscountPercentage}>
          -{props.Product.DiscountPercentage}%
        </p>
      ) : (
        <p style={{ display: "none" }}></p>
      )}
      <div className={css.ProductDetail}>
        <div className={css.ProductTitle}>
          <p>{props.Product.Name}</p>
        </div>
        <div className={css.detailDivide}>
          <div
            className={
              globalCtx.state.threeView
                ? css.ProductRemain
                : css.ProductRemainSmall
            }
          >
            <p>
              Үлдэгдэл:
              {userCtx.state.userId ? (
                <span>{props.Product.Qty} Ш</span>
              ) : (
                <span>-- Ш</span>
              )}
            </p>
            <p>
              1 багцанд: <span>{props.Product.QtyPerpackage} Ш</span>
            </p>
          </div>
          <div>
            <p>Нэгж үнэ:</p>
            {userCtx.state.userId ? (
              <p>
                {props.Product.CategoryName === "Хямдарсан"
                  ? props.Product.ListPrice.toLocaleString()
                  : props.Product.Piece * props.Product.QtyPerpackage >=
                    props.Product.WholeQty && props.Product.WholePrice > 0
                    ? props.Product.WholePrice.toLocaleString()
                    : props.Product.ListPrice.toLocaleString()}
                ₮
              </p>
            ) : (
              <p>-,---₮</p>
            )}
          </div>
        </div>
        <div
          className={
            globalCtx.state.threeView
              ? css.ProductCalculate
              : css.ProductCalculateSmall
          }
        >
          <div className={userCtx.state.userId
            ? (props.Product.CategoryName !== "Хямдарсан" ? css.customBtn : css.widerBtn)
            : css.widerBtn
          }>
            <button
              style={
                userCtx.state.userId &&
                  ((props.Product.Qty && props.Product.Qty > 0) ||
                    props.Product.Piece > 0)
                  ? {}
                  : { cursor: "not-allowed" }
              }
              className={css.minusButton}
              onClick={() => {
                if (
                  userCtx.state.userId &&
                  ((props.Product.Qty && props.Product.Qty > 0) ||
                    props.Product.Piece > 0)
                ) {
                  if (props.Product.ScheduledDate) {
                    productCtx.decrementTotalOrder(
                      props.Product.Id,
                      props.Product.ScheduledDate
                    );
                  } else {
                    productCtx.decrementTotalOrder(props.Product.Id, null);
                  }
                }
              }}
            ></button>
            {props.Product.CategoryName !== "Хямдарсан" ? (
              <p>{props.Product.Piece} багц</p>
            ) : (
              <p>{props.Product.Piece} ширхэг</p>
            )}
            <button
              style={
                userCtx.state.userId &&
                  ((props.Product.Qty && props.Product.Qty > 0) ||
                    props.Product.Piece > 0)
                  ? {}
                  : { cursor: "not-allowed" }
              }
              className={css.addButton}
              onClick={() => {
                if (
                  userCtx.state.userId &&
                  props.Product.Qty &&
                  props.Product.Qty > 0
                ) {
                  if (props.Product.ScheduledDate) {
                    productCtx.incrementTotalOrder(
                      props.Product.Id,
                      props.Product.ScheduledDate
                    );
                  } else {
                    productCtx.incrementTotalOrder(props.Product.Id, null);
                  }
                }
              }}
            ></button>
          </div>
          {/* <p>+ {props.Product.WholeQty} Ш</p> */}
          {/* <div style={
            userCtx.state.userId ? {}
              : { display: "none" }
          }>
            <span style={{ color: "red" }}>{props.Product.WholeQty}ш</span>-с дээш
            <br />
            нэгж үнэ{" "}
            <span style={{ color: "red" }}>{props.Product.WholePrice.toLocaleString()}₮</span>
          </div> */}
          {props.Product.CategoryName === "Хямдарсан" ? (
            <div style={{ display: "none" }}>
              <span style={{ color: "red" }}>{props.Product.WholeQty}ш</span>-с
              дээш
              <br />
              нэгж үнэ{" "}
              <span style={{ color: "red" }}>
                {props.Product.WholePrice.toLocaleString()}₮
              </span>
            </div>
          ) : (
            <div style={userCtx.state.userId ? {} : { display: "none" }}>
              <span style={{ color: "red" }}>{props.Product.WholeQty}ш</span>-с
              дээш
              <br />
              нэгж үнэ{" "}
              <span style={{ color: "red" }}>
                {props.Product.WholePrice.toLocaleString()}₮
              </span>
            </div>
          )}

          {/* <button
            style={
              userCtx.state.userId &&
                ((props.Product.Qty && props.Product.Qty > 0) ||
                  props.Product.Piece > 0)
                ? {}
                : { cursor: "not-allowed" }
            }
            className={css.bucketButton}
            onClick={() => {
              if (userCtx.state.userId && props.Product.Qty >= 0) {
                createBucket(
                  props.Product.Id,
                  props.Product.Piece,
                  props.Product.QtyPerpackage,
                  props.Product.CategoryName === "Хямдарсан"
                    ? props.Product.ListPrice
                    : props.Product.Piece * props.Product.QtyPerpackage >=
                      props.Product.WholeQty && props.Product.WholePrice > 0
                      ? props.Product.WholePrice
                      : props.Product.ListPrice,
                  props.Product.Code,
                  props.Product.DetailImages[0],
                  props.Product.Name,
                  props.Product.CategoryName,
                  props.Product.BrandName,
                  props.Product.WholeQty,
                  // props.Product.pre_order,
                  props.Product.CategoryName === "Урьдчилсан" ? true : false,
                  // props.Product.type,
                  props.Product.CategoryName === "Хямдарсан"
                    ? "Хямдарсан"
                    : "Хямдраагүй"
                );
              }
            }}
          ></button> */}
        </div>
        <div
          className={
            globalCtx.state.threeView
              ? css.ProductDiscount
              : css.ProductDiscountSmall
          }
        >
          {/* <button
            onMouseOver={(e) => {
              showAlertBox();
            }}
            onMouseOut={(e) => {
              hideAlertBox();
            }}
            className={css.Warning}
          ></button> */}
          {userCtx.state.userId ? (
            <div style={{ width: "100%", paddingLeft: "15px" }}>
              <p>
                {props.Product.Piece * props.Product.QtyPerpackage} ширхэг{" "}
                <span>X</span>{" "}
                {(props.Product.CategoryName === "Хямдарсан"
                  ? props.Product.ListPrice.toLocaleString()
                  : props.Product.Piece * props.Product.QtyPerpackage >=
                    props.Product.WholeQty && props.Product.WholePrice > 0
                    ? props.Product.WholePrice.toLocaleString()
                    : props.Product.ListPrice.toLocaleString())}

                ₮ =
                <span>
                  {(
                    props.Product.Piece *
                    props.Product.QtyPerpackage *
                    (props.Product.CategoryName === "Хямдарсан"
                      ? props.Product.ListPrice
                      : props.Product.Piece * props.Product.QtyPerpackage >=
                        props.Product.WholeQty && props.Product.WholePrice > 0
                        ? props.Product.WholePrice
                        : props.Product.ListPrice)
                  ).toLocaleString()}
                  ₮
                </span>
              </p>
              {props.Product.CategoryName === "Хямдарсан" ? (
                <p>
                  <span>(хямдралтай бараа)</span>
                </p>
              ) : props.Product.WholeQty > 0 &&
                props.Product.Piece * props.Product.QtyPerpackage >=
                props.Product.WholeQty &&
                props.Product.WholePrice > 0 ? (
                <p>
                  <span>(хөнгөлөгдсөн)</span>
                </p>
              ) : (
                <p>
                  <span>(хөнгөлөгдөөгүй)</span>
                </p>
              )}
            </div>
          ) : (
            <div className={css.unsignedText}>
              <p>
                Та нэвтрэн барааны <br />
                үнийг харах боломжтой
              </p>
            </div>
          )}
          {userCtx.state.userId ? (
            <button
              style={
                userCtx.state.userId &&
                  ((props.Product.Qty && props.Product.Qty > 0) ||
                    props.Product.Piece > 0)
                  ? {}
                  : { cursor: "not-allowed" }
              }
              className={
                props.Product.Piece > 0 ? css.bucketButton : css.disableBucket
              }
              onClick={() => {
                if (userCtx.state.userId && props.Product.Qty >= 0) {
                  console.log(props.Product.CategoryName);
                  if (props.Product.CategoryName === "Урьдчилсан") {
                    createBucket(
                      props.Product.Id,
                      props.Product.Piece,
                      props.Product.QtyPerpackage,
                      props.Product.CategoryName === "Хямдарсан"
                        ? props.Product.ListPrice
                        : props.Product.Piece * props.Product.QtyPerpackage >=
                          props.Product.WholeQty && props.Product.WholePrice > 0
                          ? props.Product.WholePrice
                          : props.Product.ListPrice,
                      props.Product.Code,
                      props.Product.DetailImages[0],
                      props.Product.Name,
                      props.Product.CategoryName,
                      props.Product.BrandName,
                      props.Product.Qty,
                      percent,
                      props.Product.WholeQty,
                      // props.Product.pre_order,
                      props.Product.CategoryName === "Урьдчилсан" ? true : false,
                      // props.Product.type,
                      props.Product.CategoryName === "Хямдарсан"
                        ? "Хямдарсан"
                        : "Хямдраагүй",
                      props.Product.ScheduledDate.substring(0, 10)
                    );
                  }
                  else {
                    createBucket(
                      props.Product.Id,
                      props.Product.Piece,
                      props.Product.QtyPerpackage,
                      props.Product.CategoryName === "Хямдарсан"
                        ? props.Product.ListPrice
                        : props.Product.Piece * props.Product.QtyPerpackage >=
                          props.Product.WholeQty && props.Product.WholePrice > 0
                          ? props.Product.WholePrice
                          : props.Product.ListPrice,
                      props.Product.Code,
                      props.Product.DetailImages[0],
                      props.Product.Name,
                      props.Product.CategoryName,
                      props.Product.BrandName,
                      props.Product.Qty,
                      percent,
                      props.Product.WholeQty,
                      // props.Product.pre_order,
                      props.Product.CategoryName === "Урьдчилсан" ? true : false,
                      // props.Product.type,
                      props.Product.CategoryName === "Хямдарсан"
                        ? "Хямдарсан"
                        : "Хямдраагүй",
                      // props.Product.ScheduledDate.substring(0, 10)
                    );
                  }

                }
              }}
            ></button>
          ) : (
            <div
              onClick={() => {
                toggleLoginForm();
              }}
              className={css.Login}
            >
              {/* <img src={usericon} alt="usericon" /> */}
              <span>Нэвтрэх</span>
            </div>
          )}
        </div>
      </div>
      {showProductDetail && (
        <ProductDetails
          // showPreOrderOverAlert={props.showPreOrderOverAlert}
          // showPreOrderStartAlert={props.showPreOrderStartAlert}
          ProductDetails={props.Product}
          toggleProductDetail={toggleProductDetail}
        />
      )}
      {showLoginForm && (
        <UserLogin
          toggleLoginForm={toggleLoginForm}
          toRegisterForm={toggleRegisterForm}
        />
      )}
    </div>
  );
};

export default Product;
