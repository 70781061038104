import React, { useState, useContext, useEffect } from "react";

import { Box, CircularProgress, Fab, Modal, Typography } from "@mui/material";

import { Check, Edit } from "@mui/icons-material";

import green from "@mui/material/colors/green";

import SlideContext from "../../../context/SlideContext";

export default function ProductActions({
  params,
  rowId,
  setRowId,
  editProduct,
}) {
  //contexts
  const bannerCtx = useContext(SlideContext);

  //states

  return (
    <>
      <Box sx={{ m: 1, position: "relative" }}>
        <>
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
            }}
            onClick={() => {
              editProduct(
                params.row.Id,
                params.row.ScheduledDate,
                params.row.Code
              );
            }}
          >
            <Edit />
          </Fab>
        </>
      </Box>
    </>
  );
}


