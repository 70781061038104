import React, { useContext, useState, useEffect } from "react";
import css from "./style.module.css";
import CategoryContext from "../../../context/CategoyContext";
import GlobalContext from "../../../context/GlobalContext";
import ProductContext from "../../../context/ProductContext";
import colorTag from "../../../assets/images/product/color-swatch.png";
import dollarTag from "../../../assets/images/product/dollar-circle.png";
import typeTag from "../../../assets/images/product/tag-2.png";

const OrderSidebar = (props) => {
  // local state
  const initialState = {
    sort_product_type: {
      1: { key: 1, checked: false, name: "Бүгд" },
      2: { key: 2, checked: false, name: "Хүлэмж" },
      3: { key: 3, checked: false, name: "Тариаланч" },
    },
    sort_product_size: {
      1: { key: 1, checked: false, name: "Бүгд", val1: 0, val2: 1000 },
      2: { key: 2, checked: false, name: "0-9 см", val1: 0, val2: 9 },
      3: { key: 3, checked: false, name: "10-19 см", val1: 10, val2: 19 },
      4: { key: 4, checked: false, name: "20-29 см", val1: 20, val2: 29 },
      5: { key: 5, checked: false, name: "30-39 см", val1: 30, val2: 39 },
      6: { key: 6, checked: false, name: "40-49 см", val1: 40, val2: 49 },
      7: { key: 7, checked: false, name: "50-59 см", val1: 50, val2: 59 },
      8: { key: 8, checked: false, name: "60-69 см", val1: 60, val2: 69 },
      9: { key: 9, checked: false, name: "70-79 см", val1: 70, val2: 79 },
      10: { key: 10, checked: false, name: "80-89 см", val1: 80, val2: 89 },
      11: { key: 11, checked: false, name: "90-99 см", val1: 90, val2: 99 },
      12: { key: 12, checked: false, name: "100-109 см", val1: 100, val2: 109 },
      13: { key: 13, checked: false, name: "110-119 см", val1: 110, val2: 119 },
      14: { key: 14, checked: false, name: "120 дээш", val1: 120, val2: 1000 },
    },
    sort_product_color: {
      1: { key: 1, checked: false, color: "#FFFFFF", name: "Бүгд", val1: "" },
      2: {
        key: 2,
        checked: false,
        color: "#FFC0CB",
        name: "Ягаан",
        val1: "Ягаан",
      },
      3: {
        key: 3,
        checked: false,
        color: "#FFA500",
        name: "Улбар шар",
        val1: "Улбар шар",
      },
      4: {
        key: 4,
        checked: false,
        color: "#FFFFFF",
        name: "Цагаан",
        val1: "Цагаан",
      },
      5: { key: 5, checked: false, color: "#A52A2A", name: "Бор", val1: "Бор" },
      6: {
        key: 6,
        checked: false,
        color: "#FF0000",
        name: "Улаан",
        val1: "Улаан",
      },
      7: { key: 7, checked: false, color: "#FFFF00", name: "Шар", val1: "Шар" },
      8: {
        key: 8,
        checked: false,
        color: "#008000",
        name: "Ногоон",
        val1: "Ногоон",
      },
      9: {
        key: 9,
        checked: false,
        color: "#40b2e3",
        name: "Цэнхэр",
        val1: "Цэнхэр",
      },
    },
    sort_product_origin: {
      1: { key: 1, checked: false, name: "Бүгд" },
      2: { key: 2, checked: false, name: "Хүлэмж" },
      3: { key: 3, checked: false, name: "Тариаланч" },
      4: { key: 4, checked: false, name: "Голланд" },
      5: { key: 5, checked: false, name: "Түрк" },
    },
    sort_product_price: {
      1: { key: 1, checked: false, name: "Бүгд", val1: 0, val2: 5000 },
      2: {
        key: 2,
        checked: false,
        name: "5,000 - 10,000",
        val1: 5000,
        val2: 10000,
      },
      3: {
        key: 3,
        checked: false,
        name: "10,000 - 20,000",
        val1: 10000,
        val2: 20000,
      },
      4: {
        key: 4,
        checked: false,
        name: "20,000 - 40,000",
        val1: 20000,
        val2: 40000,
      },
      5: {
        key: 5,
        checked: false,
        name: "40,000 - 60,000",
        val1: 40000,
        val2: 60000,
      },
      6: {
        key: 6,
        checked: false,
        name: "60,000 - 10,0000",
        val1: 60000,
        val2: 100000,
      },
      7: {
        key: 7,
        checked: false,
        name: "100,000 - 200,000",
        val1: 100000,
        val2: 200000,
      },
      8: {
        key: 8,
        checked: false,
        name: "200,000 дээш",
        val1: 200000,
        val2: 10000000,
      },
    },
  };

  // contexts
  const categoryCtx = useContext(CategoryContext);
  // states
  const [sortItem, setSortItem] = useState(initialState);
  const [selectedSortType, setSelectedSortType] = useState("");

  const sort_product_type = Object.entries(sortItem.sort_product_type);
  const sort_product_price = Object.entries(sortItem.sort_product_price);
  const sort_product_color = Object.entries(sortItem.sort_product_color);

  const handleTypeChange = (event) => {
    const selectedKey = event.target.value;
    const selectedOption = Object.entries(sortItem.sort_product_type).find(
      ([key, value]) => value.key.toString() === selectedKey
    );
    if (selectedOption) {
      const { key, name } = selectedOption[1];
      const sortItemArray = [
        {
          type: "sort_product_type",
          selectAll: false,
          value: name,
        },
      ];
      props.sortProducts(sortItemArray);
    }
  };

  const handleColorChange = (event) => {
    const selectedKey = event.target.value;
    const selectedOption = Object.entries(sortItem.sort_product_color).find(
      ([key, value]) => value.key.toString() === selectedKey
    );
    if (selectedOption) {
      const { key, name } = selectedOption[1];
      const sortItemArray = [
        {
          type: "sort_product_color",
          selectAll: false,
          value: name,
        },
      ];
      props.sortProducts(sortItemArray);
    }
  };
  const handlePriceRangeChange = (event) => {
    const selectedKey = event.target.value;
    const selectedOption = Object.entries(sortItem.sort_product_price).find(
      ([key, value]) => value.key.toString() === selectedKey
    );
    if (selectedOption) {
      const { key, name, val1, val2 } = selectedOption[1];
      const sortItemArray = [
        {
          type: "sort_product_price",
          val1: val1,
          val2: val2,
          selectAll: false,
        },
      ];
      props.sortProducts(sortItemArray);
    }
  };

  useEffect(() => {
    let sort_product_type_init = {};
    categoryCtx.state.subCategories.map((el, index) => {
      sort_product_type_init[index + 2] = {
        key: index + 2,
        name: el.name,
        val1: el.name,
      };
    });

    // Add "Select All" option
    sort_product_type_init[0] = {
      key: 0,
      name: "Бүгд",
      val1: "Бүгд",
    };

    setSortItem({
      ...sortItem,
      sort_product_type: sort_product_type_init,
    });
  }, [categoryCtx.state.subCategories]);


  return (
    <div className={css.OrderSidebar}>
      <section>
        <article className={css.cusArticle}>
          <div>
            <div className={css.filterTitle}>Барааны төрөл</div>
            <div className={css.SelectContainer} open={false}>
              <span className={css.Icon}>
                <img
                  src={typeTag}
                  alt="product"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </span>
              <select
                onChange={handleTypeChange}
                className={css.Select}
              >
                {/* <option value="" disabled defaultValue>
                  Барааны төрөл
                </option> */}
                {sort_product_type.map((el, index) => (
                  <option key={index} value={el[1].key}>
                    {el[1].name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <div className={css.filterTitle}>Барааны өнгө</div>
            <div className={css.SelectContainer} open={false}>
              <span className={css.Icon}>
                <img
                  src={colorTag}
                  alt="product"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </span>
              {/* <select
              onChange={(event) => handleColorChange(event)}
              value={selectedSortType}
              className={css.Select}
            >
              <option value="" disabled defaultValue>
                Барааны өнгө
              </option>
              {sort_product_color.map((el, index) => (
                <option key={index} value={el[1].key}>
                  {el[1].name}
                </option>
              ))}
            </select> */}
              <select
                onChange={handleColorChange}
                className={css.Select}
              >
                {/* <option value="" disabled defaultValue>
                  Барааны өнгө
                </option> */}
                {sort_product_color.map((el, index) => (
                  <option key={index} value={el[1].key}>
                    {el[1].name}
                  </option>
                ))}
              </select>

            </div>
          </div>
          <div>
            <div className={css.filterTitle}>Барааны үнэ</div>
            <div className={css.SelectContainer} open={false}>
              <span className={css.Icon}>
                <img
                  src={dollarTag}
                  alt="product"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </span>
              <select
                onChange={handlePriceRangeChange}
                className={css.Select}
              >
                {/* <option value="" disabled defaultValue>
                  Барааны үнэ
                </option> */}
                {sort_product_price.map((el, index) => (
                  <option key={index} value={el[1].key}>
                    {el[1].name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </article>
      </section>
    </div>
  );
};

export default OrderSidebar;
