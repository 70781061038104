import React, { useState, useEffect, useContext } from "react";
import LayoutContext from "../../context/LayoutContext";
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import css from "./style.module.css";
import BannerPage from "./BannerPage";
import BlogPage from "./BlogPage";
import OrderPage from "./OrderPage";
import PreOrderPage from "./PreOrderPage";
import UserPage from "./UserPage";
import Dashboard from "./Dashboard";
import Product from "./ProductPage";
import PreProduct from "./PreProductPage";
import BankPage from "./BankPage";
import CategoryPage from "./Category";
import MainCategoryPage from "./MainCategory";
import HelperPage from "./Helper";
import HelpBannerPage from "./HelpBanner";
import CheckOrderPage from "./CheckOrderPage"
import PreOrderEdit from "./PreOrderEdit"
import CalendarPage, { CalendarTime } from "./CalendarTime";
import AboutUsPage from "./Aboutus";
import ContactusPage from "./Contact";
import EqualPage from "./EqualPage"


export default function AdminPage() {
  //states
  const [showContent, setShowContent] = useState({
    dashboard: true,
    banner: false,
    bank: false,
    blog: false,
    order: false,
    preOrder: false,
    user: false,
    product: false,
    preProduct: false,
    category: false,
    mainCategory: false,
    help: false,
    helpBanner: false,
    calendar: false,
    helpBanner: false,
    about: false,
    contact: false,
    check: false,
    orderEdit: false,
    equal: false,
  });

  //contexts
  const layoutCtx = useContext(LayoutContext);

  useEffect(() => {
    layoutCtx.initLayout(false, false, false);
    return () => {
      layoutCtx.clearLayout();
    };
  }, []);

  const setPageContent = (page) => {
    setShowContent({
      dashboard: false,
      [page]: true,
    });
  };

  return (
    <div className={css.AdminPage}>
      <Header />
      {showContent.dashboard}
      <Menu setPageContent={setPageContent} activeMenu={showContent} />
      {showContent.dashboard && <Dashboard setPageContent={setPageContent} />}
      {showContent.banner && <BannerPage />}
      {showContent.bank && <BankPage />}
      {showContent.blog && <BlogPage />}
      {showContent.order && <OrderPage />}
      {showContent.preOrder && <PreOrderPage />}
      {showContent.user && <UserPage />}
      {showContent.product && <Product />}
      {showContent.preProduct && <PreProduct />}
      {showContent.category && <CategoryPage />}
      {showContent.mainCategory && <MainCategoryPage />}
      {showContent.help && <HelperPage />}
      {showContent.helpBanner && <HelpBannerPage />}
      {showContent.calendar && <CalendarTime />}
      {showContent.about && <AboutUsPage />}
      {showContent.contact && <ContactusPage />}
      {showContent.check && <CheckOrderPage />}
      {showContent.orderEdit && <PreOrderEdit />}
      {showContent.equal && <EqualPage />}
      <Footer />
    </div>
  );
}
