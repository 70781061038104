import React, { useState } from "react";
// import axios from "../axios/axios-for-backend";

const NewProductContext = React.createContext();

const initialState = {
  newProducts: [
    {
      id: 1,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1653591340-christmas-cactus-royalty-free-image-1568922653.jpg",
    },
    {
      id: 2,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsn3NhdAiUSyC2KtbnQK2ov0SWeJQjh-yXxg&usqp=CAU",
    },
    {
      id: 3,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReM33W2ThYjcq_vaPShC-vCer7jzvtxzL-mA&usqp=CAU",
    },
    {
      id: 4,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1653591340-christmas-cactus-royalty-free-image-1568922653.jpg",
    },
    {
      id: 5,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReM33W2ThYjcq_vaPShC-vCer7jzvtxzL-mA&usqp=CAU",
    },
    {
      id: 6,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1653591340-christmas-cactus-royalty-free-image-1568922653.jpg",
    },
    {
      id: 7,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1653591340-christmas-cactus-royalty-free-image-1568922653.jpg",
    },
    {
      id: 8,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsn3NhdAiUSyC2KtbnQK2ov0SWeJQjh-yXxg&usqp=CAU",
    },
    {
      id: 9,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1653591340-christmas-cactus-royalty-free-image-1568922653.jpg",
    },
    {
      id: 10,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1653591340-christmas-cactus-royalty-free-image-1568922653.jpg",
    },
    {
      id: 11,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1653591340-christmas-cactus-royalty-free-image-1568922653.jpg",
    },
    {
      id: 12,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsn3NhdAiUSyC2KtbnQK2ov0SWeJQjh-yXxg&usqp=CAU",
    },
    {
      id: 13,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1653591340-christmas-cactus-royalty-free-image-1568922653.jpg",
    },
    {
      id: 14,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReM33W2ThYjcq_vaPShC-vCer7jzvtxzL-mA&usqp=CAU",
    },
    {
      id: 15,
      price: 500,
      desription: "Ягаан өнгийн сарнай цэцгийн баглаа",
      size: "70 см урттай, гоёлын цэцэг",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReM33W2ThYjcq_vaPShC-vCer7jzvtxzL-mA&usqp=CAU",
    },
  ],
};
export const NewProductStore = (props) => {
  const [state] = useState(initialState);

  return (
    <NewProductContext.Provider value={{ state }}>
      {props.children}
    </NewProductContext.Provider>
  );
};
export default NewProductContext;
