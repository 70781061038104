import React, { useContext, useState } from "react";
import AddImageForBlog from "../../../../components/Admin/AddImage/AddImagesForBlog";
import { Button } from "@mui/material";
import BlogContext from "../../../../context/BlogContext";

export default function CreateBlog(props) {
  //context
  const blogCtx = useContext(BlogContext);
  //states
  const [selectedImage, setSelectedImage] = useState(null);
  const [form, setForm] = useState({ title: "", content: "" });

  const getFile = (file) => {
    setSelectedImage(file);
  };

  const changeTitle = (event) => {
    const newTitle = event.target.value;
    setForm((formBefore) => ({
      title: newTitle,
      content: formBefore.content,
    }));
  };
  const changeContent = (event) => {
    const newContent = event.target.value;
    setForm((formBefore) => ({
      title: formBefore.title,
      content: newContent,
    }));
  };

  const onSubmit = () => {
    blogCtx.createBlog(selectedImage, form.title, form.content);
    props.handleViews("createBlog");
  };
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Шинэ Блог үүсгэх</h1>
      <AddImageForBlog height={"450px"} getFile={getFile} />
      <div>
        <input
          className="input"
          type="text"
          onChange={changeTitle}
          placeholder="Гарчиг"
          style={{ margin: "20px auto" }}
        />
      </div>
      <div>
        <textarea
          placeholder="Агуулга"
          rows="10"
          cols="100"
          onChange={changeContent}
        ></textarea>
      </div>

      <Button
        className="mt-3 text-center"
        variant="contained"
        onClick={() => {
          onSubmit();
        }}
      >
        Үүсгэх
      </Button>
    </div>
  );
}
