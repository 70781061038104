import React, { useState, useContext, useEffect } from "react";

import { Box, CircularProgress, Fab } from "@mui/material";

import { Check, Edit, Save } from "@mui/icons-material";

import green from "@mui/material/colors/green";

import ProductContext from "../../../context/ProductContext";

export default function ProductActions({ params, rowId, setRowId, editImage }) {
  //contexts
  const productCtx = useContext(ProductContext);

  //states
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const { discountNumber, Id } = params.row;
    const result = await productCtx.updateDiscountNumber(
      { discountNumber, dayan_id: Id },
      Id
    );
    if (result) {
      setSuccess(true);
      setRowId(null);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (rowId === params.id && success) {
      setSuccess(false);
    }
  }, [rowId]);
  return (
    <>
      <Box sx={{ m: 1, position: "relative" }}>
        <>
          {success ? (
            <Fab
              color="primary"
              sx={{
                width: 40,
                height: 40,
                bgcolor: green[500],
                "&:hover": { bgcolor: green[700] },
              }}
            >
              <Check />
            </Fab>
          ) : (
            <>
              <Fab
                color="primary"
                sx={{
                  width: 40,
                  height: 40,
                  marginRight: 2,
                }}
                disabled={params.id !== rowId || loading}
                onClick={handleSubmit}
              >
                <Save />
              </Fab>
              <Fab
                color="primary"
                sx={{
                  width: 40,
                  height: 40,
                }}
                onClick={() => {
                  editImage(params.row.Id);
                }}
              >
                <Edit />
              </Fab>
            </>
          )}
        </>
        {loading && (
          <CircularProgress
            size={52}
            sx={{
              color: green[500],
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </>
  );
}
