import React, { useState, useContext, useEffect, useRef } from "react";
import css from "./style.module.css";
import Shadow from "../General/Shadow";
import loginImag from "../../../assets/images/auth/Illustration.png";
import closeImage from "../../../assets/images/auth/CloseButton.png";
import CloseNew from "../../../assets/images/product/Close.png";

import eyeIcon from "../../../assets/images/auth/eye.png";
import Icon from "../../../assets/images/auth/Icon.png";
import UserContext from "../../../context/UserContext";
import userIcon from "./../../../assets/images/auth/user.png";
import keyIcon from "../../../assets/images/auth/key.png";
import Spinner from "../General/Spinner";

const UserLogin = (props) => {
  //context
  const userCtx = useContext(UserContext);
  //state
  const [showShadow, setShowShadow] = useState(true);
  const [form, setForm] = useState({ email: "", password: "" });

  const [buttonClicked, setButtonClicked] = useState(false);

  const enterRef = useRef();

  useEffect(() => {
    if (userCtx.state.userId) {
      showShadowEvent();
    }
  }, [userCtx.state.userId]);
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        // callMyFunction();
        enterRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const changeEmail = (event) => {
    userCtx.clearUserLogin();
    const newEmail = event.target.value;
    setForm((formBefore) => ({
      email: newEmail,
      password: formBefore.password,
    }));
  };

  const changePassword = (event) => {
    userCtx.clearUserLogin();
    const newPassword = event.target.value;
    setForm((formBefore) => ({
      email: formBefore.email,
      password: newPassword,
    }));
  };

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  const showShadowEvent = () => {
    setShowShadow(false);
    props.toggleLoginForm();
    setForm({ email: "", password: "" });
  };

  const toRegisterForm = () => {
    showShadowEvent();
    props.toRegisterForm();
  };
  return (
    <div>
      <Shadow opacity={false} show={showShadow} darahad={showShadowEvent} />
      {userCtx.state.logginIn && <Spinner />}
      <div className={css.UserLogin}>
        <div className={css.formInputs}>
          <p>Нэвтрэх</p>
          <div className={css.emailInput}>
            <div>Нэвтрэх нэр</div>
            <input
              className="input"
              name="email"
              type="text"
              onChange={changeEmail}
              placeholder="Хэрэглэгчийн нэр"
            />
            <img className={css.userIcon} src={userIcon} alt="icon" />
          </div>

          <div className={css.passwordInput}>
            <div>Нууц үг</div>
            <input
              className="input"
              name="email"
              type={open === false ? "password" : "text"}
              onChange={changePassword}
              placeholder="Нууц үг"
            />
            {open === false ? (
              <img
                className={css.passwordShowIcon}
                src={eyeIcon}
                alt="icon"
                onClick={toggle}
              />
            ) : (
              <img
                className={css.passwordHideIcon}
                src={Icon}
                alt="icon"
                onClick={toggle}
              />
            )}

            <img className={css.keyIcon} src={keyIcon} alt="icon" />
          </div>
          {userCtx.state.error && (
            <div style={{ color: "red", fontSize: "12px" }}>
              {userCtx.state.error}
            </div>
          )}
          <button
            ref={enterRef}
            onClick={async () => {
              setButtonClicked(true);
              const result = await userCtx.login(form.email, form.password);
              if (result) {
                showShadowEvent();
              }
              setButtonClicked(false);
            }}
            className={`${css.LoginBtn} ${buttonClicked ? "loading" : ""}`}
            disabled={buttonClicked}
          >
            {buttonClicked ? (
              <div className={css.LoaderCircle}></div>
            ) : (
              "Нэвтрэх"
            )}
          </button>
          <button
            onClick={() => {
              toRegisterForm();
            }}
            className={css.RegisterBtn}
          >
            Бүртгүүлэх
          </button>
        </div>
        {/* <img className={css.loginImage} src={loginImag} alt="Icon" /> */}
        <img
          className={css.closeImage}
          onClick={() => {
            showShadowEvent();
          }}
          style={{ cursor: "pointer" }}
          src={CloseNew}
          alt="close"
        />
      </div>
    </div>
  );
};

export default UserLogin;
