import React, { useState } from "react";

const ScheduleItem = (props) => {
  const [activeSchedule, setActiveSchedule] = useState(false);

  return (
    <div
      style={
        props.active
          ? {
              width: "47%",
              height: "90px",
              backgroundColor: "#68A001",
              textAlign: "center",
              paddingTop: "10px",
              fontWeight: "600",
              fontSize: "18px",
              cursor: "pointer",
              color: "#FFFFFF",
            }
          : {
              width: "47%",
              height: "90px",
              backgroundColor: "#D9D9D9",
              textAlign: "center",
              paddingTop: "10px",
              fontWeight: "600",
              fontSize: "18px",
              cursor: "pointer",
            }
      }
      onClick={() => {
        props.selectedValue(props.Schedule.start);
      }}
    >
      <p style={props.active ? { opacity: "1" } : { opacity: "0.5" }}>
        {props.Schedule.start.substring(5, 7)}-р сарын{" "}
        {props.Schedule.start.substring(8, 10)}
      </p>
      <p
        style={
          props.active
            ? { opacity: "1", fontSize: "14px" }
            : { opacity: "0.5", fontSize: "14px" }
        }
      >
        буух бүтээгдхүүн
      </p>
    </div>
  );
};

export default ScheduleItem;
