import React, { useState, useEffect } from "react";
import axios from "../axios/axios-for-backend";

const ContactContext = React.createContext();

const initialState = {
    categories: [],
    loading: false
};
export const ContactUsStore = (props) => {
    const [state, setState] = useState(initialState);

    const ContactUs = () => {
        axios
            .get("contactus")
            .then((result) => {
                setState({ ...state, categories: result.data.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        ContactUs();
    }, []);

    const updateContactUs = async (id, aboutData) => {
        var formData = new FormData();
        formData.append("id", id);
        formData.append("passForm", JSON.stringify(aboutData));
        // formData.append("text", aboutData);

        setState({ ...state, loading: true });
        const res = await axios
            .put(`contactus/${id}`, formData)
            .then((result) => {
                setState({ ...state, success: true, loading: false });
                return result;
            })
            .catch((err) => {
                setState({ ...state, loading: false });
                console.log(err);
            });
        return res;
    };

    return (
        <ContactContext.Provider
            value={{
                state,
                ContactUs,
                updateContactUs,
            }}
        >
            {props.children}
        </ContactContext.Provider>
    );
};
export default ContactContext;
