import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import logoImage from "../../../assets/images/logo.png";
import newLogo from "../../../assets/images/LogoNew.png";
import logoMobile from "../../../assets/images/logoMobile.png";
import logoMin from "../../../assets/images/logoMin.svg";
import whiteLogo from "../../../assets/images/logo_white.png";
import css from "./style.module.css";

const Logo = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isShort, setIsShort] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
      setIsShort(window.innerWidth <= 1037);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={css.Logo}>
      <NavLink to="/">
        {isShort ? (
          <img src={isMobile ? logoMobile : logoMin} alt="Logo" />
        ) : (
          <img src={isMobile ? logoMobile : newLogo} alt="Logo" />
        )}
      </NavLink>
    </div>
  );
};

export default Logo;
