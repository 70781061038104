import React from "react";
import css from "./style.module.css";
import Logo from "../Logo";
import SideMenu from "../sideMenu";
import Shadow from "../General/Shadow";

const SideBar = (props) => {
  let classes = [css.SideBar, css.Close];

  if (props.showSidebar) {
    classes = [css.SideBar, css.Open];
  }

  const handleMenuClick = (event) => {
    event.stopPropagation();
    props.toggleSideBar();
  };

  const handleSidebarClick = () => {
    props.toggleSideBar();
  };

  return (
    <div>
      <Shadow
        opacity={false}
        show={props.showSidebar}
        darahad={props.toggleSideBar}
      />

      <div onClick={handleMenuClick} className={classes.join(" ")}>
        {/* <div className={css.Logo}>
          <Logo />
        </div> */}
        <div className={css.borderline}></div>
        <div className={css.MenuContainer}>
          <SideMenu />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
