
import React, { useContext, useState, useEffect } from "react";
import css from "./style.module.css";
import AboutUsContext from "../../context/AboutUsContext";
// import box1 from "../../assets/images/boxIcon1.png";
// import box2 from "../../assets/images/boxIcon2.png";
// import box3 from "../../assets/images/boxIcon3.png";
// import trendUp from "../../assets/images/trend-up.svg";
// import star from "../../assets/images/star.svg";
// import medalStar from "../../assets/images/medal-star.svg";
import note from "../../assets/images/note_red.svg";
import puzzle from "../../assets/images/puzzle.svg";
import handShake from "../../assets/images/handshakeDk.svg";
import group from "../../assets/images/group.svg";
import circular from "../../assets/images/circular.svg";


// import orderImage from "../../assets/images/slide/slide2.png";

const AboutPage = () => {
    const [formData, setFormData] = useState({
        aboutData: '',
        title1: '',
        title2: '',
        title3: '',
        title4: '',
        title5: '',
        context1: '',
        context2: '',
        context3: '',
        context4: '',
        context5: '',
    });
    // let aboutData = ''
    // let title1 = ''
    // let title2 = ''
    // let title3 = ''
    // let context1 = ''
    // let context2 = ''
    // let context3 = ''
    useEffect(() => {
        aboutCtx.AboutUs();
        window.scrollTo(0, 0)
    }, []);
    const aboutCtx = useContext(AboutUsContext);

    useEffect(() => {
        if (aboutCtx.state.categories[0] !== undefined) {
            const categoryData = aboutCtx.state.categories[0];
            setFormData({
                aboutData: categoryData.text,
                title1: categoryData.title1,
                title2: categoryData.title2,
                title3: categoryData.title3,
                title4: categoryData.title4,
                title5: categoryData.title5,
                context1: categoryData.context1,
                context2: categoryData.context2,
                context3: categoryData.context3,
                context4: categoryData.context4,
                context5: categoryData.context5,
            });
        }
    }, [aboutCtx.state.categories]);

    // if (aboutCtx.state.categories[0] != undefined) {
    //     aboutData = aboutCtx.state.categories[0].text
    //     title1 = aboutCtx.state.categories[0].title1
    //     title2 = aboutCtx.state.categories[0].title2
    //     title3 = aboutCtx.state.categories[0].title3
    //     context1 = aboutCtx.state.categories[0].context1
    //     context2 = aboutCtx.state.categories[0].context2
    //     context3 = aboutCtx.state.categories[0].context3
    // }
    // const words = aboutData.split(' ');
    // const first50Words = words.slice(0, 42).join(' ');
    // const remainingWords = words.slice(42).join(' ');
    return (
        <div className={css.AboutPage}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col d-flex justify-content-start">
                        <h2 className={css.AboutTitle}>Бидний тухай</h2>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col d-flex justify-content-start">
                        <h2 className={css.AboutTitle2}>Компанийн танилцуулга</h2>
                    </div>
                </div>
                <div className="row align-items-center mt-4">
                    <div className="col d-flex justify-content-start">
                        <p className={css.dataP}>{formData.aboutData}</p>
                    </div>

                    {/* <div className="w-100"></div> */}
                    {/* <div className="col">
                    <img className={css.subImage} src={process.env.REACT_APP_BASE_URL +
                        "/public/image/about/" + "u2.png"} alt="orderImage" />
                </div> */}
                    {/* <div className="col ml-4">
                    <p>{remainingWords}</p>
                </div> */}
                </div>
                <div className="row align-items-center mt-4">
                    <div className="col d-flex justify-content-start">
                        <img className={css.slideImage} src={process.env.REACT_APP_BASE_URL +
                            "/public/image/about/" + "u1.png"} alt="orderImage" />
                    </div>
                </div>
                <div className="row align-items-center mt-4">
                    <div className={css.vnet}>
                        Бидний үнэт зүйлс
                    </div>
                </div>

                <div className="row align-items-center mt-4">
                    <div className={css.contains}>
                        <div className="d-flex flex-wrap">
                            <div className="col-md-4 pb-4">
                                <div className={css.boxes}>
                                    <img className={css.boxIcon} src={group} alt="box" />
                                    <div className={css.boxTitle}>{formData.title1}</div>
                                    <div className={css.boxPara}>{formData.context1}</div>
                                </div>
                            </div>
                            <div className="col-md-4 pb-4">
                                <div className={css.boxes}>
                                    <img className={css.boxIcon} src={handShake} alt="box" />
                                    <div className={css.boxTitle}>{formData.title2}</div>
                                    <div className={css.boxPara}>{formData.context2}</div>
                                </div>
                            </div>
                            <div className="col-md-4 pb-4">
                                <div className={css.boxes}>
                                    <img className={css.boxIcon} src={circular} alt="box" />
                                    <div className={css.boxTitle}>{formData.title3}</div>
                                    <div className={css.boxPara}>{formData.context3}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className={css.contains}>
                        <div className="d-flex flex-wrap justify-content-center"> {/* Add justify-content-center class here */}
                            <div className="col-md-4 pb-4">
                                <div className={css.boxes}>
                                    <img className={css.boxIcon} src={note} alt="box" />
                                    <div className={css.boxTitle}>{formData.title4}</div>
                                    <div className={css.boxPara}>{formData.context4}</div>
                                </div>
                            </div>
                            <div className="col-md-4 pb-4">
                                <div className={css.boxes}>
                                    <img className={css.boxIcon} src={puzzle} alt="box" />
                                    <div className={css.boxTitle}>{formData.title5}</div>
                                    <div className={css.boxPara}>{formData.context5}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
        // <div className={css.AboutPage}>
        //     <div className={css.AboutTitle}>
        //         <h2>Бидний тухай</h2>
        //     </div>
        //     <div className={css.Container}>
        //         <div className={css.subAbout}>
        //             <p className={css.subPrgRIght}>{first50Words}</p>
        //             <img className={css.slideImage} src={process.env.REACT_APP_BASE_URL +
        //                 "/public/image/about/" + "u1.png"} alt="orderImage" />
        //         </div>
        //         <div className={css.subMeta}>
        //             <img className={css.subImage} src={process.env.REACT_APP_BASE_URL +
        //                 "/public/image/about/" + "u2.png"} alt="orderImage" />
        //             <p className={css.subPrg}>{remainingWords}</p>
        //         </div>
        //     </div>
        // </div>
    )
};

export default AboutPage;
