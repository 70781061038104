import React, { useEffect, useState } from "react";
import axios from "../axios/axios-for-backend";

const PausePreOrderContext = React.createContext();
const initialState = {
  loading: false,
  error: null,
  Products: [],
  isMore: true,
};
export const ProductStorePause = (props) => {
  const [state, setState] = useState(initialState);

  const updatePreProduct = (id, ScheduledDate, code) => {
    console.log(id, ScheduledDate, code);
    setState({ ...state, loading: true });
    const res = axios
      .post("pauseOrder/admin/updatePreProduct", {
        id,
        ScheduledDate,
        code,
      })
      .then((result) => {
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
    return res;
  };

  useEffect(() => {
    getPreProducts();
  }, []);

  const addStopProducts = async (id, ScheduledDate, code, count) => {
    console.log("mycontext", id, ScheduledDate, code, count);
    setState({ ...state, loading: true });
    axios
      .post("pauseOrder/admin/addStopProducts", {
        id,
        ScheduledDate,
        code,
        count
      })
      .then((result) => {
        console.log("succ");
        setState({ ...state, prevState, loading: false });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };
  const getPreProducts = async () => {
    axios
      .get("pauseOrder/admin/getPreProducts")
      .then((result) => {
      
        setState({
          ...state,
          Products: result.data.data,
        });
        console.log(state);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <PausePreOrderContext.Provider
      value={{
        state,
        updatePreProduct,
        addStopProducts,
        getPreProducts,
      }}
    >
      {props.children}
    </PausePreOrderContext.Provider>
  );
};
export default PausePreOrderContext;
