import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const SpecialCategoryContext = React.createContext();

const initialState = {
    specialCategory: [],
    success: false,
    loading: false,
};
export const SpecialCategory = (props) => {
    const [state, setState] = useState(initialState);
    const getSpecialCategory = () => {
        axios
            .get("categories/getSpecialCategory")
            .then((result) => {
                setState({ ...state, specialCategory: result.data.categories });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <SpecialCategoryContext.Provider
            value={{
                state,
                getSpecialCategory,
            }}
        >
            {props.children}
        </SpecialCategoryContext.Provider>
    );
};
export default SpecialCategoryContext;
