import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";

import UserContext from "../../../context/UserContext";
import BucketContext from "../../../context/BucketContext";
import css from "./style.module.css";

import MenuItem from "../MenuItem";
import UserLogin from "../UserLogin";
import Shadow from "../General/Shadow";
import Basket from "../Basket";
import newLogo from "../../../assets/images/LogoNew.png";

import usericon from "../../../assets/images/auth/usericon.png";
import arrowicon from "../../../assets/images/auth/arrow.png";
import downArrow from "../../../assets/images/auth/arrowDown.png";
import upArrow from "../../../assets/images/auth/ArrowUp.png";
import preOrderImage from "../../../assets/images/auth/PngItem_3074569 1.png";
import bonusImage from "../../../assets/images/auth/5622939 1.png";
import OrderImage from "../../../assets/images/auth/orderICON 1.png";
import logoutImage from "../../../assets/images/auth/clipart2640924 1.png";
import bucket from "../../../assets/images/subMenu/carto.png";
import user from "../../../assets/images/auth/profile.png";
import info from "../../../assets/images/auth/Info cr-fr.png";
import { NavLink } from "react-router-dom";


const SideMenu = () => {
    const navigate = useNavigate();

    //states
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [expandUserMenu, setExpandUserMenu] = useState(false);
    const [showBasket, setShowBasket] = useState(false);

    //contexts
    const userCtx = useContext(UserContext);
    const myBucketCtx = useContext(BucketContext);

    const toggleLoginForm = () => {
        setShowLoginForm((prevState) => !prevState);
    };

    const toggleRegisterForm = () => {
        window.open(
            "https://b24-tuh947.bitrix24.site/",
            "_blank",
            "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
        );
    };

    const closeBasket = () => {
        setShowBasket((prevState) => !prevState);
    };

    const closeExpand = () => {
        setExpandUserMenu(false);
    };

    const openExpand = () => {
        setExpandUserMenu(true);
    };

    const handleLogout = () => {
        closeExpand();
        userCtx.logout();
    };

    const toMyPreOrders = () => {
        closeExpand();
        navigate("/myPreOrders");
    };

    const toMyOrders = () => {
        closeExpand();
        navigate("/myOrders");
    };

    return (
        <div>
            {expandUserMenu && (
                <Shadow opacity={true} show={expandUserMenu} darahad={closeExpand} />
            )}
            {userCtx.state.userId ? (

                <div className={css.sideMenu}>
                    <div className={css.Logo}>
                        <NavLink to="/">
                            <img src={newLogo} alt="Logo" />
                        </NavLink>
                    </div>
                    <ul className={css.sideMenuChild}>
                        <MenuItem link="/category">B2B Онлайн худалдаа</MenuItem>
                        <MenuItem link="/blog">Блог</MenuItem>
                        <MenuItem link="/about">Бидний тухай</MenuItem>
                        <MenuItem link="/contact">Холбоо барих</MenuItem>
                        <MenuItem link="/help">Тусламж</MenuItem>
                    </ul>
                </div>
            ) : (
                <div className={css.sideMenu}>
                    <div className={css.Logo}>
                        <img src={newLogo} alt="Logo" />
                    </div>
                    <ul className={css.sideMenuChild}>
                        <MenuItem link="/category">B2B Онлайн худалдаа</MenuItem>
                        <MenuItem link="/blog">Блог</MenuItem>
                        <MenuItem link="/about">Бидний тухай</MenuItem>
                        <MenuItem link="/contact">Холбоо барих</MenuItem>
                        <MenuItem link="/help">Тусламж</MenuItem>
                    </ul>
                    <div
                        onClick={() => {
                            toggleRegisterForm();
                        }}
                        className={css.Register}
                    >
                        <span>Бүртгүүлэх</span>
                        {/* <img src={arrowicon} alt="usericon" /> */}
                    </div>
                </div>
            )}

            {showLoginForm && (
                <UserLogin
                    toggleLoginForm={toggleLoginForm}
                    toRegisterForm={toggleRegisterForm}
                />
            )}
        </div>
    );
};

export default SideMenu;
