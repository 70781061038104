import React, { useEffect, useContext } from "react";
import DashboardContext from "../../../context/admin/DashboardContext";

export default function Dashboard(props) {
  //contexts
  const dashboardCtx = useContext(DashboardContext);
  useEffect(() => {
    dashboardCtx.initDashboard();
  }, []);
  return (
    <div className="content-wrapper" style={{ height: "85vh" }}>
      <div className="content-header">{/* /.container-fluid */}</div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header border-0">
                  <div className="d-flex justify-content-between">
                    <h3 className="card-title">Хянах самбар</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                props.setPageContent("user");
              }}
            >
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{dashboardCtx.state.initValue.User}</h3>
                  <p>Хэрэглэгчид</p>
                </div>
                <div className="icon">
                  <i className="nav-icon far fa-user" />
                </div>
                <a href="#" className="small-box-footer">
                  Дэлгэрэнгүй <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                props.setPageContent("product");
              }}
            >
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{dashboardCtx.state.initValue.Product}</h3>
                  <p>Нийт Бараа</p>
                </div>
                <div className="icon">
                  <i className="nav-icon fas fa-seedling" />
                </div>
                <a href="#" className="small-box-footer">
                  Дэлгэрэнгүй <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                props.setPageContent("preProduct");
              }}
            >
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>-</h3>
                  {/* <h3>{dashboardCtx.state.initValue.PreProduct}</h3> */}
                  <p>Урьдчилсан Бараа</p>
                </div>
                <div className="icon">
                  <i className="nav-icon fab fa-pagelines" />
                </div>
                <a href="#" className="small-box-footer">
                  Дэлгэрэнгүй <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                props.setPageContent("order");
              }}
            >
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{dashboardCtx.state.initValue.Order}</h3>
                  <p>Захиалга</p>
                </div>
                <div className="icon">
                  <i className="nav-icon far fas fa-truck" />
                </div>
                <a href="#" className="small-box-footer">
                  Дэлгэрэнгүй <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                props.setPageContent("preOrder");
              }}
            >
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{dashboardCtx.state.initValue.PreOrder}</h3>
                  <p>Урьдчилсан захиалга</p>
                </div>
                <div className="icon">
                  <i className="nav-icon fas fa-boxes" />
                </div>
                <a href="#" className="small-box-footer">
                  Дэлгэрэнгүй <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                props.setPageContent("blog");
              }}
            >
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{dashboardCtx.state.initValue.Blog}</h3>
                  <p>Блог</p>
                </div>
                <div className="icon">
                  <i className="nav-icon fas fa-pen-alt" />
                </div>
                <a href="#" className="small-box-footer">
                  Дэлгэрэнгүй <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                props.setPageContent("banner");
              }}
            >
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{dashboardCtx.state.initValue.Banner}</h3>
                  <p>Баннер</p>
                </div>
                <div className="icon">
                  <i className="nav-icon fas fa-ad" />
                </div>
                <a href="#" className="small-box-footer">
                  Дэлгэрэнгүй <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              onClick={() => {
                props.setPageContent("bank");
              }}
            >
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{dashboardCtx.state.initValue.BankTransaction}</h3>
                  <p>Гүйлгээ</p>
                </div>
                <div className="icon">
                  <i className="nav-icon fas fa-money-bill" />
                </div>
                <a className="small-box-footer">
                  Дэлгэрэнгүй <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
