import React, { useContext, useState, useEffect, useMemo } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import grey from "@mui/material/colors/grey";
import BankContext from "../../../context/admin/BankContext";
import { gridClasses } from "@mui/system";
import BankActions from "./BankActions";
import Spinner from "../../../components/Users/General/Spinner";

export default function UserPage() {
  //contexts
  const bankCtx = useContext(BankContext);
  //states
  const [rowId, setRowId] = useState(null);

  const columns = useMemo(
    () => [
      { field: "_id", hide: true },
      {
        field: "createdAt",
        headerName: "Огноо",
        width: 200,
        renderCell: (params) =>
          moment(params.row.createdAt).format("YYYY-MM-DD HH:MM:SS"),
      },
      { field: "type", headerName: "Төрөл", width: 250 },
      { field: "account", headerName: "Данс", width: 250 },
      { field: "amount", headerName: "Дүн", width: 250 },
      {
        field: "description",
        headerName: "Гүйлгээний утга",
        width: 250,
        editable: true,
      },

      //   {
      //     field: "actions",
      //     headerName: "Үйлдэл",
      //     width: 200,

      //     type: "actions",
      //     renderCell: (params) => (
      //       <BankActions {...{ params, rowId, setRowId }} />
      //     ),
      //   },
    ],
    [rowId]
  );

  useEffect(() => {
    bankCtx.getBankTransactions();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header ml-2 d-inline-flex">
        <h1>Банкны гүйлгээ</h1>
      </div>
      <div className="content">
        {bankCtx.state.loading && <Spinner />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div
                  className="card-header border-0"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="d-flex justify-content-between">
                    <Box sx={{ height: 730, width: "100%" }}>
                      <Typography
                        variant="h3"
                        component="h3"
                        sx={{ textAlign: "center", mt: 3, mb: 3 }}
                      />
                      <DataGrid
                        columns={columns}
                        rows={bankCtx.state.transactions}
                        getRowId={(row) => row._id}
                        getRowSpacing={(params) => ({
                          top: params.isFirstVisible ? 0 : 5,
                          bottom: params.isLastVisible ? 0 : 5,
                        })}
                        sx={{
                          [`& .${gridClasses.row}`]: {
                            bgcolor: grey[200],
                          },
                        }}
                        onCellEditCommit={(params) => setRowId(params.id)}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
