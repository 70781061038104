import { Paper } from "@mui/material";
import { useCallback, useState, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import ImagesList from "./ImagesList";
import ProgressList from "./progressList/ProgressList";
import ProductContext from "../../../context/ProductContext";
import ProductPage from "../../../pages/AdminPage/ProductPage";


const AddImages = ({ editImageId, handleClose, onImageUploadSuccess  }) => {

  const productCtx = useContext(ProductContext);
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });
  useEffect(() => {
    if (files.length > 0) {
      const uploadImage = () => {
        setTimeout(() => {
          handleClose();
          onImageUploadSuccess();
        }, 1000);
      };
      uploadImage();
    }
  }, [files, handleClose, onImageUploadSuccess]);

  return (
    <>
      <p>{productCtx.state.error}</p>
      <Paper
        sx={{
          cursor: "pointer",
          background: "#fafafa",
          color: "#bdbdbd",
          border: "1px dashed #ccc",
          "&:hover": { border: "1px solid #ccc" },
        }}
      >
        <div style={{ padding: "16px", height: "180px" }} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p style={{ color: "green" }}>Drop the files here...</p>
          ) : (
            <p>Drag 'n' Drop some files here, or click to select files</p>
          )}
          <em>(images with *.jpeg, *.png, *.jpg extension will be accepted)</em>
        </div>
      </Paper>
      <ProgressList {...{ files, editImageId }} />
      <ImagesList {...{ editImageId }} />
      {/* <ProductPage props={2} /> */}
    </>
  );
};

export default AddImages;
