import React, { useContext } from "react";
import css from "./style.module.css";
import BucketContext from "../../../context/BucketContext";
import noImageIcon from "../../../assets/images/product/no-img.png";

const BasketItem = (props) => {
  //contexts
  const myBucketCtx = useContext(BucketContext);

  const handleDelete = (id) => {
    myBucketCtx.deleteBucket(id);
    props.sendDataToParent(id);
  }


  return (
    <div className={css.BasketItem}>
      <div className={css.BasketItemList}>
        {props.order.image !== "no-photo.jpg" ? (
          <img
            className={css.BasketItemImage}
            src={
              process.env.REACT_APP_BASE_URL +
              "/public/image/products/" +
              props.order.image
            }
            alt="сагс"
          />
        ) : (
          <img className={css.BasketItemImage} src={noImageIcon} alt="сагс" />
        )}

        <div className={css.BasketItemListDetail}>
          <div>
            <p style={{ fontWeight: "600" }}>{props.order.name}</p>{" "}
            <p>
              Код: <span style={{ fontWeight: "600" }}>{props.order.code}</span>
            </p>
            <button
              onClick={() => {
                handleDelete(props.order._id)
              }}
            // onClick={() => {
            //   myBucketCtx.deleteBucket(props.order._id);
            // }}
            ></button>
          </div>
          <div>
            <p>Үнэ</p>
            <p style={{ fontWeight: "600" }}>
              {props.order.listPrice.toLocaleString()} ₮
            </p>
          </div>
          <div>
            <p>Боломжит үлдэгдэл</p>
            <p style={{ fontWeight: "600" }}>{props.order.piece} ш</p>
          </div>
          <div>
            <p>Тоо ширхэг</p>
            <div>
              <p style={{ width: "100%" }}>
                <span style={{ fontWeight: "600" }}>
                  {props.order.piece} багц{" "}
                </span>
                <span style={{ color: "#ff4f58", fontWeight: "600" }}>
                  ( 1 багцанд {props.order.QtyPerpackage}ш )
                </span>
              </p>
              <div className={css.BasketBonus}>
                <p style={{ fontWeight: "600" }}>+{props.order.WholeQty}Ш</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={css.BasketItemFooter}>
        <p>
          {props.order.QtyPerpackage * props.order.piece} ширхэг x{" "}
          {props.order.listPrice.toLocaleString()}₮ =
          {(
            props.order.piece *
            props.order.QtyPerpackage *
            (props.order.categoryName === "Хямдарсан"
              ? props.order.listPrice
              : props.order.piece * props.order.QtyPerpackage >=
                props.order.WholeQty && props.order.WholePrice > 0
                ? props.order.WholePrice
                : props.order.listPrice)
          ).toLocaleString()}
          ₮
        </p>
        {props.order.categoryName === "Хямдарсан" ? (
          <p style={{ color: "#ff4f58", fontSize: "11px", marginTop: "0" }}>
            (хямдралтай бараа)
          </p>
        ) : props.order.Percentage !== 0 ? (
          // ) : props.order.WholeQty > 0 &&
          //   props.order.QtyPerpackage * props.order.piece >=
          //     props.order.WholeQty ? (
          <p style={{ color: "#ff4f58", fontSize: "11px", marginTop: "0" }}>
            (хөнгөлөгдсөн)
          </p>
        ) : (
          <p style={{ color: "#ff4f58", fontSize: "11px", marginTop: "0" }}>
            (хөнгөлөгдөөгүй)
          </p>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <p className={css.BasketItemPrice}>
          Төлөх дүн:
          <span
            style={{ color: "#ff4f58", fontWeight: "600", marginLeft: "10px" }}
          >
            {props.order.totalPrice.toLocaleString()}₮
          </span>
        </p>
      </div>
    </div>
  );
};

export default BasketItem;
