import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const SliderContext = React.createContext();

const initialState = {
  sliders: [],
};
export const SliderStore = (props) => {
  const [state, setState] = useState(initialState);

  const createBanners = (file) => {
    console.log(file);
    var formData = new FormData();
    formData.append("file", file);
    formData.append("isActive", true);
    console.log(file);
    console.log(formData);
    axios
      .post("banners", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        getBanners();
      })
      .catch((err) => {
        console.log(err);
        setState({
          error: err.response.data.error.message,
        });
      });
  };

  const getBanners = () => {
    axios
      .get("banners")
      .then((result) => {
        setState({ ...state, sliders: result.data.data });
      })
      .catch((err) => {
        console.log(err);
        setState({
          error: err.response.data.error.message,
        });
      });
  };

  const updateBanner = async (body, bannerId) => {
    setState({ ...state, loading: true });
    const res = await axios
      .put("banners/" + bannerId, body)
      .then((result) => {
        setState({ ...state, success: true, loading: false });
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
    return res;
  };

  const deleteBanner = async (bannerId) => {
    const res = await axios
      .delete("banners/" + bannerId)
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((err) => console.log(err));
    return res;
  };

  return (
    <SliderContext.Provider
      value={{ state, getBanners, updateBanner, deleteBanner, createBanners }}
    >
      {props.children}
    </SliderContext.Provider>
  );
};
export default SliderContext;
