import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const BlogContext = React.createContext();

const initialState = {
  blogs: [],
};
export const BlogStore = (props) => {
  const [state, setState] = useState(initialState);

  const createBlog = (file, title, content) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("title", title);
    formData.append("content", content);

    axios
      .post("blogs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        getBlogs();
      })
      .catch((err) => {
        console.log(err);
        setState({
          error: err.response.data.error.message,
        });
      });
  };

  const getBlogs = () => {
    axios
      .get("blogs")
      .then((result) => {
        setState({ ...state, blogs: result.data.data });
      })
      .catch((err) => {
        console.log(err);
        setState({
          error: err.response.data.error.message,
        });
      });
  };

  const updateBlog = async (body, blogId) => {
    setState({ ...state, loading: true });
    const res = await axios
      .put("blogs/" + blogId, body)
      .then((result) => {
        setState({ ...state, success: true, loading: false });
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
    return res;
  };

  const deleteBlog = async (blogId) => {
    const res = await axios
      .delete("blogs/" + blogId)
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((err) => console.log(err));
    return res;
  };

  return (
    <BlogContext.Provider
      value={{ state, getBlogs, updateBlog, deleteBlog, createBlog }}
    >
      {props.children}
    </BlogContext.Provider>
  );
};
export default BlogContext;
