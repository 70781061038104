import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const UserContext = React.createContext();

const initialState = {
  saving: false,
  logginIn: false,
  role: null,
  error: null,
  token: null,
  userId: null,
  userDetail: {
    f_name: null,
    l_name: null,
    email: null,
    bonusPoint: null,
    phone: null,
  },
};
export const UserStore = (props) => {
  const [state, setState] = useState(initialState);

  const signup = (email, password) => {
    setState({ saving: true });
  };

  const login = async (email, password) => {
    setState({ logginIn: true });
    const dondon = axios
      .post("users/login", {
        email: email,
        password: password,
      })
      .then((result) => {
        localStorage.setItem("userId", result.data.user._id);
        setState({
          ...state,
          logginIn: false,
          token: result.data.token,
          userId: result.data.user._id,
          role: result.data.user.role,
          userDetail: {
            ...state.userDetail,
            f_name: result.data.user.name,
            email: result.data.user.email,
          },
        });
        return result;
      })
      .catch((err) => {
        setState({
          ...state,
          error: err.response.data.error.message,
          logginIn: false,
        });
      });
    return dondon;
  };

  const getUserDetails = async () => {
    const userId = localStorage.getItem("userId");
    const res = axios
      .get("users/" + userId)
      .then((result) => {
        setState({
          ...state,
          userId: result.data.data._id,
          role: result.data.data.role,
          userDetail: {
            ...state.userDetail,
            f_name: result.data.data.name,
            email: result.data.data.email,
            bonusPoint: result.data.data.bonusPoint,
          },
        });
        return result;
      })
      .catch((err) => {
        setState(initialState);
      });
    return res;
  };

  const logout = () => {
    axios
      .get("users/logout")
      .then((result) => {
        localStorage.removeItem("userId");
      })
      .catch((err) => console.log(err));
    setState(initialState);
  };

  const deleteUser = async (userId) => {
    const dondon = await axios
      .delete("users/" + userId)
      .then((result) => {
        return result;
      })
      .catch((err) => console.log(err));
    return dondon;
  };

  const clearUserLogin = () => {
    setState(initialState);
  };

  return (
    <UserContext.Provider
      value={{
        state,
        signup,
        login,
        clearUserLogin,
        logout,
        getUserDetails,
        deleteUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
export default UserContext;
