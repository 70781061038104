import React, { useContext, useState, useEffect, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "mui-image";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Modal, Alert } from "@mui/material";
import grey from "@mui/material/colors/grey";
import green from "@mui/material/colors/green";
import noImagePhoto from "../../../assets/images/product/no-img.png";
import { Fab } from "@mui/material";
import ProductContext from "../../../context/ProductContext";
import ProductActions from "./ProductActions";
import { gridClasses } from "@mui/system";
import AddImages from "../../../components/Admin/AddImage/AddImages";
import Spinner from "../../../components/Users/General/Spinner";
import css from "./style.module.css";

import "./styles.css";
// Import Swiper stylesi
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/grid";
import { Pagination, Navigation } from "swiper";

export default function ProductPage() {
  //contexts
  const productCtx = useContext(ProductContext);

  //states
  const [pageSize, setPageSize] = useState(100);
  const [rowId, setRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editImageId, setEditImageId] = useState(null);
  const [alertShow, setAlertShow] = useState(false);

  const editImage = (Id) => {
    setShowModal(true);
    setEditImageId(Id);
  };
  const handleClose = () => {
    setShowModal(false);
    setEditImageId(null);
  };
  const handleUploadSuccess = () => {
    setAlertShow(true);
    setTimeout(() => {
      setAlertShow(false);
    }, 3000);
  };
  const columns = useMemo(
    () => [
      { field: "Id", hide: true },
      {
        field: "DetailImages",
        headerName: "Зураг",
        width: 350,
        renderCell: (params) =>
          params.row.DetailImages && params.row.DetailImages.length > 0 ? (
            <Swiper
              navigation={true}
              pagination={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {params.row.DetailImages.map((el, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={
                      process.env.REACT_APP_BASE_URL +
                      "/public/image/products/" +
                      el
                    }
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <img
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              src={noImagePhoto}
            />
          ),
        sortable: false,
        filterable: false,
      },
      {
        field: "discountNumber",
        headerName: "Хямдрах тоо",
        width: 100,
        editable: true,
      },
      {
        field: "actions",
        width: 180,
        headerName: "Үйлдэл",
        type: "actions",
        renderCell: (params) => (
          <ProductActions {...{ params, rowId, setRowId, editImage }} />
        ),
      },
      {
        field: "Qty",
        headerName: "Үлдэгдэл",
        width: 80,
      },
      {
        field: "Code",
        headerName: "Код",
        width: 80,
      },
      {
        field: "Name",
        headerName: "Нэр",
        width: 180,
      },
      {
        field: "CategoryName",
        headerName: "Категори нэр",
        width: 180,
      },
      {
        field: "GroupName",
        headerName: "Дэд Категори",
        width: 180,
      },
      {
        field: "QtyPerPackage",
        headerName: "Багц",
        width: 180,
      },
      // {
      //   field: "PriceListName",
      //   headerName: "Барааны үнийн төрөл",
      //   width: 210,
      // },
      // {
      //   field: "ListPrice",
      //   headerName: "Барааны үнэ",
      //   width: 120,
      // },
      // {
      //   field: "PriceListNameMain",
      //   headerName: "Барааны үнийн төрөл",
      //   width: 210,
      // },
      // {
      //   field: "ListPriceMain",
      //   headerName: "Барааны үнэ",
      //   width: 120,
      // },
      // {
      //   field: "PriceListNameDiscount",
      //   headerName: "Барааны үнийн төрөл",
      //   width: 210,
      // },
      // {
      //   field: "ListPriceDiscount",
      //   headerName: "Барааны үнэ",
      //   width: 120,
      // },
    ],
    [rowId]
  );
  useEffect(() => {
    productCtx.getAllProduct("admin", 1, null);
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header ml-2 d-inline-flex">
        <h1>Нийт бараа</h1>
      </div>
      <div className="content">
        {productCtx.state.loading && <Spinner />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div
                  className="card-header border-0"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="d-flex justify-content-center">
                    <Box sx={{ height: 730, width: "100%" }}>
                      <Typography
                        variant="h3"
                        component="h3"
                        sx={{ textAlign: "center", mt: 3, mb: 3 }}
                      />
                      <DataGrid
                        rowHeight={220}
                        columns={columns}
                        rows={productCtx.state.Products}
                        getRowId={(row) => row.Id}
                        rowsPerPageOptions={[100, 200, 500]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        getRowSpacing={(params) => ({
                          top: params.isFirstVisible ? 0 : 5,
                          bottom: params.isLastVisible ? 0 : 5,
                        })}
                        sx={{
                          [`& .${gridClasses.row}`]: {
                            bgcolor: grey[200],
                          },
                        }}
                        onCellEditCommit={(params) => setRowId(params.id)}
                      />
                    </Box>
                    <Modal
                      open={showModal}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box>
                        <div className="ModalBody">
                          {/* <AddImages {...{ editImageId, handleClose }} /> */}
                          <AddImages
                            editImageId={editImageId}
                            handleClose={handleClose}
                            onImageUploadSuccess={handleUploadSuccess}
                          />
                        </div>
                      </Box>
                    </Modal>
                    {alertShow ?
                      (
                        <Alert icon={false} className={css.forAlert} >Зураг амжилттай хадгалагдлаа.</Alert>
                      ) :
                      (null)
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
