import React, { useState, useContext } from "react";
import css from "./style.module.css";
import Image from "mui-image";
import Shadow from "../General/Shadow";
import closeButton from "../../../assets/images/product/CloseButtonBorder.png";
import CloseNew from "../../../assets/images/product/Close.png";
import discountImage from "../../../assets/images/product/discount.png";
import ProductContext from "../../../context/ProductContext";
import BucketContext from "../../../context/BucketContext";
import UserContext from "../../../context/UserContext";
import noImagePhoto from "../../../assets/images/product/no-img.png";
import "./styles.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";

//alert Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const ProductDetails = (props) => {
  //states
  const [my_swiper, set_my_swiper] = useState({});
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [showShadow, setShowShadow] = useState(true);
  // const [bucketAlert, setBucketAlert] = useState(false);

  //contexts
  const myBucketCtx = useContext(BucketContext);
  const productCtx = useContext(ProductContext);
  const userCtx = useContext(UserContext);
  // console.log(props.ProductDetails.ListPrice);
  // console.log(props.ProductDetails.WholePrice)
  const showShadowEvent = () => {
    setShowShadow(false);
    props.toggleProductDetail();
  };
  const handleLeftClick = () => {
    my_swiper.slidePrev();
  };

  const handleRightClick = () => {
    my_swiper.slideNext();
  };

  // const closeBucketAlert = () => {
  //   setBucketAlert(false);
  // };

  const createBucket = (
    id,
    piece,
    QtyPerpackage,
    listPrice,
    code,
    image,
    name,
    categoryName,
    brandName,
    WholeQty,
    pre_order,
    type
  ) => {
    if (piece > 0) {
      productCtx.clearProduct(id);
      myBucketCtx.addBucket(
        id,
        piece,
        QtyPerpackage,
        listPrice,
        code,
        image,
        name,
        categoryName,
        brandName,
        WholeQty,
        pre_order,
        type
      );
      showShadowEvent();
    }
  };
  let percent = Math.round(
    ((props.ProductDetails.ListPrice - props.ProductDetails.WholePrice) / props.ProductDetails.ListPrice) * 100
  )

  return (
    <div>
      {/* <Dialog open={bucketAlert} onClose={closeBucketAlert}>
        <DialogTitle>Анхааруулга</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Та сагсан дахь бүтээгдхүүнээ худалдан авсаны дараа энэ үйлдэлийг
            хийх боломжтой.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeBucketAlert}>Хаах</Button>
        </DialogActions>
      </Dialog> */}

      <Shadow opacity={false} show={showShadow} darahad={showShadowEvent} />
      <div className={css.ProductDetails}>
        <div className={css.headerCus}>
          <div>{' '}</div>
          <div className={css.cusTitle}>Бүтээгдэхүүний дэлгэрэнгүй</div>
          <img
            className={css.ProductCloseButton}
            src={CloseNew}
            alt="product"
            style={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
            }}
            onClick={() => {
              showShadowEvent();
            }}
          /></div>
        <div className={css.divideContainer}>
          <div className={css.leftSector}>
            {props.ProductDetails.DetailImages.length > 0 && (
              <div className={css.handleLeftClickContainer}>
                <button
                  onClick={handleLeftClick}
                  className={css.handleLeftClick}
                ></button>
              </div>
            )}

            <div className={css.ProductDetailsImage}>
              {props.ProductDetails.DetailImages.length > 0 && (
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  loop={true}
                  spaceBetween={10}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper6"
                  onInit={(ev) => {
                    set_my_swiper(ev);
                  }}
                >
                  {props.ProductDetails.DetailImages.map((el, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={
                          process.env.REACT_APP_BASE_URL +
                          "/public/image/products/" +
                          el
                        }
                        alt="product_img"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
              {props.ProductDetails.DetailImages.length > 0 && (
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={2}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper5"
                >
                  {props.ProductDetails.DetailImages.map((el, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={
                          process.env.REACT_APP_BASE_URL +
                          "/public/image/products/" +
                          el
                        }
                        alt="product_img"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
              {props.ProductDetails.DetailImages.length === 0 && (
                <Image style={{ height: "460px" }} src={noImagePhoto} />
              )}
            </div>
            {props.ProductDetails.DetailImages.length > 0 && (
              <div className={css.handleRightClickContainer}>
                <button
                  onClick={handleRightClick}
                  className={css.handleRightClick}
                ></button>
              </div>
            )}
          </div>
          <div className={css.rightSector}>
            <div className={css.ProductDetailsContainer}>
              <div className={css.ProductDetailsTitle}>
                <div>
                  <p
                    style={{
                      fontFamily: 'Montserrat',
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "24px",
                      color: "#2A2C38",
                    }}
                  >
                    {props.ProductDetails.Name}
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{
                      fontFamily: 'Montserrat',
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "12px",
                      color: "#5D5F6B",
                    }}>Код: <span style={{
                      fontFamily: 'Montserrat',
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "12px",
                      lineHeight: "12px",
                      color: "#2A2C38",
                    }}>{props.ProductDetails.Code}</span></p>
                    <p
                      style={{
                        color: "#ff4f58",
                        fontWeight: "600",
                        marginLeft: "8px"
                      }}
                    >
                      ({props.ProductDetails.CategoryName})
                    </p>
                  </div>
                </div>
                {/* <div> */}
                {/* <p
                    style={{
                      color: "#ff4f58",
                      fontSize: "24px",
                      fontWeight: "600",
                    }}
                  >
                    {userCtx.state.userId
                      ? props.ProductDetails.CategoryName === "Хямдарсан"
                        ? props.ProductDetails.ListPrice.toLocaleString()
                        : props.ProductDetails.Piece *
                          props.ProductDetails.QtyPerpackage >=
                          props.ProductDetails.WholeQty &&
                          props.ProductDetails.WholePrice > 0
                          ? props.ProductDetails.WholePrice.toLocaleString()
                          : props.ProductDetails.ListPrice.toLocaleString()
                      : "-,--"}
                    ₮
                  </p> */}
                {/* <p>Код: {props.ProductDetails.Code}</p> */}
                {/* </div> */}
              </div>
              <div className={css.ProductDetailsDesc}>

                <div className={`container-fluid ${css.formContainer}`}>
                  <div className={`row ${css.formRow}`}>
                    <div className="col">Тариалагч</div>
                    <div className="col"><p >{props.ProductDetails.BrandName}</p></div>
                    <div className="col">Урт (см)</div>
                    <div className="col"><p>{props.ProductDetails.QtyPackage}</p></div>
                  </div>
                  <div className={`row ${css.formRow}`}>
                    <div className="col">Өнгө</div>
                    <div className="col"><p>{props.ProductDetails.Color}</p></div>
                    <div className="col">Улс</div>
                    <div className="col"><p>{props.ProductDetails.Manufacturer}</p></div>
                  </div>
                  <div className={`row ${css.formRow}`}>
                    <div className="col">Чанар</div>
                    <div className="col"><p>{props.ProductDetails.Size}</p></div>
                    <div className="col">Хайрцаглах хэмжээ (ш)</div>
                    <div className="col"><p>{props.ProductDetails.Volume}</p></div>
                  </div>
                  <div className={`row ${css.formRow}`}>
                    <div className="col">Дэд Категори</div>
                    <div className="col"><p>{props.ProductDetails.GroupName.replace(/\//g, "")}</p></div>
                    <div className="col">Тайлбар</div>
                    <div className="col"><p>{props.ProductDetails.Faq}</p></div>
                  </div>
                </div>

                <section className={css.ProductDetailsOrder}>
                  <div className={css.ProductDetailsOrderContainer}>
                    <div>
                      <p style={{
                        fontFamily: 'Montserrat',
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "12px",
                        color: "#5D5F6B",
                        marginBottom: "5px"
                      }}>
                        1 багцанд:{" "}
                        <span
                          style={{
                            fontFamily: 'Montserrat',
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "12px",
                            lineHeight: "12px",
                            color: "#2A2C38",
                          }}
                        >
                          {props.ProductDetails.QtyPerpackage} Ш
                        </span>
                      </p>
                      <p style={{
                        fontFamily: 'Montserrat',
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "12px",
                        color: "#5D5F6B",
                      }}>
                        Үлдэгдэл :{" "}
                        <span
                          style={{
                            fontFamily: 'Montserrat',
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "12px",
                            lineHeight: "12px",
                            color: "#2A2C38",
                          }}
                        >
                          {userCtx.state.userId ? props.ProductDetails.Qty : "---"}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Нэгж үнэ:
                      </p>
                      <p>
                        {userCtx.state.userId
                          ? props.ProductDetails.CategoryName === "Хямдарсан"
                            ? props.ProductDetails.ListPrice.toLocaleString()
                            : props.ProductDetails.Piece *
                              props.ProductDetails.QtyPerpackage >=
                              props.ProductDetails.WholeQty &&
                              props.ProductDetails.WholePrice > 0
                              ? props.ProductDetails.WholePrice.toLocaleString()
                              : props.ProductDetails.ListPrice.toLocaleString()
                          : "-,--"}
                        ₮
                      </p>
                    </div>
                    {/* <div>
                      {props.ProductDetails.CategoryName === "Хямдарсан" ? (
                        <p>
                          Хөнгөлөгдсөн бараа. Та үндсэн үнээс хямдруулан авах
                          боломжтой.
                        </p>
                      ) : (
                        <p>
                          Та{" "}
                          <span
                            style={{
                              color: "#ff4f58",
                              fontWeight: "600",
                            }}
                          >
                            {props.ProductDetails.WholeQty}
                          </span>{" "}
                          ширхэгээс дээш бараа авбал{" "}
                          <span
                            style={{
                              color: "#ff4f58",
                              fontWeight: "600",
                            }}
                          >
                            {Math.ceil(
                              (props.ProductDetails.WholeQty * 100) /
                              props.ProductDetails.ListPrice
                            )}
                            %{" "}
                          </span>
                          хөнгөлөлтөнд хамрагдах боломжтой.
                        </p>
                      )}

                      <img src={discountImage} alt="discount" />
                    </div> */}
                  </div>
                  <div>
                    <div className={css.ProductCalculate}>
                      {/* <p>Тооцоолуур</p> */}
                      <div>
                        {props.ProductDetails.CategoryName === "Хямдарсан" ? (
                          <p style={{
                            fontFamily: 'Montserrat',
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "12px",
                            textAlign: "center",
                            color: "#2A2C38",
                            border: "1px solid #FFDCDE",
                            borderRadius: "20px",
                            padding: "5px"
                          }}>
                            Хөнгөлөгдсөн бараа. Та үндсэн үнээс хямдруулан авах
                            боломжтой.
                          </p>
                        ) : (
                          <p style={{
                            fontFamily: 'Montserrat',
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "12px",
                            textAlign: "center",
                            color: "#2A2C38",
                            border: "1px solid #FFDCDE",
                            borderRadius: "20px",
                            padding: "5px"
                          }}>
                            Та{" "}
                            <span
                              style={{
                                fontFamily: 'Montserrat',
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "12px",
                                textAlign: "center",
                                color: "#FF4F58",
                              }}
                            >
                              {props.ProductDetails.WholeQty}
                            </span>{" "}
                            ширхэгээс дээш бараа авбал{" "}
                            <span
                              style={{
                                fontFamily: 'Montserrat',
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "12px",
                                textAlign: "center",
                                color: "#FF4F58",
                              }}
                            >
                              {props.ProductDetails.WholePrice ? percent : "0"}
                              %{" "}
                            </span>
                            хөнгөлөлтөнд хамрагдах боломжтой.
                          </p>
                        )}
                      </div>
                      <section className={css.ProductCalculateMain}>
                        <section>
                          <section className={css.ProductCalculateBasket}>
                            <section className={css.customBtn}>
                              <button className={css.minusButton}
                                style={
                                  userCtx.state.userId
                                    ? {}
                                    : { cursor: "not-allowed" }
                                }
                                onClick={() => {
                                  if (props.ProductDetails.ScheduledDate) {
                                    productCtx.decrementTotalOrder(
                                      props.ProductDetails.Id,
                                      props.ProductDetails.ScheduledDate
                                    );
                                  } else {
                                    productCtx.decrementTotalOrder(
                                      props.ProductDetails.Id,
                                      null
                                    );
                                  }
                                }}
                              ></button>
                              <p>{props.ProductDetails.Piece} багц</p>
                              <button
                                className={css.addButton}
                                style={
                                  userCtx.state.userId
                                    ? {}
                                    : { cursor: "not-allowed" }
                                }
                                onClick={() => {
                                  if (props.ProductDetails.ScheduledDate) {
                                    productCtx.incrementTotalOrder(
                                      props.ProductDetails.Id,
                                      props.ProductDetails.ScheduledDate
                                    );
                                  } else {
                                    productCtx.incrementTotalOrder(
                                      props.ProductDetails.Id,
                                      null
                                    );
                                  }
                                }}
                              ></button>
                            </section>
                            {/* <p>+{props.ProductDetails.WholeQty} ш</p> */}
                          </section>
                        </section>
                        <section>
                          {userCtx.state.userId ? (<p
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              marginTop: "20px",
                            }}
                          >
                            {props.ProductDetails.Piece *
                              props.ProductDetails.QtyPerpackage}{" "}
                            ширхэг x{" "}
                            {props.ProductDetails.CategoryName === "Хямдарсан"
                              ? props.ProductDetails.ListPrice.toLocaleString()
                              : props.ProductDetails.Piece *
                                props.ProductDetails.QtyPerpackage >=
                                props.ProductDetails.WholeQty &&
                                props.ProductDetails.WholePrice > 0
                                ? props.ProductDetails.WholePrice.toLocaleString()
                                : props.ProductDetails.ListPrice.toLocaleString()}
                            ₮ ={" "}
                            {(
                              props.ProductDetails.Piece *
                              props.ProductDetails.QtyPerpackage *
                              (props.ProductDetails.CategoryName === "Хямдарсан"
                                ? props.ProductDetails.ListPrice
                                : props.ProductDetails.Piece *
                                  props.ProductDetails.QtyPerpackage >=
                                  props.ProductDetails.WholeQty &&
                                  props.ProductDetails.WholePrice > 0
                                  ? props.ProductDetails.WholePrice
                                  : props.ProductDetails.ListPrice)
                            ).toLocaleString()}
                            ₮
                          </p>) : (<p></p>)}
                          {userCtx.state.userId ? (props.ProductDetails.CategoryName === "Хямдарсан" ? (
                            <p
                              style={{
                                color: "#ff4f58",
                                textAlign: "center",
                                fontSize: "11px",
                              }}
                            >
                              <span>(хямдарсан бараа)</span>
                            </p>
                          ) : props.ProductDetails.WholeQty > 0 &&
                            props.ProductDetails.Piece *
                            props.ProductDetails.QtyPerpackage >=
                            props.ProductDetails.WholeQty ? (
                            <p
                              style={{
                                color: "#ff4f58",
                                textAlign: "center",
                                fontSize: "11px",
                              }}
                            >
                              <span>(хөнгөлөгдсөн)</span>
                            </p>
                          ) : (
                            <p
                              style={{
                                color: "#ff4f58",
                                textAlign: "center",
                                fontSize: "11px",
                              }}
                            >
                              <span>(хөнгөлөгдөөгүй)</span>
                            </p>
                          )) : (<p></p>)}
                        </section>
                        <section className={css.ProductCalculateToBucket}
                          onClick={() => {
                            if (
                              userCtx.state.userId &&
                              props.ProductDetails.Qty >= 0
                            ) {
                              createBucket(
                                props.ProductDetails.Id,
                                props.ProductDetails.Piece,
                                props.ProductDetails.QtyPerpackage,
                                props.ProductDetails.CategoryName === "Хямдарсан"
                                  ? props.ProductDetails.ListPrice
                                  : props.ProductDetails.Piece *
                                    props.ProductDetails.QtyPerpackage >=
                                    props.ProductDetails.WholeQty &&
                                    props.ProductDetails.WholePrice > 0
                                    ? props.ProductDetails.WholePrice
                                    : props.ProductDetails.ListPrice,
                                props.ProductDetails.Code,
                                props.ProductDetails.DetailImages[0],
                                props.ProductDetails.Name,
                                props.ProductDetails.CategoryName,
                                props.ProductDetails.BrandName,
                                props.ProductDetails.Qty,
                                percent,
                                props.ProductDetails.WholeQty,
                                props.ProductDetails.CategoryName === "Урьдчилсан"
                                  ? true
                                  : false,
                                // props.ProductDetails.type,
                                props.ProductDetails.CategoryName === "Хямдарсан"
                                  ? "Хямдарсан"
                                  : "Хямдраагүй"
                              );
                            }
                          }}
                        >
                          <button
                            style={
                              userCtx.state.userId && props.ProductDetails.Qty > 0
                                ? {}
                                : { cursor: "not-allowed" }
                            }

                            className={css.bucketButton}
                          ></button>
                          <div>Сагслах</div>
                        </section>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default ProductDetails;
