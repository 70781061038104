import React, { useContext, useState, useEffect } from "react";
import { useHref, useNavigate } from "react-router-dom";

import PaymentProductList from "./PaymentProductList";
import css from "./style.module.css";
import LayoutContext from "../../context/LayoutContext";
import GlobalContext from "../../context/GlobalContext";
import BucketContext from "../../context/BucketContext";
import UserContext from "../../context/UserContext";
import OrderContext from "../../context/OrderContext";

import Flag from "../../assets/images/payment/Flag.png";
import Receipt from "../../assets/images/payment/Receipt.png";
import Shopping from "../../assets/images/payment/Shopping Cart.png";
import Trash from "../../assets/images/payment/Trash Can.png";
import Shop from "../../assets/images/payment/Shop.png";
import Hourglass from "../../assets/images/payment/Hourglass.png";

import counterImage from "../../assets/images/basket/timer.png";

import khanBank from "../../assets/images/payment/khaan.png";
import finishOrder from "../../assets/images/payment/completeIcon.png";
import moment from "moment";

//dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const PaymentPage = (props) => {
  const navigate = useNavigate();

  //states
  const [openDialog, setOpenDialog] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  let deadline = null;

  const receiveDataFromChild = (data) => {
    const itemIdToDelete = data;
    myBucketCtx.state.myBuckets = myBucketCtx.state.myBuckets.filter(
      (item) => item.id !== itemIdToDelete
    );
    if (myBucketCtx.state.myBuckets.length == 0) {
      navigate("/")
    }
  };

  const [step, setStep] = useState({
    step1: { current: true, complete: false },
    step3: { current: false, complete: false },
    step4: { current: false, complete: false },
  });

  const [randomNumber, setRandomNumber] = useState(0);
  const [activeStep, setActiveStep] = useState(1);
  const [lockStep, setLockStep] = useState(false);
  const [usedBonus, setUsedBonus] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  //step 1 form
  const [bonus, setBonus] = useState([
    { bonusSize: 10, active: false },
    { bonusSize: 25, active: false },
    { bonusSize: 50, active: false },
  ]);
  const [delivery, setDelivery] = useState(null);

  //step 2 form
  const [orderItem, setOrderItem] = useState({
    water: false,
    ground: false,
    vase: false,
  });
  const [deliveryAddress, setDeliveryAddress] = useState("");

  //step 3 form

  //contexts
  const layoutCtx = useContext(LayoutContext);
  const globalCtx = useContext(GlobalContext);
  const myBucketCtx = useContext(BucketContext);
  const userCtx = useContext(UserContext);
  const orderCtx = useContext(OrderContext);

  useEffect(() => {
    // console.log(userCtx.state.userDetail);
    // console.log(myBucketCtx.state.myBuckets.length)
    // console.log("myBucketCtx.state.myBuckets.length")
    if (myBucketCtx.state.myBuckets.length === 0) {

      navigate("/");
    }
    if (myBucketCtx.state.checkOrder[0] != undefined) {
      var email = myBucketCtx.state.checkOrder[0].orderDescription;
      var parts = email.split("-");
      setRandomNumber(parts[1]);
      console.log(parts[1]);
      console.log(randomNumber)
    }
    else {
      if (randomNumber === 0) {
        setRandomNumber(Math.random().toString().substring(2, 7));
      }
    }
    if (myBucketCtx.state.myBuckets.length > 0) {
      const isPreOrder = myBucketCtx.state.myBuckets[0].pre_order;

      if (!deadline && !isPreOrder) {
        deadline =
          Date.parse(
            moment(myBucketCtx.state.myBuckets[0].createdAt).format("LLL")
          ) +
          1000 * 60 * 30;
      }
      else if (!deadline && isPreOrder) {
        deadline =
          Date.parse(
            moment(myBucketCtx.state.myBuckets[0].createdAt).format("LLL")
          ) +
          1000 * 60 * 40; // Set the deadline to 40 minutes (40 * 60 seconds) from the createdAt time for pre-orders
      }
      const interval = setInterval(() => getTime(deadline), 1000);
      // if (!isPreOrder) {
      //   const interval = setInterval(() => getTime(deadline), 1000);
      //   return () => clearInterval(interval);
      // }

      return () => clearInterval(interval);
    }
    layoutCtx.initLayout(false, true, true);
    globalCtx.hideSubToolbar();
    return () => {
      layoutCtx.clearLayout();
      globalCtx.showSubToolbar();
      userCtx.getUserDetails();
    };
  }, [myBucketCtx.state.myBuckets]);

  // useEffect(() => {
  //   // console.log("javhadd");
  //   if (myBucketCtx.state.myBuckets.length === 0) {
  //     navigate("/");
  //   }
  // }, [myBucketCtx.state.myBuckets]);

  const getTime = () => {
    // console.log(deadline);
    if (deadline >= Date.now()) {
      const time = deadline - Date.now();
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    } else {
      myBucketCtx.deleteAllBucket();
      myBucketCtx.clearBucketData();
      navigate("/")
    }
  };

  const deliveryType = (event) => {
    if (event.target.value === "no") {
      setDelivery(false);
      //decrease balance by 5000
    }
    if (event.target.value === "yes") {
      setDelivery(true);
      //increase balance by 5000
    }
    if (event.target.value === "choice") {
      setDelivery(null);
    }
  };

  const bonusPointUsage = (bonusPercentage) => {
    let a;
    bonus.forEach((el) => {
      if (el.active) {
        a = el.bonusSize;
      }
    });
    let prevBonus = [
      { bonusSize: 10, active: false },
      { bonusSize: 25, active: false },
      { bonusSize: 50, active: false },
    ];

    prevBonus.forEach((el) => {
      if (el.bonusSize === bonusPercentage) {
        if (el.bonusSize === a) {
          el.active = false;
          if (userCtx.state.userDetail.bonusPoint > 0) {
            setUsedBonus(0);
          }
        } else {
          if (userCtx.state.userDetail.bonusPoint > 0) {
            if (
              (myBucketCtx.state.bucketTotalPrice * el.bonusSize) / 100 <=
              userCtx.state.userDetail.bonusPoint
            ) {
              setUsedBonus(
                Math.floor(
                  (myBucketCtx.state.bucketTotalPrice * el.bonusSize) / 100
                )
              );
            } else {
              setUsedBonus(userCtx.state.userDetail.bonusPoint);
            }
          }
          el.active = true;
        }
      } else {
        el.active = false;
      }
    });
    setBonus(prevBonus);
  };

  const setDiliveryAddress = (event) => {
    setDeliveryAddress(event.target.value);
  };
  const setOrderItemType = (type) => {
    if (orderItem[type] === true) {
      setOrderItem({
        water: false,
        ground: false,
        vase: false,
      });
    } else {
      setOrderItem({ [type]: true });
    }
  };
  //generate random number
  const randomNumberInRange = () => {
    if (randomNumber === 0) {
      setRandomNumber(Math.random().toString().substring(2, 7));
    }
  };
  const continueOrder = (currentStep, isNextStep) => {

    if (!lockStep) {
      currentStep = `step${currentStep}`;
      if (isNextStep) {
        if (currentStep === "step1") {
          setStep({
            ...step,
            step1: { ...step.step1, current: false, complete: true },
            step3: { ...step.step3, current: true },
          });
          randomNumberInRange();
          console.log(randomNumber);
          setActiveStep(3);
        }
        if (myBucketCtx.state.myBuckets[0].pre_order) {
          if (currentStep === "step3") {
            setStep({
              ...step,
              step3: { ...step.step3, current: false, complete: true },
              step4: { ...step.step4, current: true },
            });
            setActiveStep(4);
          }
          else {
            setStep({
              ...step,
              step1: { ...step.step1, current: false, complete: true },
              step4: { ...step.step4, current: true },
            });
            setActiveStep(4);
          }
        }

      } else {
        if (currentStep === "step1") {
          console.log(randomNumber);
          setStep({
            ...step,
            step1: { ...step.step1, current: true },
            step3: { ...step.step3, current: false },
            step4: { ...step.step4, current: false },
          });
          setActiveStep(1);
        }
        if (currentStep === "step3" && step.step1.complete) {
          setStep({
            ...step,
            step1: { ...step.step1, current: false },
            step3: { ...step.step3, current: true },
            step4: { ...step.step4, current: false },
          });
          setActiveStep(3);
        }
        if (currentStep === "step4" && step.step3.complete) {
          setStep({
            ...step,
            step1: { ...step.step1, current: false },
            step3: { ...step.step3, current: false },
            step4: { ...step.step4, current: true },
          });
          setActiveStep(4);
        }
      }
    }
  };
  const closePaymentpage = () => {
    navigate("/");
  };
  const gotoGarden = () => {
    navigate("/");
  }
  const closeDialong = () => {
    setOpenDialog(false);
    setPaymentError("");
  };

  const purchaseOrder = async (currentStep, isNextSte, isPreOrderParam) => {
    console.log(isPreOrderParam);
    if (isPreOrderParam) {
      if (myBucketCtx.state.myBuckets.length > 0) {
        continueOrder(currentStep, isNextSte);
        setLockStep(true);
        //extra item
        var orderExtraItem = "";
        for (const [key, value] of Object.entries(orderItem)) {
          if (value) {
            orderExtraItem = key;
          }
        }
        let isPreOrder = false;
        if (myBucketCtx.state.myBuckets[0].pre_order) {
          isPreOrder = true;
        }
        const result = await orderCtx.addOrder(
          usedBonus,
          myBucketCtx.state.bucketTotalPrice -
          usedBonus +
          (delivery ? 5000 : 0) +
          (orderItem.water || orderItem.ground || orderItem.vase ? 10000 : 0),
          userCtx.state.userDetail.f_name + "-" + randomNumber,
          delivery,
          deliveryAddress,
          isPreOrder ? "Шинэ урьдчилсан захиалга" : "Шинэ захиалга",
          orderExtraItem,
          isPreOrder,
          "Хийгдсэн"
        );
        myBucketCtx.getAllBucket();
        myBucketCtx.clearBucketData();

      }
    } else {
      if (step.step1.current) {
        continueOrder(currentStep, isNextSte);
        let isPreOrder = false;
        const result = await orderCtx.createLogOrder(
          usedBonus,
          myBucketCtx.state.bucketTotalPrice -
          usedBonus +
          (delivery ? 5000 : 0) +
          (orderItem.water || orderItem.ground || orderItem.vase ? 10000 : 0),
          userCtx.state.userDetail.f_name + "-" + randomNumber,
          delivery,
          deliveryAddress,
          isPreOrder ? "Шинэ урьдчилсан захиалга" : "Шинэ захиалга",
          orderExtraItem,
          isPreOrder,
          "Хийгдээгүй"
        );
      } else {
        const result = await orderCtx.getBankTransaction(
          userCtx.state.userDetail.f_name + "-" + randomNumber
        );
        // if (myBucketCtx.state.myBuckets.length > 0) {
        //   continueOrder(currentStep, isNextSte);
        //   setLockStep(true);
        //   //extra item
        //   var orderExtraItem = "";
        //   for (const [key, value] of Object.entries(orderItem)) {
        //     if (value) {
        //       orderExtraItem = key;
        //     }
        //   }
        //   let isPreOrder = false;
        //   if (myBucketCtx.state.myBuckets[0].pre_order) {
        //     isPreOrder = true;
        //   }
        //   const result = await orderCtx.addOrder(
        //     usedBonus,
        //     myBucketCtx.state.bucketTotalPrice -
        //     usedBonus +
        //     (delivery ? 5000 : 0) +
        //     (orderItem.water || orderItem.ground || orderItem.vase
        //       ? 10000
        //       : 0),
        //     userCtx.state.userDetail.f_name + "-" + randomNumber,
        //     delivery,
        //     deliveryAddress,
        //     isPreOrder ? "Шинэ урьдчилсан захиалга" : "Шинэ захиалга",
        //     orderExtraItem,
        //     isPreOrder,
        //     "Хийгдсэн"
        //   );
        //   myBucketCtx.getAllBucket();
        // }
        if (result) {
          if (result.amount) {
            if (
              result.amount >=
              myBucketCtx.state.bucketTotalPrice -
              usedBonus +
              (delivery ? 5000 : 0) +
              (orderItem.water || orderItem.ground || orderItem.vase
                ? 10000
                : 0)
            ) {
              if (myBucketCtx.state.myBuckets.length > 0) {
                continueOrder(currentStep, isNextSte);
                setLockStep(true);
                //extra item
                var orderExtraItem = "";
                for (const [key, value] of Object.entries(orderItem)) {
                  if (value) {
                    orderExtraItem = key;
                  }
                }
                let isPreOrder = false;
                if (myBucketCtx.state.myBuckets[0].pre_order) {
                  isPreOrder = true;
                }
                const result = await orderCtx.addOrder(
                  usedBonus,
                  myBucketCtx.state.bucketTotalPrice -
                  usedBonus +
                  (delivery ? 5000 : 0) +
                  (orderItem.water || orderItem.ground || orderItem.vase
                    ? 10000
                    : 0),
                  userCtx.state.userDetail.f_name + "-" + randomNumber,
                  delivery,
                  deliveryAddress,
                  isPreOrder ? "Шинэ урьдчилсан захиалга" : "Шинэ захиалга",
                  orderExtraItem,
                  isPreOrder,
                  "Хийгдсэн"
                );
                myBucketCtx.getAllBucket();
                myBucketCtx.clearBucketData();

              }
            } else {
              setPaymentError(`Таны төлбөр дутуу орсон байна.`);
              setOpenDialog(true);
            }
          } else {
            setPaymentError(
              `Таны төлбөр хараахан баталгаажаагүй байна. Та түр хүлээгээд дахин оролдоно уу (Та гүйлгээний утгыг зөв оруулах шаардлагатай)`
            );
            setOpenDialog(true);
          }
        }
      }
    }
  };
  const deleteAllBucketClick = () => {
    myBucketCtx.deleteAllBucketClick();
    myBucketCtx.clearBucketData();
    navigate(`/`);
  };

  return (
    myBucketCtx.state.myBuckets[0].pre_order ? (
      <div className={css.PaymentPage}>
        <div className={css.PaymentHeader}>
          <div className="container-fluid">
            <div className="row">
              <div className="container-fluid">
                <div className="row">
                  <p className={css.topTopText}>Таны сагс</p>
                </div>
                <div className="row">
                  <p className={css.topBotText}>Худалдан авалт</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-center">
                <div
                  className={
                    step.step1.current ? css.ButtonMain : css.InacButtonMain
                  }
                  onClick={() => {
                    continueOrder(1, false);
                  }}
                >
                  <div className="row">
                    <div className="col-lg-2">
                      <button className={css.roundedBtn}>
                        <img
                          className={css.stepIcons}
                          src={Shopping}
                          alt="counter"
                        />
                      </button>
                    </div>
                    <div className="col">
                      <div className={css.topText}>Бараа сонголт</div>
                      <div className={css.botText}>
                        <p>1-р алхам</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col text-center">
                <div
                  className={
                    step.step4.current ? css.ButtonMain : css.InacButtonMain
                  }
                  onClick={() => {
                    continueOrder(4, false);
                  }}
                >
                  <div className="row">
                    <div className="col-lg-2">
                      <button className={css.roundedBtn}>
                        <img className={css.stepIcons} src={Flag} alt="counter" />
                      </button>
                    </div>
                    <div className="col">
                      <div className={css.topText}>
                        Баталгаа
                        <span
                          style={{ display: "none" }}
                          className={css.phoneSpace}
                        >
                          {" "}
                        </span>
                        жуулалт
                      </div>
                      <div className={css.botText}>
                        <p>2-р алхам</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={css.PaymentBody}>
          {step.step1.current && (
            <div className={css.PaymentProductList}>
              <div className="row mt-4">
                <div className={`col-lg-7 ${css.toPad}`}>
                  <div className="row">
                    <div className="col">
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "21px",
                          margin: "0 0 0 11px",
                          fontFamily: "Montserrat",
                          color: "#2A2C38",
                        }}
                      >
                        Таны сагсалсан бараанууд
                      </p>
                    </div>
                    <div className="col-auto">
                      <button
                        onClick={deleteAllBucketClick}
                        className={css.deleteButton}
                      >
                        <img
                          className={css.deleteBtnIcons}
                          src={Trash}
                          alt="counter"
                        />
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "22px",
                            fontFamily: "Montserrat",
                            color: "#2A2C38",
                            margin: "auto",
                          }}
                        >
                          Сагс хоослох
                        </p>
                      </button>
                    </div>
                  </div>
                  <div className={css.PaymentCounter}>
                    <img className={css.clearBasket} />
                  </div>
                  {myBucketCtx.state.myBuckets.map((el, index) => (
                    <PaymentProductList key={index} order={el} sendDataToParent={receiveDataFromChild} />
                  ))}
                </div>
                <div className="col-lg">
                  <div className={css.PaymentInfo}>
                    <div className="row">
                      <div className="col">
                        <p
                          className={css.phoneText}
                          style={{
                            fontSize: "24px",
                            fontWeight: "600",
                            lineHeight: "22px",
                            margin: "0 0 0 3px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Төлбөрийн мэдээлэл
                        </p>
                      </div>
                    </div>
                    {myBucketCtx.state.myBuckets.map((el, index) => (
                      <div key={index} className={`row ${css.hidePhone}`}>
                        <div
                          className="col"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "22px",
                            fontFamily: "Montserrat",
                            color: "#2A2C38",
                            margin: "auto",
                          }}
                        >
                          {el.name}
                        </div>
                        <div
                          className="col"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "22px",
                            fontFamily: "Montserrat",
                            color: "#2A2C38",
                            margin: "auto",
                          }}
                        >
                          {el.QtyPerpackage * el.piece} ш
                        </div>
                        <div
                          className="col-auto"
                          style={{
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "22px",
                            fontFamily: "Montserrat",
                            color: "#2A2C38",
                          }}
                        >
                          ₮{el.listPrice * el.QtyPerpackage * el.piece}
                        </div>
                      </div>
                    ))}
                    <div className={css.PaymentTotalPrice}>
                      <div className={`row mt-4 ${css.centerPhone}`}>
                        <div className="col">
                          <div className="row">
                            <p
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "700",
                                  lineHeight: "32px",
                                  marginTop: "10px",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                ₮
                                {(
                                  myBucketCtx.state.bucketTotalPrice -
                                  usedBonus +
                                  (delivery ? 5000 : 0) +
                                  (orderItem.water ||
                                    orderItem.ground ||
                                    orderItem.vase
                                    ? 10000
                                    : 0)
                                ).toLocaleString()}

                              </span>
                              <span
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "700",
                                  lineHeight: "24px",
                                  marginLeft: "10px",
                                  color: "#AEAFB5",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                <s>
                                  ₮
                                  {myBucketCtx.state.bucketTotalPrice.toLocaleString()}
                                </s>
                              </span>
                            </p>
                          </div>
                        </div>
                        <div
                          className="col-auto"
                          style={{
                            padding: "10px 20px 0 20px",
                          }}
                        >
                          {myBucketCtx.state.myBuckets[0].pre_order ? (
                            <button
                              className={css.PaymentContinueButton}
                              onClick={() => {
                                continueOrder(activeStep, true);
                                purchaseOrder(activeStep, true, true);
                              }}
                            >
                              Худалдан авах
                            </button>
                          ) : (
                            <button
                              className={css.PaymentContinueButton}
                              onClick={() => {
                                continueOrder(activeStep, true);
                                purchaseOrder(activeStep, true, false);
                              }}
                            >
                              Худалдан авах
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step.step4.current && (
            <div className={css.finishOrder}>
              <img src={Shop} className={css.iconBtn} alt="counter" />
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  fontFamily: "Montserrat",
                  color: "#2A2C38",
                }}
              >
                Таны төлбөр төлөлт амжилттай баталгаажлаа!
              </p>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  fontFamily: "Montserrat",
                  color: "#2A2C38",
                }}
              >
                Та миний захиалга цэснээс мэдээллээ харна уу
              </p>
              <button
                onClick={() => {
                  closePaymentpage();
                }}
                className={css.stepDoneBtn}
              >
                Онлайн худалдаа
              </button>
              <button
                onClick={() => {
                  closePaymentpage();
                }}
                className={css.step3App}
              >
                Миний захиалга цэс
              </button>
            </div>
          )}
        </div>
        <Dialog open={openDialog} onClose={closeDialong}>
          <DialogTitle>Төлбөрын мэдээлэл</DialogTitle>
          <DialogContent>
            <DialogContentText>{paymentError}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialong}>Хаах</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
      : (
        <div className={css.PaymentPage}>
          <div className={css.PaymentHeader}>
            <div className="container-fluid">
              <div className="row">
                <div className="container-fluid">
                  <div className="row">
                    <p className={css.topTopText}>Таны сагс</p>
                  </div>
                  <div className="row">
                    <p className={css.topBotText}>Худалдан авалт</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  <div
                    className={
                      step.step1.current ? css.ButtonMain : css.InacButtonMain
                    }
                    onClick={() => {
                      continueOrder(1, false);
                    }}
                  >
                    <div className="row">
                      <div className="col-lg-2">
                        <button className={css.roundedBtn}>
                          <img
                            className={css.stepIcons}
                            src={Shopping}
                            alt="counter"
                          />
                        </button>
                      </div>
                      <div className="col">
                        <div className={css.topText}>Бараа сонголт</div>
                        <div className={css.botText}>
                          <p>1-р алхам</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col text-center">
                  <div
                    className={
                      step.step3.current ? css.ButtonMain : css.InacButtonMain
                    }
                    onClick={() => {
                      continueOrder(3, false);
                    }}
                  >
                    <div className="row">
                      <div className="col-lg-2">
                        <button className={css.roundedBtn}>
                          {" "}
                          <img
                            className={css.stepIcons}
                            src={Receipt}
                            alt="counter"
                          />
                        </button>
                      </div>
                      <div className="col">
                        <div className={css.topText}>Төлбөр төлөлт</div>
                        <div className={css.botText}>
                          <p>2-р алхам</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col text-center">
                  <div
                    className={
                      step.step4.current ? css.ButtonMain : css.InacButtonMain
                    }
                    onClick={() => {
                      continueOrder(4, false);
                    }}
                  >
                    <div className="row">
                      <div className="col-lg-2">
                        <button className={css.roundedBtn}>
                          <img className={css.stepIcons} src={Flag} alt="counter" />
                        </button>
                      </div>
                      <div className="col">
                        <div className={css.topText}>
                          Баталгаа
                          <span
                            style={{ display: "none" }}
                            className={css.phoneSpace}
                          >
                            {" "}
                          </span>
                          жуулалт
                        </div>
                        <div className={css.botText}>
                          <p>3-р алхам</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.PaymentBody}>
            {step.step1.current && (
              <div className={css.PaymentProductList}>
                <div className="row mt-4">
                  <div className={`col-lg-7 ${css.toPad}`}>
                    <div className="row">
                      <div className="col">
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "21px",
                            margin: "0 0 0 11px",
                            fontFamily: "Montserrat",
                            color: "#2A2C38",
                          }}
                        >
                          Таны сагсалсан бараанууд
                        </p>
                      </div>
                      <div className="col-auto">
                        <button
                          onClick={deleteAllBucketClick}
                          // onClick={() => {
                          //   myBucketCtx.deleteAllBucket();
                          //   myBucketCtx.getAllBucket();
                          // }}
                          className={css.deleteButton}
                        >
                          {/* <a className="d-flex" href="https://gardenia.mn"> */}
                          <img
                            className={css.deleteBtnIcons}
                            src={Trash}
                            alt="counter"
                          />
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "22px",
                              fontFamily: "Montserrat",
                              color: "#2A2C38",
                              margin: "auto",
                            }}
                          >
                            Сагс хоослох
                          </p>
                          {/* </a> */}
                        </button>
                      </div>
                    </div>
                    <div className={css.PaymentCounter}>
                      <img className={css.clearBasket} />
                    </div>
                    {myBucketCtx.state.myBuckets.map((el, index) => (
                      <PaymentProductList key={index} order={el} sendDataToParent={receiveDataFromChild} />
                    ))}
                  </div>
                  <div className="col-lg">
                    <div className={css.PaymentInfo}>
                      <div className="row">
                        <div className="col">
                          <p
                            className={css.phoneText}
                            style={{
                              fontSize: "24px",
                              fontWeight: "600",
                              lineHeight: "22px",
                              margin: "0 0 0 3px",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Төлбөрийн мэдээлэл
                          </p>
                        </div>
                        <div className="col-auto">
                          <div className="row">
                            <div className={`col ${css.noColumnMargin}`}>
                              <img
                                className={css.deleteBtnIcons}
                                src={Hourglass}
                                alt="counter"
                              />
                            </div>
                            <div className={`col ${css.noColumnMargin}`}>
                              <p
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "700",
                                  lineHeight: "24px",
                                  fontFamily: "Montserrat",
                                  color: "#FF4F58",
                                }}
                              >
                                {minutes}:{seconds}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {myBucketCtx.state.myBuckets.map((el, index) => (
                        <div key={index} className={`row ${css.hidePhone}`}>
                          <div
                            className="col"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "22px",
                              fontFamily: "Montserrat",
                              color: "#2A2C38",
                              margin: "auto",
                            }}
                          >
                            {el.name}
                          </div>
                          <div
                            className="col"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "22px",
                              fontFamily: "Montserrat",
                              color: "#2A2C38",
                              margin: "auto",
                            }}
                          >
                            {el.QtyPerpackage * el.piece} ш
                          </div>
                          <div
                            className="col-auto"
                            style={{
                              fontSize: "14px",
                              fontWeight: "700",
                              lineHeight: "22px",
                              fontFamily: "Montserrat",
                              color: "#2A2C38",
                            }}
                          >
                            ₮{el.listPrice * el.QtyPerpackage * el.piece}
                          </div>
                        </div>
                      ))}
                      {/* <div className={css.PaymentBonus}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "16px",
                          fontFamily: "Montserrat",
                          textAlign: "center",
                          marginTop: "30px",
                        }}
                      >
                        Танд{" "}
                        <span style={{ color: "#FF4F58", fontWeight: "600" }}>
                          {userCtx.state.userDetail.bonusPoint &&
                            userCtx.state.userDetail.bonusPoint.toLocaleString()}
                        </span>{" "}
                        бонус оноо байна.
                      </p>
  
                      <div className="row">
                        <div className="col">
                          <button className={
                            bonus[0].active
                              ? css.buttonActive
                              : css.buttonInactive
                          } onClick={() => { bonusPointUsage(10); }}>
                            10%
                          </button>
                        </div>
                        <div className="col">
                          <button className={
                            bonus[1].active
                              ? css.buttonActive
                              : css.buttonInactive
                          } onClick={() => { bonusPointUsage(25); }}>
                            25%
                          </button>
                        </div>
                        <div className="col">
                          <button className={
                            bonus[2].active
                              ? css.buttonActive
                              : css.buttonInactive
                          } onClick={() => {
                            bonusPointUsage(50);
                          }} >
                            50%
                          </button>
                        </div>
                      </div> */}

                      {/* <div
                        className="row"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <button
                          className={
                            bonus[0].active
                              ? css.buttonActive
                              : css.buttonInactive
                          }
                          onClick={() => {
                            bonusPointUsage(10);
                          }}
                        >
                          10%
                        </button>
                        <button
                          className={
                            bonus[1].active
                              ? css.buttonActive
                              : css.buttonInactive
                          }
                          onClick={() => {
                            bonusPointUsage(25);
                          }}
                        >
                          25%
                        </button>
                        <button
                          className={
                            bonus[2].active
                              ? css.buttonActive
                              : css.buttonInactive
                          }
                          onClick={() => {
                            bonusPointUsage(50);
                          }}
                        >
                          50%
                        </button>
                      </div> */}
                      {/* </div> */}

                      <div className={css.PaymentTotalPrice}>
                        <div className={`row mt-4 ${css.centerPhone}`}>
                          <div className="col">
                            {/* <div className="row">
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "12px",
                                marginTop: "10px",
                                fontFamily: "Montserrat",
                              }}
                            >
                              <span
                                style={{
                                  color: "#FF4F58",
                                }}
                              >
                                {usedBonus.toLocaleString()}
                              </span>{" "}
                              Бонус оноо ашигласан
                            </p>
                          </div> */}
                            <div className="row">
                              <p
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "24px",
                                    fontWeight: "700",
                                    lineHeight: "32px",
                                    marginTop: "10px",
                                    fontFamily: "Montserrat",
                                  }}
                                >
                                  ₮
                                  {(
                                    myBucketCtx.state.bucketTotalPrice -
                                    usedBonus +
                                    (delivery ? 5000 : 0) +
                                    (orderItem.water ||
                                      orderItem.ground ||
                                      orderItem.vase
                                      ? 10000
                                      : 0)
                                  ).toLocaleString()}

                                </span>
                                <span
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "700",
                                    lineHeight: "24px",
                                    marginLeft: "10px",
                                    color: "#AEAFB5",
                                    fontFamily: "Montserrat",
                                  }}
                                >
                                  <s>
                                    ₮
                                    {myBucketCtx.state.bucketTotalPrice.toLocaleString()}
                                  </s>
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="col-auto"
                            style={{
                              padding: "10px 20px 0 20px",
                            }}
                          >
                            {myBucketCtx.state.myBuckets[0].pre_order ? (
                              <button
                                className={css.PaymentContinueButton}
                                onClick={() => {
                                  continueOrder(activeStep, true);
                                }}
                              >
                                Худалдан авах
                              </button>
                            ) : (
                              <button
                                className={css.PaymentContinueButton}
                                onClick={() => {
                                  continueOrder(activeStep, true);
                                  purchaseOrder(activeStep, true, false);
                                }}
                              >
                                Худалдан авах
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* {step.step3.current ? (
                      myBucketCtx.state.myBuckets[0].pre_order ? (
                        <button
                          className={css.PaymentContinueButton}
                          onClick={() => {
                            purchaseOrder(activeStep, true, true);
                          }}
                        >
                          Урьдчилсан захиалга дуусгах
                        </button>
                      ) : (
                        <button
                          className={css.PaymentContinueButton}
                          onClick={() => {
                            purchaseOrder(activeStep, true, false);
                          }}
                        >
                          Худалдан авах
                        </button>
                      )
                    ) : myBucketCtx.state.myBuckets[0].pre_order ? (
                      <button
                        className={css.PaymentContinueButton}
                        onClick={() => {
                          purchaseOrder(activeStep, true, true);
                          continueOrder(activeStep, true);
                        }}
                      >
                        Үргэлжлүүлэх
                      </button>
                    ) : (
                      <button
                        className={css.PaymentContinueButton}
                        onClick={() => {
                          purchaseOrder(activeStep, true, false);
                          continueOrder(activeStep, true);
                        }}
                      >
                        Үргэлжлүүлэх
                      </button>
                    )} */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step.step3.current &&
              (myBucketCtx.state.myBuckets[0].pre_order ? (
                <div className={`col mt-4 ${css.bankFormContainer}`}>
                  <div className="col-auto">
                    <div className="row">
                      <div className={`col ${css.topTextPay}`}>
                        Төлбөрийн мэдээлэл
                      </div>
                      <div className="col-auto">
                        <div className="row">
                          <img
                            className={css.deleteBtnIcons}
                            src={Hourglass}
                            alt="counter"
                          />
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "700",
                              lineHeight: "24px",
                              fontFamily: "Montserrat",
                              color: "#FF4F58",
                            }}
                          >
                            {minutes}:{seconds}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className={css.leftText}>Банк:</p>
                      <p className={css.leftText}>Дансны дугаар:</p>
                      <p className={css.leftText}>Төлөх дүн:</p>
                      <p className={css.leftText}>Дансны нэр:</p>
                      <p className={css.leftText}>Гүйлгээний утга:</p>
                    </div>
                    <div className="col" style={{ textAlign: "end" }}>
                      <p className={css.rightText}>Голомт Банк</p>
                      <p className={css.rightText}>
                        1205172539
                        <i
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            active: "background-color: rgb(123, 180, 17)",
                          }}
                          className={css.copy1 + " " + "nav-icon fa fa-copy"}
                          onClick={() =>
                            navigator.clipboard.writeText("1205172539")
                          }
                        />
                      </p>
                      <p className={css.rightText}>
                        {myBucketCtx.state.bucketTotalPrice -
                          usedBonus +
                          (delivery ? 5000 : 0) +
                          (orderItem.water || orderItem.ground || orderItem.vase
                            ? 10000
                            : 0)}
                        <i
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            active: "background-color: rgb(123, 180, 17)",
                          }}
                          className={css.copy1 + " " + "nav-icon fa fa-copy"}
                          onClick={() => {
                            let copyText =
                              myBucketCtx.state.bucketTotalPrice -
                              usedBonus +
                              (delivery ? 5000 : 0) +
                              (orderItem.water || orderItem.ground || orderItem.vase
                                ? 10000
                                : 0);
                            navigator.clipboard.writeText(copyText);
                          }}
                        />
                      </p>
                      <p className={css.rightText}>Гардения</p>
                      <p className={css.rightText}>
                        {userCtx.state.userDetail.f_name}-{randomNumber}
                        <i
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            active: "background-color: rgb(123, 180, 17)",
                          }}
                          className={css.copy1 + " " + "nav-icon fa fa-copy"}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              userCtx.state.userDetail.f_name +
                              "-" +
                              randomNumber.toString()
                            );
                          }}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <button
                      className={css.stepBackBtn}
                      onClick={() => {
                        continueOrder(1, false);
                      }}
                    >
                      Буцах
                    </button>
                    <button
                      className={css.step2App}
                      onClick={() => {
                        continueOrder(activeStep, true);
                        purchaseOrder(activeStep, true, true);

                      }}
                    >
                      Баталгаажуулах
                    </button>
                  </div>
                </div>
              ) : (
                <div className={`col mt-4 ${css.bankFormContainer}`}>
                  <div className="col-auto">
                    <div className="row">
                      <div className={`col ${css.topTextPay}`}>
                        Төлбөрийн мэдээлэл
                      </div>
                      <div className="col-auto">
                        <div className="row">
                          <img
                            className={css.deleteBtnIcons}
                            src={Hourglass}
                            alt="counter"
                          />
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "700",
                              lineHeight: "24px",
                              fontFamily: "Montserrat",
                              color: "#FF4F58",
                            }}
                          >
                            {minutes}:{seconds}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className={css.leftText}>Банк:</p>
                      <p className={css.leftText}>Дансны дугаар:</p>
                      <p className={css.leftText}>Төлөх дүн:</p>
                      <p className={css.leftText}>Дансны нэр:</p>
                      <p className={css.leftText}>Гүйлгээний утга:</p>
                    </div>
                    <div className="col" style={{ textAlign: "end" }}>
                      <p className={css.rightText}>Голомт Банк</p>
                      <p className={css.rightText}>
                        1205172539
                        <i
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            active: "background-color: rgb(123, 180, 17)",
                          }}
                          className={css.copy1 + " " + "nav-icon fa fa-copy"}
                          onClick={() =>
                            navigator.clipboard.writeText("1205172539")
                          }
                        />
                      </p>
                      <p className={css.rightText}>
                        {myBucketCtx.state.bucketTotalPrice -
                          usedBonus +
                          (delivery ? 5000 : 0) +
                          (orderItem.water || orderItem.ground || orderItem.vase
                            ? 10000
                            : 0)}
                        <i
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            active: "background-color: rgb(123, 180, 17)",
                          }}
                          className={css.copy1 + " " + "nav-icon fa fa-copy"}
                          onClick={() => {
                            let copyText =
                              myBucketCtx.state.bucketTotalPrice -
                              usedBonus +
                              (delivery ? 5000 : 0) +
                              (orderItem.water || orderItem.ground || orderItem.vase
                                ? 10000
                                : 0);
                            navigator.clipboard.writeText(copyText);
                          }}
                        />
                      </p>
                      <p className={css.rightText}>Гардения</p>
                      <p className={css.rightText}>
                        {userCtx.state.userDetail.f_name}-{randomNumber}
                        <i
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            active: "background-color: rgb(123, 180, 17)",
                          }}
                          className={css.copy1 + " " + "nav-icon fa fa-copy"}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              userCtx.state.userDetail.f_name +
                              "-" +
                              randomNumber.toString()
                            );
                          }}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <button
                      className={css.stepBackBtn}
                      onClick={() => {
                        continueOrder(1, false);
                      }}
                    >
                      Буцах
                    </button>
                    <button
                      className={css.step2App}
                      onClick={() => {
                        purchaseOrder(activeStep, true, false);
                      }}
                    >
                      Баталгаажуулах
                    </button>
                  </div>
                </div>
              ))}
            {step.step4.current && (
              <div className={css.finishOrder}>
                <img src={Shop} className={css.iconBtn} alt="counter" />
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "24px",
                    fontFamily: "Montserrat",
                    color: "#2A2C38",
                  }}
                >
                  Таны төлбөр төлөлт амжилттай баталгаажлаа!
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    fontFamily: "Montserrat",
                    color: "#2A2C38",
                  }}
                >
                  Та миний захиалга цэснээс мэдээллээ харна уу
                </p>
                <button
                  onClick={() => {
                    closePaymentpage();
                  }}
                  className={css.stepDoneBtn}
                >
                  Онлайн худалдаа
                </button>
                <button
                  onClick={() => {
                    closePaymentpage();
                  }}
                  className={css.step3App}
                >
                  Миний захиалга цэс
                </button>
              </div>
            )}
          </div>
          <Dialog open={openDialog} onClose={closeDialong}>
            <DialogTitle>Төлбөрын мэдээлэл</DialogTitle>
            <DialogContent>
              <DialogContentText>{paymentError}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialong}>Хаах</Button>
            </DialogActions>
          </Dialog>
        </div>
      )

  );
};

export default PaymentPage;
