import React from "react";
import { NavLink } from "react-router-dom";
import css from "./style.module.css";

const MenuItem = (props) => {
  const navLinkStyles =({ isActive })=> {
    return{
      fontWeight: isActive ? '600' : '600',
      borderBottom : isActive ? 'solid' : "",
      color: isActive ? "#ff4f58" : "",
      paddingBottom: isActive ? '18px' : '18px',
    }
  }
  return(
  <li className={css.MenuItem}>
    <NavLink style={navLinkStyles} exact={props.exact} to={props.link}>
      {props.children}
    </NavLink>
  </li>
  )
};

export default MenuItem;
