import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const MainCategoryContext = React.createContext();

const initialState = {
  categories: [],
  loading: false
};
export const MainCategoryStore = (props) => {
  const [state, setState] = useState(initialState);

  const MainCategory = () => {
    axios
      .get("mainCategories")
      .then((result) => {
        console.log(result);
        setState({ ...state, categories: result.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }; 

  const mainCategoryActive = () => {
    axios
      .get("maincategories/mainCategoryActive")
      .then((result) => {
        // console.log(result);
        setState({ ...state, categories: result.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }; 


  const checkCategory = () => {
    axios
      .get("maincategories/checkCategory")
      .then((result) => {
        console.log(result);
        setState({ ...state, categories: result.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const updateCategory = async (body, categoryId, changePhoto, photoName) => {
    console.log(body, categoryId, changePhoto, photoName);
    var formData = new FormData();
    formData.append("file", changePhoto);
    formData.append("pname", photoName);
    formData.append("active", body);
    setState({ ...state, loading: true });
    const res = await axios
      .put("maincategories/" + categoryId, formData )
      .then((result) => {
        setState({ ...state, success: true, loading: false });
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
    return res;
  };
  return (
    <MainCategoryContext.Provider
      value={{
        state,
        MainCategory,
        updateCategory,
        checkCategory,
        mainCategoryActive
      }}
    >
      {props.children}
    </MainCategoryContext.Provider>
  );
};
export default MainCategoryContext;
