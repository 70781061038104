import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const PreOrderContext = React.createContext();

const initialState = {
  preOrders: [],
  loading: false,
};
export const PreOrderStore = (props) => {
  const [state, setState] = useState(initialState);

  const getPreOrderSchedule = () => {
    setState({ ...state, loading: true });
    axios
      .get("preProducts")
      .then((result) => {
        const reduced = Object.values(
          result.data.preORderSchedule.reduce((acc, { start }) => {
            acc[start] ??= { start };
            return acc;
          }, {})
        );
        // console.log(result.data.preOrderSchedule);
        // const reduced = result.data.preORderSchedule;
        console.log(reduced);
        setState({
          ...state,
          preOrders: reduced,
          loading: false,
        });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };

  return (
    <PreOrderContext.Provider value={{ state, getPreOrderSchedule }}>
      {props.children}
    </PreOrderContext.Provider>
  );
};
export default PreOrderContext;
