
import React, { useState, useContext, useEffect } from "react";
import css from "./style.module.css";
import LayoutContext from "../../context/LayoutContext";
import GlobalContext from "../../context/GlobalContext";
import OrderPage from "../OrderPage";

const CategoryPage = (props) => {
  // states
  const [showOrderPage, setShowOrderPage] = useState(false);
  const [showCategoryPage, setShowCategoryPage] = useState(true);
  const [categoryIdVar, setCategoryIdVar] = useState("c62bf54e-4693-4217-bc57-0eb22d2bcbda");

  // contexts
  const layoutCtx = useContext(LayoutContext);
  const globalCtx = useContext(GlobalContext);

  useEffect(() => {
    layoutCtx.initLayout(true, false, false);
    redirectToDefaultCategory(); // Call the function to redirect to the default category
    return () => {
      layoutCtx.clearLayout();
    };
  }, []);

  // const redirectToCategory = (categoryName, categoryId) => {
  //   console.log("redirectToCategory")
  //   console.log(globalCtx.state.activeCategory)
  //   setCategoryIdVar(globalCtx.state.activeID);
  //   globalCtx.setActiveCategory(globalCtx.state.activeCategory);
  //   setShowOrderPage(true);
  //   setShowCategoryPage(false);
  // };

  const redirectToDefaultCategory = () => {
    if (globalCtx.state.activeID === undefined
      || globalCtx.state.activeCategory === ""
      || globalCtx.state.activeCategory === undefined
      || globalCtx.state.activeID === "") {
      setCategoryIdVar("c62bf54e-4693-4217-bc57-0eb22d2bcbda");
      globalCtx.setActiveCategory("Гоёлын цэцэг", "c62bf54e-4693-4217-bc57-0eb22d2bcbda");
      setShowOrderPage(true);
      setShowCategoryPage(false);
    }
    else {
      setCategoryIdVar(globalCtx.state.activeID);
      globalCtx.setActiveCategory(globalCtx.state.activeCategory, globalCtx.state.activeID);
      setShowOrderPage(true);
      setShowCategoryPage(false);
    }

    // Modify the parameters here for your default category
    // redirectToCategory(
    //   "Гоёлын цэцэг",
    //   "c62bf54e-4693-4217-bc57-0eb22d2bcbda"
    // );
  };

  return (
    <>
      {showOrderPage && <OrderPage activeCatId={categoryIdVar} />}
      {showCategoryPage && (
        <div className={css.Categories}>
          {/* Category buttons */}
          <button
            // onClick={() => {
            //   redirectToCategory(
            //     "Гоёлын цэцэг",
            //     "c62bf54e-4693-4217-bc57-0eb22d2bcbda"
            //   );
            // }}
            className={css.ontsgoi}
          ></button>
          {/* Add other category buttons here */}
        </div>
      )}
    </>
  );
};

export default CategoryPage;

// huuchin kod

// import React, { useState, useContext, useEffect } from "react";
// import css from "./style.module.css";
// import LayoutContext from "../../context/LayoutContext";
// import GlobalContext from "../../context/GlobalContext";
// import OrderPage from "../OrderPage";

// const CategoryPage = (props) => {
//   //states
//   const [showOrderPage, setShowOrderPage] = useState(false);
//   const [showCategoryPage, setShowCategoryPage] = useState(true);
//   const [categoryIdVar, setCategoryIdVar] = useState(null);

//   //contexts
//   const layoutCtx = useContext(LayoutContext);
//   const globalCtx = useContext(GlobalContext);

//   useEffect(() => {
//     layoutCtx.initLayout(true, false, false);
//     return () => {
//       layoutCtx.clearLayout();
//     };
//   }, []);

//   const redirectToCategory = (categoryName, categoryId) => {
//     setCategoryIdVar(categoryId);
//     globalCtx.setActiveCategory(categoryName);
//     setShowOrderPage(true);
//     setShowCategoryPage(false);
//   };

//   return (
//     <>
//       {showOrderPage && <OrderPage activeCatId={categoryIdVar} />}
//       {showCategoryPage && (
//         <div className={css.Caterogies}>
//           <button
//             onClick={() => {
//               redirectToCategory(
//                 "Нидерланд гоёлын цэцэг",
//                 "2c5ac4b6-d3e5-4dfa-bece-b737bd442852"
//               );
//             }}
//             className={css.ontsgoi}
//           ></button>
//           <button
//             onClick={() => {
//               redirectToCategory(
//                 "Гоёлын цэцэг",
//                 "c62bf54e-4693-4217-bc57-0eb22d2bcbda"
//               );
//             }}
//             className={css.goyliin}
//           ></button>
//           <button
//             onClick={() => {
//               redirectToCategory(
//                 "Тасалгааны цэцэг",
//                 "25dddf3b-89d7-400c-a286-9e5e76e14ad4"
//               );
//             }}
//             className={css.tasalgaan}
//           ></button>
//           <button
//             onClick={() => {
//               redirectToCategory(
//                 "Хатаасан цэцэг",
//                 "f9aa3bc5-44ed-4741-8607-d29519cef8ce"
//               );
//             }}
//             className={css.hataasan}
//           ></button>
//           <button
//             onClick={() => {
//               redirectToCategory(
//                 "Хиймэл цэцэг",
//                 "2759d7f3-815f-4a38-b5a2-e951967f4a44"
//               );
//             }}
//             className={css.tsetsgiinBagts}
//           ></button>
//           <button
//             onClick={() => {
//               redirectToCategory(
//                 "Туслах материал",
//                 "7446e84c-57d2-453c-8d46-30f0e7d940e8"
//               );
//             }}
//             className={css.tuslahMat}
//           ></button>
//           <button
//             onClick={() => {
//               redirectToCategory(
//                 "Тэжээл",
//                 "44c5828e-4b4a-40b4-8d98-808b17172061"
//               );
//             }}
//             className={css.tejeel}
//           ></button>
//           <button
//             onClick={() => {
//               redirectToCategory("Урьдчилсан", null);
//             }}
//             className={css.uridchilsan}
//           ></button>
//           <button
//             onClick={() => {
//               redirectToCategory("Хямдарсан", null);
//             }}
//             className={css.hyamdral}
//           ></button>
//         </div>
//       )}
//     </>
//   );
// };

// export default CategoryPage;
