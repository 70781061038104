import React, { useState, useContext, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import css from "./style.module.css";
import './variables.css'
import Toolbar from "../../components/Users/Toolbar";
import SideBar from "../../components/Users/SideBar";
import HomePage from "../HomePage";
import HelpPage from "../HelpPage";
import AboutPage from "../AboutUsPage";
import ContactPage from "../Contactpage";
import CategoryPage from "../CategoryPage";
import BlogPage from "../BlogPage";
import BlogGrid from "../BlogGrid";
import PaymentPage from "../Payment";
import Footer from "../../components/Users/Footer";
import SubToolbar from "../../components/Users/SubToolbar";
import OrderPage from "../OrderPage";
import AdminPage from "../AdminPage";
import MyOrderPage from "../MyOrderPage";
import PreOrderPage from "../PreOrderPage";
import { CalendarPage } from "../CalendarPage";
//contexts
import { NewProductStore } from "../../context/NewProductContext";
import { SliderStore } from "../../context/SlideContext";
import { AdminUserStore } from "../../context/admin/AdminUserContext";
import { BankStore } from "../../context/admin/BankContext";
import { DashboardStore } from "../../context/admin/DashboardContext";

import LayoutContext from "../../context/LayoutContext";
import UserContext from "../../context/UserContext";
import BucketContext from "../../context/BucketContext";
import CategoryContext from "../../context/CategoyContext";

const App = (props) => {
  //contexts
  const userCtx = useContext(UserContext);
  const layoutCtx = useContext(LayoutContext);
  const bucketCtx = useContext(BucketContext);
  const categoyCtx = useContext(CategoryContext);

  //states
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSideBar = () => {
    setShowSidebar((prevState) => !prevState);
  };

  useEffect(() => {
    userCtx.getUserDetails();
    bucketCtx.getAllBucket();
    categoyCtx.getAllCategory();
  }, []);

  useEffect(() => {
    userCtx.getUserDetails();
    bucketCtx.clearBucketData();
    bucketCtx.getAllBucket();
  }, [userCtx.state.userId]);

  return (
    <div>
      {layoutCtx.state.mainMenu && <Toolbar toggleSideBar={toggleSideBar} />}
      {layoutCtx.state.subMenu && <Toolbar toggleSideBar={toggleSideBar} />}

      <SideBar showSidebar={showSidebar} toggleSideBar={toggleSideBar} />
      <main
        className={
          layoutCtx.state.subMenu ? css.ContentWithSubMenu : css.Content
        }
      >
        <Suspense>
          {(() => {
            switch (userCtx.state.role) {
              case null:
                return (
                  <Routes>
                    <Route path="/help" exact element={<HelpPage />} />
                    <Route path="/about" exact element={<AboutPage />} />
                    <Route path="/contact" exact element={<ContactPage />} />
                    <Route path="/category" exact element={<CategoryPage />} />
                    <Route path="/blog" exact element={<BlogGrid />} />
                    <Route path="/blog/:id" exact element={<BlogPage />} />
                    <Route path="/order/:id" exact element={<OrderPage />} />
                    <Route
                      path="*"
                      element={
                        <SliderStore>
                          <NewProductStore>
                            <HomePage />
                          </NewProductStore>
                        </SliderStore>
                      }
                    />
                  </Routes>
                );
              case "admin":
                return (
                  <Routes>
                    <Route
                      path="*"
                      element={
                        <SliderStore>
                          <AdminUserStore>
                            <BankStore>
                              <DashboardStore>
                                <AdminPage />
                              </DashboardStore>
                            </BankStore>
                          </AdminUserStore>
                        </SliderStore>
                      }
                    />
                  </Routes>
                );
              case "user":
                return (
                  <Routes>
                    <Route path="/help" exact element={<HelpPage />} />
                    <Route path="/about" exact element={<AboutPage />} />
                    <Route path="/contact" exact element={<ContactPage />} />
                    <Route path="/category" exact element={<CategoryPage />} />
                    {/* <Route path="/blog" exact element={<BlogGrid />} /> */}
                    <Route path="/blog" exact element={<BlogGrid />} />
                    <Route path="/blog/:id" exact element={<BlogPage />} />
                    <Route path="/payment" exact element={<PaymentPage />} />
                    <Route path="/myOrders" exact element={<MyOrderPage />} />
                    <Route path="/calendar" exact element={<CalendarPage />} />
                    <Route
                      path="/myPreOrders"
                      exact
                      element={<PreOrderPage />}
                    />
                    <Route path="/order/:id" exact element={<OrderPage />} />
                    <Route
                      path="*"
                      element={
                        <SliderStore>
                          <NewProductStore>
                            <HomePage />
                          </NewProductStore>
                        </SliderStore>
                      }
                    />
                  </Routes>
                );
              default:
                return null;
            }
          })()}
        </Suspense>
      </main>
      {layoutCtx.state.footer && <Footer />}
    </div>
  );
};

export default App;
