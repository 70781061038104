import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const ProductContext = React.createContext();
const initialState = {
  loading: false,
  error: null,
  Products: [],
  isMore: true,
};
export const ProductStore = (props) => {
  const [state, setState] = useState(initialState);

  const decrementTotalOrder = (product_id, date) => {
    const prevState = state.Products;
    if (date) {
      prevState.forEach((el) => {
        if (el.Id === product_id && el.ScheduledDate === date) {
          if (el.Piece > 0) {
            el.Piece--;
            el.Qty = el.Qty + el.QtyPerpackage;
          }
        }
      });
    } else {
      prevState.forEach((el) => {
        if (el.Id === product_id) {
          if (el.Piece > 0) {
            el.Piece--;
            el.Qty = el.Qty + el.QtyPerpackage;
          }
        }
      });
    }

    setState({ ...state, prevState });
  };
  const getAllProduct = async (type, limit, catId) => {
    setState({
      loading: true,
      error: null,
      Products: [],
    });
    const res = await axios
      .get("products/" + type + `?limit=${limit}&catId=${catId}`)
      .then((result) => {
        // console.log(result.data.products.length)
        if (result.data.products.length === 0) {
          setState({
            ...state,
            Products: [], /* remove this line if error , хямдрал category засвар*/
            isMore: false,
          });
        } else {
          if (type !== "Хямдарсан" && type !== "admin") {
            setState((prevState) => {
              const existingIds = new Set(prevState.Products.map((product) => product.Id));
              // const newArray = result.data.products.filter((el) => !existingIds.has(el.Id) && el.Qty > 0);
              // Барааг хасахруу оруулж байгааг засах
              const newArray = result.data.products.filter((el) => !existingIds.has(el.Id));
              return {
                ...prevState,
                Products: [...prevState.Products, ...newArray],
                loading: false,
              };
            });
          } else {
            setState({
              ...state,
              Products: result.data.products,
              loading: false,
            });
          }
        }
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        return err.response.data.error.message;
      });

    return res;
  };
  const getAdminPreProducts = () => {
    setState({ ...state, loading: true });
    axios
      .get("preProducts/admin")
      .then((result) => {
        setState({
          ...state,
          Products: result.data.products,
          loading: false,
        });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };
  const getAdminFilterPreProducts = (startDate, endDate) => {
    console.log(startDate, endDate);
    setState({ ...state, loading: true });
    console.log();
    axios
      .get("preProducts/admin/filter", { // Update the API endpoint to match the router path
        params: { startDate, endDate }, // Send startDate and endDate as query parameters
      })
      .then((result) => {
        setState({
          ...state,
          Products: result.data.products,
          loading: false,
        });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };
  const updatePreProduct = (form) => {
    setState({ ...state, loading: true });
    const res = axios
      .put("preProducts/admin", {
        scheduleDate: form.scheduleDate,
        dayan_id: form.productId,
        PriceListName: form.priceType,
        ItemCode: form.ItemCode,
      })
      .then((result) => {
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        return err.response.data.error.message;
      });
    return res;
  };
  const getProductBalance = (product_id) => {
    setState({ ...state, loading: true });
    axios
      .get("products/balance/" + product_id)
      .then((result) => {
        const prevState = state.Products;
        prevState.forEach((el) => {
          if (el.Id === product_id) {
            el.Qty = result.data.Qty;
            el.discountNumber = result.data.discountNumber;
            el.DetailImages = result.data.DetailImages;
          }
        });
        setState({ ...state, prevState, loading: false });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };
  const incrementTotalOrder = (product_id, date) => {
    const prevState = state.Products;
    if (date) {
      prevState.forEach((el) => {
        if (el.Id === product_id && el.ScheduledDate === date) {
          if (el.QtyPerpackage <= el.Qty) {
            el.Piece++;
            el.Qty = el.Qty - el.QtyPerpackage;
          }
        }
      });
    } else {
      prevState.forEach((el) => {
        if (el.Id === product_id) {
          if (el.QtyPerpackage <= el.Qty) {
            el.Piece++;
            el.Qty = el.Qty - el.QtyPerpackage;
          }
        }
      });
    }

    setState({ ...state, prevState });
  };
  const clearProduct = (product_id) => {
    const prevState = state.Products;
    prevState.forEach((el) => {
      if (el.Id === product_id) {
        el.Piece = 0;
      }
    });
    setState({ ...state, prevState });
  };
  const addProductImage = (product_id, file) => {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("dayan_id", product_id);
    const res = axios
      .post("products", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        const prevState = state.Products;
        prevState.forEach((el) => {
          if (el.Id === product_id) {
            el.DetailImages.push(result.data.data);
          }
        });
        setState({ ...state, prevState, error: null });
        return result;
      })
      .catch((err) => {
        setState({ ...state, error: err.response.data.error.message });
        return err.response.data.error.message;
      });
    return res;
  };
  const updateDiscountNumber = async (body, productId) => {
    setState({ ...state, loading: true });
    const res = await axios
      .put("products/admin/" + productId, body)
      .then((result) => {
        setState({ ...state, loading: false });
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
    return res;
  };
  const deleteProductImage = (product_id, imageName) => {
    var formData = new FormData();

    formData.append("imageName", imageName);
    formData.append("dayan_id", product_id);
    const res = axios
      .delete(`products/${product_id}/${imageName}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        const prevState = state.Products;
        prevState.forEach((el) => {
          if (el.Id === product_id) {
            const index = el.DetailImages.indexOf(imageName);
            if (index > -1) {
              el.DetailImages.splice(index, 1);
            }
          }
        });
        setState({ ...state, prevState });
        return result;
      })
      .catch((err) => {
        console.log(err);
      });
    return res;
  };
  const clearProductData = () => {
    setState({
      ...state,
      loading: false,
      error: null,
      Products: [],
      isMore: true,
    });
  };
  const getAllPreProductProduct = async (date) => {
     console.log(date);
    setState({ ...state, loading: true });
    const res = axios
      .get("preProducts/" + date)
      .then((result) => {
        setState({
          ...state,
          Products: result.data.products,
          loading: false,
        });
        console.log(result);
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        return err.response.data.error.message;
      });
    return res;
  };
  const getSpecials = async (type, limit, catId) => {
    setState({
      loading: true,
      error: null,
      Products: [],
    });
    const res = await axios
      .get("products/" + type + `?limit=${limit}&catId=${catId}`)
      .then((result) => {
        if (result.data.products.length === 0) {
          setState({
            ...state,
            isMore: false,
          });
        } else {
          if (type !== "Хямдарсан" && type !== "admin") {
            setState((prevState) => {
              const existingIds = new Set(prevState.Products.map((product) => product.Id));
              const newArray = result.data.products.filter((el) => !existingIds.has(el.Id) && el.Qty > 0);
              return {
                ...prevState,
                Products: [...prevState.Products, ...newArray],
                loading: false,
              };
            });
          } else {
            setState({
              ...state,
              Products: result.data.products,
              loading: false,
            });
          }
        }
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        return err.response.data.error.message;
      });
    console.log(res);
    return res;
  };
  const getAllBalance = async () => {
    console.log("asdffss");
    setState({ ...state, loading: true });
    axios
      .get("preProducts/admin/checkit")
      .then((result) => {
        setState({
          ...state,
          Products: result.data.products,
          loading: false,
        });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };
  const getAllPrice = async () => {
    setState({ ...state, loading: true });
    axios
      .get("preProducts/admin/price")
      .then((result) => {
        setState({
          ...state,
          Products: result.data.products,
          loading: false,
        });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };
  const dateChange = async (enteredDate, selectedDate) => {
    try {
        const response = await axios.put("preProducts/admin/changeDate", {
            enteredDate: enteredDate,
            selectedDate: selectedDate
        });
        console.log(response.data);
    } catch (err) {
        // Handle errors
        console.error(err);
    }
  }
  return (
    <ProductContext.Provider
      value={{
        state,
        decrementTotalOrder,
        incrementTotalOrder,
        clearProduct,
        getAllProduct,
        addProductImage,
        deleteProductImage,
        getAllPreProductProduct,
        clearProductData,
        getAdminPreProducts,
        getAdminFilterPreProducts,
        updatePreProduct,
        updateDiscountNumber,
        getProductBalance,
        getAllBalance,
        getSpecials,
        getAllPrice,
        dateChange,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductContext;
