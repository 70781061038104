import React, { useState, useContext, useEffect } from "react";
import css from "./style.module.css";
import LayoutContext from "../../context/LayoutContext";
import GlobalContext from "../../context/GlobalContext";
import OrderPage from "../OrderPage";
import BlogContext from "../../context/BlogContext";
import { Link, useNavigate } from 'react-router-dom';

const BlogGrid = (props) => {
    const navigate = useNavigate();
    const blogCtx = useContext(BlogContext);
    useEffect(() => {
        blogCtx.getBlogs();
        window.scrollTo(0, 0)
    }, []);

    const getFirst10Words = (content) => {
        const words = content.split(" ");
        return words.slice(0, 13).join(" ");
    };
    const toBlogPage = (index) => {
        navigate(`/blog/${index}`);
    }
    return (
        <div className={css.Container}>
            <div className="container-fluid">
                <div className="row align-items-center">

                    <div className="col d-flex justify-content-start">
                        <h2 className={css.AboutTitle}>Блог</h2>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col d-flex justify-content-start">
                        <h2 className={css.AboutTitle2}>Мэдээ мэдээлэл</h2>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className={css.gridContainer}>
                        {blogCtx.state.blogs.map((el, index) => (
                            <div
                                className={css.blogMeta}
                                key={index}
                                onClick={() => { toBlogPage(index) }}
                            >
                                <div className={css.subImage}>
                                    <img
                                        className={css.img}
                                        src={
                                            el.photo !== "no-photo.jpg"
                                                ? process.env.REACT_APP_BASE_URL +
                                                "/public/image/blog/" +
                                                el.photo
                                                : noImageIcon
                                        }
                                        alt="orderImage"
                                    />
                                </div>
                                <div className={css.meta}>
                                    <a style={{ cursor: "pointer" }}>
                                        {/* <a href="/blog"> */}
                                        <h5>{el.title} </h5>
                                    </a>
                                    <p className={css.contents}>{getFirst10Words(el.content)}...</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogGrid;
