import React, { useState, useContext, useEffect, useRef } from "react";
import css from "./style.module.css";
import { useNavigate } from "react-router-dom";

import Spinner from "../../components/Users/General/Spinner";
import OrderSidebar from "../../components/Users/OrderSideBar";
import Product from "../../components/Users/Product";
import Basket from "../../components/Users/Basket";
import SubCategory from "../../components/Users/SubCategory";
import SubMenu from "../../components/Users/SubMenu";

import ProductContext from "../../context/ProductContext";
import LayoutContext from "../../context/LayoutContext";
import GlobalContext from "../../context/GlobalContext";
import BucketContext from "../../context/BucketContext";

//alert Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import UserContext from "../../context/UserContext";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const OrderPage = (props) => {
  const initialState = {
    sortValues: [],
  };
  const initialLimitState = {
    limit: 1,
  };
  //states
  const [limit, setLimit] = useState(initialLimitState);
  const [activeCatId, setActiveCatId] = useState("");
  const [isLoad, setIsload] = useState(false);
  const [showBasket, setShowBasket] = useState(false);
  // const [bucketAlert, setBucketAlert] = useState(false);
  const [preOrderOverAlert, setPreOrderOverAlert] = useState(false);
  const [preOrderStartAlert, setPreOrderStartAlert] = useState(false);
  const [sortProductState, setSortProductState] = useState(initialState);
  //refs
  const scrollTop = useRef();
  const scrollToLimit = useRef();
  //contexts
  const layoutCtx = useContext(LayoutContext);
  const productCtx = useContext(ProductContext);
  const globalCtx = useContext(GlobalContext);
  const bucketCtx = useContext(BucketContext);
  const userCtx = useContext(UserContext);
  //navigate
  const navigate = useNavigate();

  const closeBasket = () => {
    setShowBasket(false);
  };

  const top = () => {
    scrollTop.current.scrollTo(0, 0);
  };
  // const showBucketAlert = () => {
  //   setBucketAlert(true);
  // };
  // const closeBucketAlert = () => {
  //   setBucketAlert(false);
  // };
  const showPreOrderOverAlert = () => {
    setPreOrderOverAlert(true);
  };
  const closePreOrderOverAlert = () => {
    setPreOrderOverAlert(false);
  };
  const showPreOrderStartAlert = () => {
    setPreOrderStartAlert(true);
  };
  const closePreOrderStartAlert = () => {
    setPreOrderStartAlert(false);
  };

  //sort product
  // const sortProducts = (sortItemArray) => {
  //   console.log("sortItemArray")
  //   console.log(sortItemArray)
  //   let newArray = [...sortProductState.sortValues]; // Make a copy of the original array

  //   if (sortItemArray.length > 1) {
  //     sortItemArray.forEach((el) => {
  //       if (el.selectAll) {
  //         let indexOfSort = newArray.findIndex((i) => i.type === el.type);
  //         if (indexOfSort !== -1) {
  //           newArray.splice(indexOfSort, 1);
  //         }
  //       } else {
  //         let indexOfSort = newArray.findIndex(
  //           (i) => i.type === el.type && i.val1 === el.val1
  //         );
  //         if (indexOfSort === -1) {
  //           newArray.push(el);
  //         }
  //       }
  //     });
  //   } else {
  //     sortItemArray.forEach((el) => {
  //       let indexOfSort = newArray.findIndex(
  //         (i) => i.type === el.type && i.val1 === el.val1
  //       );
  //       if (indexOfSort === -1) {
  //         newArray.push(el);
  //       } else {
  //         newArray.splice(indexOfSort, 1);
  //       }
  //     });
  //   }

  //   // Update the component's state with the new array
  //   setSortProductState({ ...sortProductState, sortValues: newArray });
  // };

  const sortProducts = (sortItemArray) => {
    let newArray = [];

    if (sortItemArray[0].value == "Бүгд") {
      // "Show All" option is selected
      newArray = []; // Empty array to show all products
    } else {
      sortItemArray.forEach((el) => {
        let indexOfSort = newArray.findIndex(
          (i) => i.type === el.type && i.val1 === el.val1
        );
        if (indexOfSort === -1) {
          newArray.push(el);
        } else {
          newArray.splice(indexOfSort, 1);
        }
      });
    }

    // Update the component's state with the new array
    setSortProductState({ ...sortProductState, sortValues: newArray });
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.clientHeight + 1;
    if (bottom) {
      setLimit((prevState) => ({
        limit: prevState.limit + 1,
      }));
    }
  };
  const reSetLimit = () => {
    setLimit(initialLimitState);
  };

  // const selectedCategory = async (cat, catId) => {
  // productCtx.clearProductData();
  // setActiveCatId(catId);
  // if (cat === "Урьдчилсан") {
  //   if (globalCtx.state.preOrderSorted !== "") {
  //     productCtx.getAllPreProductProduct(globalCtx.state.preOrderSorted);
  //   } else {
  //     productCtx.getAllPreProductProduct("all");
  //   }
  // }
  //   if (cat !== "Урьдчилсан") {
  //     productCtx.getAllProduct(cat, 1, catId);
  //   }
  // };

  // useEffect(() => {
  //   console.log("12121212")
  //   productCtx.getAllProduct(
  //     globalCtx.state.activeCategory,
  //     1,
  //     props.activeCatId
  //   );
  // }, [])

  useEffect(() => {
    if (!isLoad) {
      setIsload(true);
    }
    if (
      isLoad &&
      globalCtx.state.activeCategory !== "Урьдчилсан" &&
      productCtx.state.isMore &&
      props.activeCatId
    ) {
      productCtx.getAllProduct(
        globalCtx.state.activeCategory,
        limit.limit,
        props.activeCatId ? props.activeCatId : activeCatId
      );
      setIsload(false);
    }
  }, [limit.limit]);

  useEffect(() => {
    // if (globalCtx.state.activeCategory === "Урьдчилсан") {
    //   if (globalCtx.state.preOrderSorted !== "") {
    //     productCtx.getAllPreProductProduct(globalCtx.state.preOrderSorted);
    //   } else {
    //     productCtx.getAllPreProductProduct("all");
    //   }
    // }
    if (globalCtx.state.activeCategory !== "Урьдчилсан") {
      productCtx.getAllProduct(
        globalCtx.state.activeCategory,
        1,
        props.activeCatId
      );
    }
    if (globalCtx.state.activeCategory === "") {
      navigate(`/category`);
    }
    layoutCtx.initLayout(false, true, false);
    return () => {
      layoutCtx.clearLayout();
      setSortProductState(initialState);
      setLimit(initialLimitState);
      productCtx.clearProductData();
    };
  }, []);
  return (
    // onScroll={handleScroll}
    <div className={css.Order} ref={scrollTop}>
      {/* өмнөх Loader */}
      {/* {(productCtx.state.loading || bucketCtx.state.loading) && <Spinner />} */}
      {/* өмнөх Loader */}
      {userCtx.state.userId ? (<SubMenu />) : ("")}
      {<SubCategory />}
      <OrderSidebar
        sortProducts={sortProducts}
        reSetLimit={reSetLimit}
      // selectedCategory={selectedCategory}
      />

      {globalCtx.state.activeCategory !== "Урьдчилсан" &&
        globalCtx.state.activeCategory !== "Хямдарсан" &&
        productCtx.state.Products.filter((el) =>
          el.CategoryName.includes(globalCtx.state.activeCategory)
        ).length === 0 &&
        productCtx.state.loading === false && (
          <p
            style={{
              textAlign: "center",
              width: "100%",
              // marginLeft: "45%",
              marginTop: "5%",
              paddingTop: "200px",
              fontSize: "20px",
              color: "#FF4F58",
              fontWeight: "600",
            }}
          >
            Уучлаарай тухайн категорийн бүтээгдэхүүн дууссан байна.
          </p>
        )}
      {/* Skeleton Loaders */}
      {globalCtx.state.activeCategory !== "Урьдчилсан" &&
        globalCtx.state.activeCategory !== "Хямдарсан" &&
        productCtx.state.Products.filter((el) =>
          el.CategoryName.includes(globalCtx.state.activeCategory)
        ).length === 0 && productCtx.state.loading === true && (
          <div
            className={
              globalCtx.state.threeView
                ? css.SortedOrdersThreeView
                : css.SortedOrdersFourView
            }
          >
            {Array.from({ length: 8 }).map((_, index) => (
              <Box key={index} sx={{ width: 294, height: 396 }}>
                <Skeleton animation="wave" variant="rounded" width={290} height={195} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={55} />
                </Box>
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={45} />
                </Box>
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={50} />
                </Box>
              </Box>
            ))}
          </div>
        )}
      {/* Skeleton Loaders */}
      {globalCtx.state.activeCategory !== "Урьдчилсан" &&
        globalCtx.state.activeCategory !== "Хямдарсан" &&
        productCtx.state.Products.filter((el) =>
          el.CategoryName.includes(globalCtx.state.activeCategory)
        ).length >= 0 && (
          <div
            className={
              globalCtx.state.threeView
                ? css.SortedOrdersThreeView
                : css.SortedOrdersFourView
            }
            ref={scrollToLimit}
          >
            {productCtx.state.Products.filter((el) =>
              el.CategoryName.includes(globalCtx.state.activeCategory)
            )
              .filter((elem) => {
                if (sortProductState.sortValues.length > 0) {
                  let returnValue = true; // Set initial value to 'true'
                  sortProductState.sortValues.forEach((el) => {
                    if (el.type === "sort_product_price") {
                      if (!(el.val1 < elem.ListPrice && elem.ListPrice <= el.val2)) {
                        returnValue = false; // Хэрэв үнэ таарахгүй бол 'returnValue'-г 'false' болгох.
                      }
                    }
                    if (el.type === "sort_product_color") {
                      if (el.value !== elem.Color) { // Хэрэв өнгө таарахгүй бол 'returnValue'-г 'false' болгох.
                        returnValue = false;
                      }
                    }
                    if (el.type === "sort_product_type") {
                      if (el.value !== elem.GroupName) { // Хэрэв төрөл таарахгүй бол 'returnValue'-г 'false' болгох.
                        returnValue = false;
                      }
                    }
                  });
                  return returnValue;
                } else {
                  return true;
                }
              })
              .filter((el) =>
                el.Name.toLowerCase().includes(globalCtx.state.searchValue.toLowerCase()) ||
                el.Code.toLowerCase().includes(globalCtx.state.searchValue.toLowerCase())
              )
              .map((el, index) => (
                <Product
                  // checkBucket={showBucketAlert}
                  showPreOrderOverAlert={showPreOrderOverAlert}
                  showPreOrderStartAlert={showPreOrderStartAlert}
                  Product={el}
                  key={index}
                />
              ))}
          </div>
        )}
      {globalCtx.state.activeCategory === "Урьдчилсан" &&
        productCtx.state.Products.filter((el) =>
          el.CategoryName.includes(globalCtx.state.activeCategory)
        ).length === 0 &&
        productCtx.state.loading === false && (
          <p
            style={{
              textAlign: "center",
              width: "100%",
              paddingTop: "200px",
              // marginLeft: "45%",
              marginTop: "5%",
              fontSize: "20px",
              color: "#FF4F58",
              fontWeight: "600",
            }}
          >
            Уучлаарай тухайн категорийн бүтээгдэхүүн дууссан байна.
          </p>
        )}
      {/* Skeleton Loaders */}
      {globalCtx.state.activeCategory === "Урьдчилсан" &&
        productCtx.state.Products.filter((el) =>
          el.CategoryName.includes(globalCtx.state.activeCategory)
        ).length === 0 &&
        productCtx.state.loading === true && (
          <div
            className={
              globalCtx.state.threeView
                ? css.SortedOrdersThreeView
                : css.SortedOrdersFourView
            }
          >
            {Array.from({ length: 8 }).map((_, index) => (
              <Box key={index} sx={{ width: 294, height: 396 }}>
                <Skeleton animation="wave" variant="rounded" width={290} height={195} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={55} />
                </Box>
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={45} />
                </Box>
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={50} />
                </Box>
              </Box>
            ))}
          </div>
        )}
      {/* Skeleton Loaders */}

      {globalCtx.state.activeCategory === "Урьдчилсан" &&
        productCtx.state.Products.filter((el) =>
          el.CategoryName.includes(globalCtx.state.activeCategory)
        ).length >= 0 && (
          <div
            className={
              globalCtx.state.threeView
                ? css.SortedOrdersThreeView
                : css.SortedOrdersFourView
            }
          >
            {productCtx.state.Products.filter((el) =>
              el.CategoryName.includes(globalCtx.state.activeCategory)
            )
              .filter((elem) => {
                if (sortProductState.sortValues.length > 0) {
                  let returnValue = true; // Set initial value to 'true'
                  sortProductState.sortValues.forEach((el) => {
                    if (el.type === "sort_product_price") {
                      if (!(el.val1 < elem.ListPrice && elem.ListPrice <= el.val2)) {
                        returnValue = false; // Хэрэв үнэ таарахгүй бол 'returnValue'-г 'false' болгох.
                      }
                    }
                    if (el.type === "sort_product_color") {
                      if (el.value !== elem.Color) { // Хэрэв өнгө таарахгүй бол 'returnValue'-г 'false' болгох.
                        returnValue = false;
                      }
                    }
                    if (el.type === "sort_product_type") {
                      if (el.value !== elem.GroupName) { // Хэрэв төрөл таарахгүй бол 'returnValue'-г 'false' болгох.
                        returnValue = false;
                      }
                    }
                  });
                  return returnValue;
                } else {
                  return true;
                }
              })
              .filter((el) =>
                el.Name.toLowerCase().includes(globalCtx.state.searchValue.toLowerCase()) ||
                el.Code.toLowerCase().includes(globalCtx.state.searchValue.toLowerCase())
              )
              .map((el, index) => (
                <Product
                  // checkBucket={showBucketAlert}
                  // showPreOrderOverAlert={showPreOrderOverAlert}
                  // showPreOrderStartAlert={showPreOrderStartAlert}
                  Product={el}
                  key={index}
                />
              ))}
          </div>
        )}
      {globalCtx.state.activeCategory === "Хямдарсан" &&
        productCtx.state.Products.length === 0 &&
        productCtx.state.loading === false && (
          <p
            style={{
              textAlign: "center",
              width: "100%",
              paddingTop: "200px",
              // marginLeft: "45%",
              marginTop: "5%",
              fontSize: "20px",
              color: "#FF4F58",
              fontWeight: "600",
            }}
          >
            Уучлаарай тухайн категорийн бүтээгдэхүүн дууссан байна.
          </p>
        )}
      {/* Skeleton Loaders */}
      {globalCtx.state.activeCategory === "Хямдарсан" &&
        productCtx.state.Products.length === 0 &&
        productCtx.state.loading === true && (
          <div
            className={
              globalCtx.state.threeView
                ? css.SortedOrdersThreeView
                : css.SortedOrdersFourView
            }
          >
            {Array.from({ length: 8 }).map((_, index) => (
              <Box key={index} sx={{ width: 294, height: 396 }}>
                <Skeleton animation="wave" variant="rounded" width={290} height={195} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={55} />
                </Box>
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={45} />
                </Box>
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={50} />
                </Box>
              </Box>
            ))}
          </div>
        )}
      {/* Skeleton Loaders */}

      {globalCtx.state.activeCategory === "Хямдарсан" &&
        productCtx.state.Products.length >= 0 &&
        productCtx.state.loading === false && (
          <div
            className={
              globalCtx.state.threeView
                ? css.SortedOrdersThreeView
                : css.SortedOrdersFourView
            }
          >
            {productCtx.state.Products.filter((elem) => {
              if (sortProductState.sortValues.length > 0) {
                let returnValue = true; // Set initial value to 'true'
                sortProductState.sortValues.forEach((el) => {
                  if (el.type === "sort_product_price") {
                    if (!(el.val1 < elem.ListPrice && elem.ListPrice <= el.val2)) {
                      returnValue = false; // Хэрэв үнэ таарахгүй бол 'returnValue'-г 'false' болгох.
                    }
                  }
                  if (el.type === "sort_product_color") {
                    if (el.value !== elem.Color) { // Хэрэв өнгө таарахгүй бол 'returnValue'-г 'false' болгох.
                      returnValue = false;
                    }
                  }
                  if (el.type === "sort_product_type") {
                    if (el.value !== elem.GroupName) { // Хэрэв төрөл таарахгүй бол 'returnValue'-г 'false' болгох.
                      returnValue = false;
                    }
                  }
                });
                return returnValue;
              } else {
                return true;
              }
            })
              .filter((el) =>
                el.Name.toLowerCase().includes(globalCtx.state.searchValue.toLowerCase()) ||
                el.Code.toLowerCase().includes(globalCtx.state.searchValue.toLowerCase())
              )
              .map((el, index) => (
                <Product
                  // checkBucket={showBucketAlert}
                  showPreOrderOverAlert={showPreOrderOverAlert}
                  showPreOrderStartAlert={showPreOrderStartAlert}
                  Product={el}
                  key={index}
                />
              ))}
          </div>
        )}
      {showBasket && <Basket closeBasket={closeBasket} />}
      <button onClick={top} className={css.topBtn}></button>

      {/* <Dialog open={bucketAlert} onClose={closeBucketAlert}>
        <DialogTitle>Анхааруулга</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Та сагсан дахь бүтээгдхүүнээ худалдан авсаны дараа энэ үйлдэлийг
            хийх боломжтой.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeBucketAlert}>Хаах</Button>
        </DialogActions>
      </Dialog> */}
      {/* <Dialog open={preOrderOverAlert} onClose={closePreOrderOverAlert}>
        <DialogTitle>Анхааруулга</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Захиалга авч дууссан.Бараа ирэх өдрөөс 3 хоногын өмнө хаагдана.
            Захиалга авч дууссан.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePreOrderOverAlert}>Хаах</Button>
        </DialogActions>
      </Dialog> */}
      {/* <Dialog open={preOrderStartAlert} onClose={closePreOrderStartAlert}>
        <DialogTitle>Анхааруулга</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Захиалга авч хараахан эхлээгүй байна. Бараа ирэх өдрөөс 14 хоногын
            өмнө нээгдэнэ.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePreOrderStartAlert}>Хаах</Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default OrderPage;
