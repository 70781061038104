import React, { useState, useEffect, useContext } from "react";
import css from "./style.module.css";
import phoneImag from "../../assets/images/auth/phone.png";
import addressImag from "../../assets/images/auth/address.png";
import ContactContext from "../../context/ContactContext";

const ContactPage = () => {
    const [formData, setFormData] = useState({
        title1: '',
        title2: '',
        title3: '',
        text1: '',
        text2: '',
        text3: '',
        googleMap: ''
    });

    useEffect(() => {
        contactCtx.ContactUs();
    }, []);

    const contactCtx = useContext(ContactContext);

    useEffect(() => {
        if (contactCtx.state.categories[0] !== undefined) {
            const categoryData = contactCtx.state.categories[0];
            setFormData({
                title1: categoryData.title1,
                title2: categoryData.title2,
                title3: categoryData.title3,
                text1: categoryData.text1,
                text2: categoryData.text2,
                text3: categoryData.text3,
                googleMap: categoryData.googleMap
            });
        }
    }, [contactCtx.state.categories]);

    const iframeString = formData.googleMap;
    const regex = /<iframe src="([^"]+)"/;
    const match = iframeString.match(regex);

    let srcAttribute = "";
    if (match && match[1]) {
        srcAttribute = match[1];
    }
    return (
        <div className={css.ContactPage}>
            {/* <div className={css.ContactTitle}>
                <p>Холбоо барих</p>
            </div> */}
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col d-flex justify-content-start">
                        <h2 className={css.AboutTitle}>Холбоо барих</h2>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col d-flex justify-content-start">
                        <h2 className={css.AboutTitle2}>Бидэнтэй холбогдох</h2>
                    </div>
                </div>
                <div className={css.Container}>
                    <div className={css.ContactWidget}>
                        <div className={css.iconContact}>
                            {/* <div className={css.icon}>
                            <img className={css.phoneImage} src={phoneImag} alt="Icon" />
                        </div> */}
                            <div className={css.text}>
                                {/* <h4>{formData.title1}</h4> */}
                                {/* <p>{formData.text1}</p> */}
                                <div className="row">
                                    <div className="col-4">
                                        <h4>{formData.title1 + " : "}</h4>
                                    </div>
                                    <div className="col-8 d-flex justify-content-start">
                                        <p>{formData.text1}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={css.iconContact}>
                            {/* <div className={css.icon}>
                            <img className={css.addressImage} src={addressImag} alt="Icon" />
                        </div> */}
                            <div className={css.text}>
                                <div className="row">
                                    <div className="col-4">
                                        <h4>{formData.title3 + " : "}</h4>
                                    </div>
                                    <div className="col-8 d-flex justify-content-start">
                                        <p>{formData.text3}</p>
                                    </div>
                                </div>
                                {/* <h4>{formData.title2}</h4>
                            <p>{formData.text2}</p> */}
                            </div>
                        </div>
                        <div className={css.iconContact}>
                            {/* <div className={css.icon}>
                            <img className={css.addressImage} src={addressImag} alt="Icon" />
                        </div> */}
                            <div className={css.text}>
                                <div className="row">
                                    <div className="col-2">
                                        <h4>{formData.title2 + " : "}</h4>
                                    </div>
                                    <div className="col-9 d-flex justify-content-center">
                                        <p>{formData.text2}</p>
                                    </div>
                                </div>
                                {/* <h4>{formData.title2}</h4>
                            <p>{formData.text2}</p> */}
                            </div>
                        </div>

                    </div>
                    {match ? 
                    <iframe
                        className={css.map} width="50%" height="400"
                        src={srcAttribute}
                        style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    :
                    <div><p>
                        404. Алдаа гарлаа.
                        </p></div>
                }
                    {/* <iframe className={css.map} width="50%" height="400" frameborder="0" marginheight="0" marginwidth="0" id="gmap_canvas" 
                    src="https://maps.google.com/maps?width=700&amp;height=400&amp;hl=en&amp;q=WV2P+WCC,%20%D0%A3%D0%BB%D0%B0%D0%B0%D0%BD%D0%B1%D0%B0%D0%B0%D1%82%D0%B0%D1%80%20Ulan%20Bator+(peace%20town2)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
