import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const BucketContext = React.createContext();
const initialState = {
  myBuckets: [],
  checkOrder: [],
  bucketTotalPrice: 0,
  loading: false,
};
export const MyBucketStore = (props) => {
  const [state, setState] = useState(initialState);

  const addBucket = (
    id,
    piece,
    QtyPerpackage,
    listPrice,
    code,
    image,
    name,
    categoryName,
    brandName,
    Qty,
    Percentage,
    WholeQty,
    pre_order,
    type,
    scheduledDate
  ) => {
    console.log("asdf");
    setState({ ...state, loading: true });
    axios
      .post("buckets", {
        product_id: id,
        piece: piece,
        QtyPerpackage: QtyPerpackage,
        listPrice: listPrice,
        code: code,
        image: image,
        name: name,
        categoryName: categoryName,
        brandName: brandName,
        Qty: Qty,
        Percentage: Percentage,
        WholeQty: WholeQty,
        pre_order: pre_order,
        type: type,
        scheduledDate: scheduledDate,
        totalPrice: piece * QtyPerpackage * listPrice,
      })
      .then((result) => {
        setState({ ...state, loading: false });
        getAllBucket();
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };

  const deleteBucket = (bucket_id) => {
    axios
      .delete("buckets/" + bucket_id)
      .then((result) => {
        getAllBucket();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBucket = () => {
    axios
      .get("buckets")
      .then((result) => {
        var totalPrice = 0;
        result.data.data.map((el) => {
          totalPrice = totalPrice + el.totalPrice;
        });
        setState({
          ...state,
          myBuckets: result.data.data,
          bucketTotalPrice: totalPrice,
          checkOrder: result.data.check
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteAllBucket = () => {
    axios
      .delete("buckets")
      .then((result) => {
        getAllBucket();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteAllBucketClick = () => {
    axios
      .delete("buckets")
      .then((result) => {
        getAllBucket();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateAllBucket = () => {
    axios
      .post("/buckets/updateAllBucket")
      .then((result) => {
        getAllBucket();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const updateOneBucket = (order, increment) => {
    const newQuantity = increment ? order.piece + 1 : order.piece - 1;
    axios
      .put(`buckets/updateBucket/${order.product_id}`, { bucketData: order, quantity: newQuantity })
      .then((result) => {
        getAllBucket();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearBucketData = () => {
    setState(initialState);
  };

  return (
    <BucketContext.Provider
      value={{
        state,
        addBucket,
        deleteBucket,
        deleteAllBucket,
        deleteAllBucketClick,
        getAllBucket,
        clearBucketData,
        updateAllBucket,
        updateOneBucket,
      }}
    >
      {props.children}
    </BucketContext.Provider>
  );
};
export default BucketContext;
