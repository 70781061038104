import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import React, { useState, useContext, useEffect } from "react";
import CaleContext from "../../context/CalendarContext";
import css from "./style.module.css";

export function CalendarPage() {
  const calendarCtx = useContext(CaleContext);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(calendarCtx.state.days);
  }, [calendarCtx.state.days]);

  const eventContent = (eventInfo) => {
    return (
      <div className={css.itemContain}>
        {/* {eventInfo.timeText}
        <br />
        <div> */}
          <p> {eventInfo.event.title}</p>
        {/* </div> */}
      </div>
    );
  };

  return (
    <div className={css.calendarContainer}>
      <FullCalendar
        events={events}
        displayEventTime={false}
        eventContent={eventContent}
        plugins={[dayGridPlugin, interactionPlugin]}
        views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
        // views={["dayGridMonth"]}
        handleCustomRendering={true}
        handleWindowResize={true}
        // dayMaxEventRows={6}
        height={700}
      // contentHeight={700}
      />
    </div>
  );
}
