import React, { useState, useContext, useEffect } from "react";
import css from "./style.module.css";
import Shadow from "../General/Shadow";
import BasketItem from "../BasketItem";
import timerBasket from "../../../assets/images/basket/timer.png";
import BucketContext from "../../../context/BucketContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Basket = (props) => {
  //states
  const [showShadow, setShowShadow] = useState(true);
  const myBucketCtx = useContext(BucketContext);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  let deadline = null;

  //navigate
  const navigate = useNavigate();

  //functions
  const getTime = () => {
    const serverTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Ulaanbaatar' });
    const serverTimeDate = new Date(serverTime);
    if (deadline >= serverTimeDate.getTime()) {
      const time = deadline - Date.now();
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    } else {
      //hadgalah heseg end hiih
      myBucketCtx.updateAllBucket();
      myBucketCtx.deleteAllBucketClick();
      myBucketCtx.clearBucketData();
      navigate("/");
    }
  };

  const showShadowEvent = () => {
    setShowShadow(false);
    props.closeBasket();
  };

  const toPayment = () => {
    setShowShadow(false);
    props.closeBasket();
    navigate("/payment");
  };
  const deleteAllBucketClick = () => {
    myBucketCtx.deleteAllBucketClick();
    myBucketCtx.clearBucketData();
    navigate("/");
  };

  const receiveDataFromChild = (data) => {
    const itemIdToDelete = data;
    myBucketCtx.state.myBuckets = myBucketCtx.state.myBuckets.filter(
      (item) => item.id !== itemIdToDelete
    );
    if (myBucketCtx.state.myBuckets.length == 0) {
      navigate("/")
    }
  };

  //useEffect
  useEffect(() => {
    if (myBucketCtx.state.myBuckets.length > 0) {
      const isPreOrder = myBucketCtx.state.myBuckets[0].pre_order;

      if (!deadline && !isPreOrder) {
        deadline =
          Date.parse(
            moment(myBucketCtx.state.myBuckets[0].createdAt).format("LLL")
          ) +
          1000 * 60 * 30;
      }
      else if (!deadline && isPreOrder) {
        deadline =
          Date.parse(
            moment(myBucketCtx.state.myBuckets[0].createdAt).format("LLL")
          ) +
          1000 * 60 * 40; // Set the deadline to 40 minutes (40 * 60 seconds) from the createdAt time for pre-orders
      }
      const interval = setInterval(() => getTime(deadline), 1000);
      // if (!isPreOrder) {
      //   const interval = setInterval(() => getTime(deadline), 1000);
      //   return () => clearInterval(interval);
      // }

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <div>
      <Shadow opacity={true} show={showShadow} darahad={showShadowEvent} />
      <div className={css.Basket}>
        <div className={css.BasketTitle}>
          <p>
            Таны сагсанд <span>{myBucketCtx.state.myBuckets.length}</span>{" "}
            бүтээгдэхүүн байна.
          </p>
          <button onClick={deleteAllBucketClick}>Сагс хоослох</button>
          <button onClick={props.closeBasket}></button>
        </div>
        <div className={myBucketCtx.state.myBuckets.length === 0 ? css.emptyBasket : css.MyBasket}>
          {myBucketCtx.state.myBuckets.map((el, index) => (
            // console.log(el),
            <BasketItem key={index} order={el} sendDataToParent={receiveDataFromChild} />
          ))}
        </div>
        {myBucketCtx.state.myBuckets.length === 0 && (
          <div className={css.emptyBasket}>Таны сагс хоосон байна</div>
        )}
        {myBucketCtx.state.myBuckets.length > 0 && (
          <div className={css.BasketFooter}>
            <div style={{ display: "flex" }}>
              <img
                style={{ height: "24px", margin: "6px 10px 0 0" }}
                src={timerBasket}
                alt="close"
              />
              <p
                style={{
                  fontSize: "13px",
                  marginTop: "7px",
                  color: "#FF4F58",
                  fontWeight: "600",
                }}
              >
                {minutes}:{seconds}
              </p>
            </div>
            <button
              onClick={() => {
                toPayment();
              }}
            >
              Худалдан авах
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Basket;
