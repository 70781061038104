import React, { useState, useContext, useEffect, useRef } from "react";
import { Swiper, SwiperSlide, Breakpoints } from "swiper/react";

import css from "./style.module.css";
import HomePageSlide from "../../components/Users/General/Slide";

// import dileveryFreeImage from "../../../backend/public/image/helpbanner/HurgeltUnegui.png";
// import orderImage from "../../../../backend/public/image/helpbanner/Zahialga.png";
// import moneyBackImage from "../../../../backend/public/image/helpbanner/MunguButsaalt.png";
// import rewardImage from "../../../../backend/public/image/helpbanner/Uramshuulal.png";

import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import GlobalContext from "../../context/GlobalContext";
import NewProduct from "../../components/Users/NewProduct";
//contexts
import NewProductContext from "../../context/NewProductContext";
import SliderContext from "../../context/SlideContext";
import MainCategoryContext from "../../context/MainCategoryContext";
import SpecialCategory from "../../context/SpecialCategory";
import ProductContext from "../../context/ProductContext";
import SpecialProductContext from "../../context/SpecialProductContext";

import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/grid";
import swiperCss from "./swiper.css";
import { Grid, Navigation } from "swiper";

const HomePage = (props) => {
  const swiperRef = useRef(null);
  const [expand, setExpand] = useState();
  const [my_swiper, set_my_swiper] = useState({});

  const ctx = useContext(NewProductContext);
  const globalCtx = useContext(GlobalContext);
  const userCtx = useContext(UserContext);
  const mainCategories = useContext(MainCategoryContext);
  const swiperCtx = useContext(SliderContext);
  const categoryCTX = useContext(SpecialCategory);
  const productCtx = useContext(ProductContext);
  const specialCtx = useContext(SpecialProductContext);
  const navigate = useNavigate();

  useEffect(() => {
    swiperCtx.getBanners();
    // categoryCTX.getSpecialCategory()
    // mainCategories.mainCategoryActive();
    specialCtx.getSpecials(
      "Онцгой бараа",
      500000,
      "c1fd26b4-4a35-47d9-9032-278501d3ef72"
    );
  }, []);

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;

    const loopSlides = () => {
      if (swiperInstance) {
        if (swiperInstance.activeIndex === swiperInstance.slides.length - 1) {
          swiperInstance.slideTo(0, 0, false);
        } else {
          swiperInstance.slideNext();
        }
      }
    };

    const interval = setInterval(loopSlides, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleLeftClick = () => {
    my_swiper.slidePrev();
  };

  const handleRightClick = () => {
    my_swiper.slideNext();
  };

  const redirectToCategory = (categoryName, activeID) => {
    globalCtx.setActiveCategory(categoryName, activeID);
    navigate(`/category`);
  };

  const toRegister = () => {
    window.open(
      "https://b24-tuh947.bitrix24.site/",
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
    );
  };
  const expandAll = () => {
    setExpand(true);
  };
  const unExpandAll = () => {
    setExpand(false);
  };

  return (
    <div className={css.HomePage}>
      <div className={css.newSwiper}>
        <Swiper loop={true} ref={swiperRef} navigation={true} modules={[Navigation]} className="mySwiper1">
          <div className={css.insideSwiper}>
            {swiperCtx.state.sliders
              .filter((el) => el.isActive === true)
              .map((el, index) => (
                <SwiperSlide key={index} className={swiperCss.top}>
                  <HomePageSlide sliders={el} />
                </SwiperSlide>
              ))}
          </div>
        </Swiper>
      </div>

      <div className={css.categorySwiper}>
        <div className={css.angilal}>
          <div className={css.angilaltitle}>Ангилал</div>
          <p>Бүтээгдэхүүний категори </p>
        </div>
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper1"
          spaceBetween={24}
          breakpoints={{
            375: {
              slidesPerView: 2,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 24,
            },

            1024: {
              slidesPerView: 5,
              spaceBetween: 24,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 24,
            },
            1440: {
              slidesPerView: 7,
              spaceBetween: 24,
            },
          }}
        >
          {mainCategories.state.categories.map((el) => (
            <SwiperSlide
              onClick={() => {
                redirectToCategory(
                  el.name,
                  el.dayan_id
                );
              }}
              className={css.dummySwipe}
            >
              <button style={{ background: `url(` + process.env.REACT_APP_BASE_URL + `/public/image/maincategory/` + el.photo + `) center center no-repeat`, backgroundSize: 'cover' }}></button>

              <div>{el.name}</div>
            </SwiperSlide>
          ))}
          {/* <SwiperSlide
            onClick={() => {
              redirectToCategory("Урьдчилсан", null);
            }}
            className={css.dummySwipe}>
            <button
              className={css.uridchilsan}
            ></button>
            <div>Урьдчилсан</div>
          </SwiperSlide> */}
          <SwiperSlide
            onClick={() => {
              redirectToCategory("Хямдарсан", null);
            }}
            className={css.dummySwipe}
          >
            <button
              className={css.hyamdral}
            ></button>
            <div>Хямдарсан</div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* <div className={css.Caterogies}>
        <button
          onClick={() => redirectToCategory("Гоёлын цэцэг")}
          className={css.ProductSpecial}
        ></button>
        <button
          onClick={() => redirectToCategory("Тасалгааны цэцэг")}
          className={css.ProductIndoor}
        ></button>
        <button
          onClick={() => redirectToCategory("Хангамжын материал")}
          className={css.ProductDecor}
        ></button>
        <button onClick={toRegister} className={css.ToRegister}></button>
      </div> */}
      <div className={css.addedProduct}>
        <div className={css.NewProductText}>
          <p>Шинээр ирсэн</p>
          <p>Онцгой бараа</p>
        </div>
        {specialCtx.state.Products.length > 0 &&
          expand ? (
          <div className={css.NewProductExpanded}>
            {specialCtx.state.Products.map((el) => (
              <div className={css.NewProductExpandedItem}>
                <NewProduct newProduct={el} userId={userCtx.state.userId} />
              </div>
            ))}
          </div>
        ) : specialCtx.state.Products.length > 0 && (
          <div className={css.NewProduct}>
            {specialCtx.state.Products.slice(0, 8).map((el, index) => (
              <div className={css.NewProductItem} key={index}>
                <NewProduct newProduct={el} userId={userCtx.state.userId} />
              </div>
            ))}
          </div>
        )
        }
        {specialCtx.state.Products.length > 0 && specialCtx.state.loading == true &&
          expand ? (
          <div className={css.NewProductExpanded}>
            {Array.from({ length: 6 }).map((_, index) => (
              <Box key={index} sx={{ width: 294 }}>
                <Skeleton animation="wave" variant="rounded" width={290} height={170} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={55} />
                </Box>
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={45} />
                </Box>
              </Box>
            ))}
          </div>
        ) : specialCtx.state.Products.length > 0 && specialCtx.state.loading == true && (
          <div className={css.NewProduct}>
            {Array.from({ length: 6 }).map((_, index) => (
              <Box key={index} sx={{ width: 294 }}>
                <Skeleton animation="wave" variant="rounded" width={290} height={170} />
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={55} />
                </Box>
                <Box sx={{ pt: 0.5 }}>
                  <Skeleton animation="wave" variant="rounded" width={290} height={45} />
                </Box>
              </Box>
            ))}
          </div>
        )
        }
        {specialCtx.state.Products.length == 0 && specialCtx.state.loading === false && (
          <p
            style={{
              textAlign: "center",
              width: "100%",
              paddingTop: "30px",
              paddingBottom: "30px",
              fontSize: "20px",
              color: "#FF4F58",
              fontWeight: "600",
            }}
          >
            Уучлаарай тухайн категорийн бүтээгдэхүүн дууссан байна.
          </p>
        )}
      </div>
      {
        expand ? (
          <button className={css.More} onClick={unExpandAll}>
            Хураах
          </button>
        ) : (
          <button className={css.More} onClick={expandAll}>
            Бүгдийг нь
          </button>
        )
      }
    </div >
  );
};

export default HomePage;
