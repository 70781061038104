import React, { useContext, useState, useEffect, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import grey from "@mui/material/colors/grey";
import CategoryContext from "../../../context/MainCategoryContext";
import CategoryActions from "./CategoryActions";
import { gridClasses } from "@mui/system";
import Image from "mui-image";
import Spinner from "../../../components/Users/General/Spinner";

export default function MainCategoryPage() {
  //contexts
  const categoryCtx = useContext(CategoryContext);

  //states
  const [pageSize, setPageSize] = useState(15);
  const [rowId, setRowId] = useState();
  const [photo123, setPhoto] = useState();

  const columns = useMemo(
    () => [
      { field: "_id", hide: true },
      // {
      //   field: "createdAt",
      //   headerName: "Үүсгэсэн огноо",
      //   width: 250,
      //   renderCell: (params) =>
      //     moment(params.row.createdAt).format("YYYY-MM-DD HH:MM:SS"),
      // },
      {
        field: "name",
        headerName: "Нэр",
        width: 280,
        editable: true,
      },
      {
        field: "changePhoto",
        headerName: "Зураг",
        width: 350,
        height: 500,
        renderCell: (params) => (
          <Image
            src={
              process.env.REACT_APP_BASE_URL +
              "/public/image/maincategory/" +
              params.row.photo
            }
          />
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: "photo",
        headerName: "Зураг солих",
        width: 280,
        editable: true,
        renderCell: (params) => (
          <div>
            <input
              type="file"
              onChange={(event) => handleFileChange(event, params.id)}
            />
            {/* {photo && <img src={params.row.photo} alt="Selected photo" />} */}
          </div>
        ),
      },
      {
        field: "isActive",
        headerName: "Стасус",
        width: 280,
        type: "boolean",
        editable:true
      },
      {
        field: "actions",
        width: 250,
        headerName: "Үйлдэл",
        type: "actions",
        renderCell: (params) => (
          <CategoryActions {...{ params, photo123, rowId, setRowId }} />
        ),
      },
    ],
    [rowId, photo123]
  );
  useEffect(() => {
    categoryCtx.MainCategory();
  }, []);

  function handleFileChange(event, id) {
    const file = event.target.files[0];
    setPhoto(file);
    setRowId(id);
  }

  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="content-header ml-2 d-inline-flex col">
          <h1>Үндсэн категори</h1>
        </div>
        <div className="content-header col-auto mr-2">
          <button
            onClick={() => {
              categoryCtx.checkCategory();
            }}
            type="button"
            className="btn btn-primary"
          >
            Шинэ категори шалгах
          </button>
        </div>
      </div>

      <div className="content">
        {categoryCtx.state.loading && <Spinner />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div
                  className="card-header border-0"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="d-flex justify-content-center">
                    <Box sx={{ height: 730, width: "100%" }}>
                      <Typography
                        variant="h3"
                        component="h3"
                        sx={{ textAlign: "center", mt: 3, mb: 3 }}
                      />
                      <DataGrid
                        rowHeight={90}
                        columns={columns}
                        rows={categoryCtx.state.categories}
                        getRowId={(row) => row._id}
                        rowsPerPageOptions={[5, 10, 20]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        getRowSpacing={(params) => ({
                          top: params.isFirstVisible ? 0 : 5,
                          bottom: params.isLastVisible ? 0 : 5,
                        })}
                        sx={{
                          [`& .${gridClasses.row}`]: {
                            bgcolor: grey[200],
                          },
                        }}
                        onCellEditCommit={(params) => setRowId(params.id)}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
