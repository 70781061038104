import React, { useContext, useState, useEffect, useMemo } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import grey from "@mui/material/colors/grey";
import { Sync } from "@mui/icons-material";
import { Fab } from "@mui/material";
import green from "@mui/material/colors/green";
import AdminUserContext from "../../../context/admin/AdminUserContext";
import { gridClasses } from "@mui/system";
import UserActions from "./UserActions";
import Spinner from "../../../components/Users/General/Spinner";

export default function UserPage() {
  //contexts
  const userCtx = useContext(AdminUserContext);
  //states
  const [rowId, setRowId] = useState(null);

  const syncUsers = async () => {
    const result = await userCtx.syncUsers();
    // if (result) {
    //   userCtx.getAlluser();
    // }
  };
  const columns = useMemo(
    () => [
      { field: "_id", hide: true },
      { field: "email", headerName: "И-мэйл", width: 200 },
      { field: "erp_id", headerName: "ERP id", width: 70 },
      { field: "name", headerName: "Нэр", width: 180, editable: true },
      {
        field: "companyId",
        headerName: "Bittrex ID",
        width: 100,
        editable: true,
      },
      {
        field: "bonusPoint",
        headerName: "Бонус оноо",
        width: 100,
        editable: true,
      },
      {
        field: "bonusLevel",
        headerName: "Бонус %",
        width: 100,
        editable: true,
      },
      {
        field: "role",
        headerName: "Хандах төвшин",
        width: 80,
      },
      {
        field: "isActive",
        headerName: "Статус",
        width: 80,
        type: "singleSelect",
        valueOptions: ["true", "false"],
        editable: true,
      },
      {
        field: "createdAt",
        headerName: "Бүртгүүлсэн огноо",
        width: 200,
        renderCell: (params) =>
          moment(params.row.createdAt).format("YYYY-MM-DD HH:MM:SS"),
      },
      {
        field: "actions",
        headerName: "Үйлдэл",
        width: 200,

        type: "actions",
        renderCell: (params) => (
          <UserActions {...{ params, rowId, setRowId }} />
        ),
      },
    ],
    [rowId]
  );

  useEffect(() => {
    userCtx.getAlluser();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header ml-2 d-inline-flex">
        <h1>Нийт хэрэглэгчид</h1>
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            marginLeft: 2,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={syncUsers}
        >
          <Sync />
        </Fab>
      </div>
      <div className="content">
        {userCtx.state.recentlyAdded.length > 0 && (
          <ul>
            {userCtx.state.recentlyAdded.map((el, index) => (
              <li key={index}>{el.email}</li>
            ))}
          </ul>
        )}

        {userCtx.state.loading && <Spinner />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div
                  className="card-header border-0"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="d-flex justify-content-between">
                    <Box sx={{ height: 730, width: "100%" }}>
                      <Typography
                        variant="h3"
                        component="h3"
                        sx={{ textAlign: "center", mt: 3, mb: 3 }}
                      />
                      <DataGrid
                        columns={columns}
                        rows={userCtx.state.users}
                        getRowId={(row) => row._id}
                        getRowSpacing={(params) => ({
                          top: params.isFirstVisible ? 0 : 5,
                          bottom: params.isLastVisible ? 0 : 5,
                        })}
                        sx={{
                          [`& .${gridClasses.row}`]: {
                            bgcolor: grey[200],
                          },
                        }}
                        onCellEditCommit={(params) => setRowId(params.id)}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
