import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const SpecialProductContext = React.createContext();
const initialState = {
    loading: false,
    error: null,
    Products: [],
    isMore: true,
};
export const SpecialProductStore = (props) => {
    const [state, setState] = useState(initialState);

    const clearProduct = (product_id) => {
        const prevState = state.Products;
        prevState.forEach((el) => {
            if (el.Id === product_id) {
                el.Piece = 0;
            }
        });
        setState({ ...state, prevState });
    };

    const clearProductData = () => {
        setState({
            ...state,
            loading: false,
            error: null,
            Products: [],
            isMore: true,
        });
    };

    // const getSpecials = async (type, limit, catId) => {
    //     setState({
    //         loading: true,
    //         error: null,
    //         Products: [],
    //     });
    //     const res = await axios
    //         .get("products/" + type + `?limit=${limit}&catId=${catId}`)
    //         .then((result) => {
    //             if (result.data.products.length === 0) {
    //                 setState({
    //                     ...state,
    //                     isMore: false,
    //                 });
    //             } else {
    //                 if (type !== "Хямдарсан" && type !== "admin") {
    //                     setState((prevState) => {
    //                         const existingIds = new Set(prevState.Products.map((product) => product.Id));
    //                         const newArray = result.data.products.filter((el) => !existingIds.has(el.Id) && el.Qty > 0);
    //                         return {
    //                             ...prevState,
    //                             Products: [...prevState.Products, ...newArray],
    //                             loading: false,
    //                         };
    //                     });
    //                 } else {
    //                     setState({
    //                         ...state,
    //                         Products: result.data.products,
    //                         loading: false,
    //                     });
    //                 }
    //             }
    //             return result;
    //         })
    //         .catch((err) => {
    //             setState({ ...state, loading: false });
    //             return err.response.data.error.message;
    //         });
    //     return res;
    // };

    const getSpecials = async (type, limit, catId) => {
        setState({
          loading: true,
          error: null,
          Products: [],
        });
        const res = await axios
          .get("products/" + type + `?limit=${limit}&catId=${catId}`)
          .then((result) => {
            if (result.data.products.length === 0) {
              setState({
                ...state,
                Products: [], /* remove this line if error , хямдрал category засвар*/
                isMore: false,
              });
            } else {
              if (type !== "Хямдарсан" && type !== "admin") {
                setState((prevState) => {
                  const existingIds = new Set(prevState.Products.map((product) => product.Id));
                  const newArray = result.data.products.filter((el) => !existingIds.has(el.Id));
                  // const newArray = result.data.products.filter((el) => !existingIds.has(el.Id) && el.Qty > 0); Барааг хасахруу оруулж байгааг засах
                  return {
                    ...prevState,
                    Products: [...prevState.Products, ...newArray],
                    loading: false,
                  };
                });
              } else {
                setState({
                  ...state,
                  Products: result.data.products,
                  loading: false,
                });
              }
            }
            return result;
          })
          .catch((err) => {
            setState({ ...state, loading: false });
            return err.response.data.error.message;
          });
    
        return res;
      };

    const addProductImage = (product_id, file) => {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("dayan_id", product_id);
        const res = axios
            .post("products", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => {
                const prevState = state.Products;
                prevState.forEach((el) => {
                    if (el.Id === product_id) {
                        el.DetailImages.push(result.data.data);
                    }
                });
                setState({ ...state, prevState, error: null });
                return result;
            })
            .catch((err) => {
                setState({ ...state, error: err.response.data.error.message });
                return err.response.data.error.message;
            });
        return res;
    };

    return (
        <SpecialProductContext.Provider
            value={{
                state,
                clearProduct,
                clearProductData,
                addProductImage,
                getSpecials,
            }}
        >
            {props.children}
        </SpecialProductContext.Provider>
    );
};
export default SpecialProductContext;
