import React, { useState } from "react";
import axios from "../../axios/axios-for-backend";

const AdminUserContext = React.createContext();

const initialState = {
  loading: false,
  success: false,
  users: [],
  recentlyAdded: [],
};

export const AdminUserStore = (props) => {
  const [state, setState] = useState(initialState);

  //admin requests
  const getAlluser = () => {
    setState({ ...state, loading: true });
    axios
      .get("users")
      .then((result) => {
        setState({ ...state, loading: false, users: result.data.data });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };

  const updateUser = async (body, userId) => {
    setState({ ...state, loading: true });
    const dondon = await axios
      .put("users/" + userId, body)
      .then((result) => {
        setState({ ...state, success: true, loading: false });
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
    return dondon;
  };

  const clearStatus = () => {
    setState({ ...state, loading: false, success: false });
  };

  const syncUsers = async () => {
    setState({ ...state, loading: true });
    const dondon = await axios
      .post("users/sync")
      .then((result) => {
        setState({
          ...state,
          loading: false,
          recentlyAdded: result.data.recentlyAdded,
          users: state.users.concat(result.data.recentlyAdded),
        });
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
    return dondon;
  };

  const adminChangePassword = (user_id, password) => {
    setState({ ...state, loading: true });
    axios
      .post(`users/adminChangePassword/${user_id}`, { password: password })
      .then((result) => {
        setState({ ...state, loading: false });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
      });
  };
  return (
    <AdminUserContext.Provider
      value={{
        state,
        getAlluser,
        updateUser,
        clearStatus,
        syncUsers,
        adminChangePassword,
      }}
    >
      {props.children}
    </AdminUserContext.Provider>
  );
};
export default AdminUserContext;
