import React, { useState, useContext, useEffect } from "react";
import { Swiper, SwiperSlide, Breakpoints } from "swiper/react";
import GlobalContext from "../../../context/GlobalContext";
import ProductContext from "../../../context/ProductContext";
import UserContext from "../../../context/UserContext";
import MainCategoryContext from "../../../context/MainCategoryContext";

import css from "./style.module.css";

import { useNavigate } from "react-router-dom";
//contexts
import SliderContext from "../../../context/SlideContext";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/grid";
import { Grid, Navigation } from "swiper";

const SubCategory = (props) => {
  const globalCtx = useContext(GlobalContext);
  const productCtx = useContext(ProductContext);
  const userCtx = useContext(UserContext);
  const mainCategories = useContext(MainCategoryContext);
  const initialState = {
    sortValues: [],
  };
  const initialLimitState = {
    limit: 1,
  };

  const [limit, setLimit] = useState(initialLimitState);
  const [isLoad, setIsload] = useState(false);
  const defaultCat = "c62bf54e-4693-4217-bc57-0eb22d2bcbda"
  const [activeCatId, setActiveCatId] = useState(defaultCat);
  const [expand, setExpand] = useState();
  const [my_swiper, set_my_swiper] = useState({});

  // const swiperCtx = useContext(SliderContext);
  const navigate = useNavigate();
  const redirectToCategory = async (cat, catId) => {
    productCtx.clearProductData();
    globalCtx.setActiveCategory(cat);
    setActiveCatId(catId);
    // if (cat === "Урьдчилсан") {
    //   if (globalCtx.state.preOrderSorted !== "") {
    //     productCtx.getAllPreProductProduct(globalCtx.state.preOrderSorted);
    //   } else {
    //     productCtx.getAllPreProductProduct("all");
    //   }
    // }
    if (cat !== "Урьдчилсан") {
      productCtx.getAllProduct(cat, 1, catId);
    }
  };
  // useEffect(() => {
  //     swiperCtx.getBanners();
  // }, []);
  useEffect(() => {
    mainCategories.mainCategoryActive();
    if (!isLoad) {
      setIsload(true);
    }
    if (
      isLoad &&
      globalCtx.state.activeCategory !== "Урьдчилсан" &&
      productCtx.state.isMore &&
      props.activeCatId
    ) {
      productCtx.getAllProduct(
        globalCtx.state.activeCategory,
        limit.limit,
        activeCatId
      );
      setIsload(false);
    }
  }, [limit.limit]);

  const handleLeftClick = () => {
    my_swiper.slidePrev();
  };

  const handleRightClick = () => {
    my_swiper.slideNext();
  };

  // const redirectToCategory = (categoryName) => {
  //     globalCtx.setActiveCategory(categoryName);
  //     // navigate(`/order/${categoryName}`);
  // };
  return (
    <div className={userCtx.state.userId ? css.HomePage : css.unHomePage}>
      <div className={css.categorySwiper}>
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper1"
          spaceBetween={24}
          slidesPerView={8}
          breakpoints={{
            375: {
              slidesPerView: 2,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 24,
            },

            1024: {
              slidesPerView: 5,
              spaceBetween: 24,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 24,
            },
            1440: {
              slidesPerView: 7,
              spaceBetween: 24,
            },
          }}
        >
          {mainCategories.state.categories.map((el) => (
            <SwiperSlide
              onClick={() => {
                redirectToCategory(el.name, el.dayan_id);
              }}
              className={`${css.dummySwipe} ${globalCtx.state.activeCategory === el.name
                ? css.highlighted
                : ""
                }`}
            >
              <button
                style={{
                  background:
                    `url(` +
                    process.env.REACT_APP_BASE_URL +
                    `/public/image/maincategory/` +
                    el.photo +
                    `) center center no-repeat`,
                  backgroundSize: "cover",
                  border: "none"
                }}
              ></button>

              <div>{el.name}</div>
            </SwiperSlide>
          ))}
          {/* <SwiperSlide
            onClick={() => {
              redirectToCategory("Урьдчилсан", null);
            }}
            className={`${css.dummySwipe} ${
              globalCtx.state.activeCategory === "Урьдчилсан"
                ? css.highlighted
                : ""
            }`}
          >
            <button className={css.uridchilsan}></button>
            <div>Урьдчилсан</div>
          </SwiperSlide> */}
          <SwiperSlide
            onClick={() => {
              redirectToCategory("Хямдарсан", null);
            }}
            className={`${css.dummySwipe} ${globalCtx.state.activeCategory === "Хямдарсан"
              ? css.highlighted
              : ""
              }`}
          >
            <button className={css.hyamdral}></button>
            <div>Хямдарсан</div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <OrderSidebar
                sortProducts={sortProducts}
                reSetLimit={reSetLimit}
            selectedCategory={selectedCategory}
            /> */}
    </div>
  );
};

export default SubCategory;
