import React, { useState, useContext, useEffect } from "react";

import { Box, CircularProgress, Fab } from "@mui/material";

import { Check, Delete, Save } from "@mui/icons-material";

import green from "@mui/material/colors/green";
import AlertDialog from "../../../components/Admin/AlertDialog";
import OrderContext from "../../../context/OrderContext";
import ProductContext from "../../../context/ProductContext";
import CategoryContext from "../../../context/CategoyContext";

export default function CategoryActions({ item, rowId, setRowId }) {
  //contexts
  const categoryCtx = useContext(CategoryContext);
  const orderCtx = useContext(OrderContext);
  const productCtx = useContext(ProductContext);

  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // useEffect(() => {
  //   orderCtx.failedOrders();
  // }, []);

  const handleSubmit = async () => {
    item.paymentType = "Хийгдсэн"
    await orderCtx.addOrder(item);
    setSuccess(true);
  };

  const handleDelete = async () => {
    setLoading(true); // Set loading to true to show the loading spinner
    try {
      const result = await orderCtx.deleteOrder(item.id);
      if (result) {
        orderCtx.failedOrders(); // Fetch the updated list of failed orders
      }
      setLoading(false); // Set loading to false to hide the loading spinner
    } catch (error) {
      setLoading(false); // Set loading to false in case of an error
      console.error("Failed to delete order:", error);
    }
  };

  // const handleDelete = async () => {
  //   setSuccess(true);
  //   const result = await orderCtx.deleteOrder(item.id);
  //   if (result) {
  //     orderCtx.failedOrders();
  //   };
  // };

  const agree = async () => {
    setOpenAlert(false);
    const result = await categoryCtx.deleteCategory(params.id);
    if (result) {
      categoryCtx.getSubCategory();
    }
  };
  const disAgree = () => {
    setOpenAlert(false);
  };
  useEffect(() => {
    if (rowId === item.id && success) {
      setSuccess(false);
    }
  }, [rowId]);
  return (
    <>
      <Box sx={{ m: 1, position: "relative" }}>
        {success ? (
          <Fab
            color="red"
            sx={{
              width: 30,
              height: 30,
              bgcolor: green[500],
              "&:hover": { bgcolor: green[700] },
            }}
          >
            <Check />
          </Fab>
        ) : (
          <>
            <Fab
              color="primary"
              sx={{
                width: 30,
                height: 30,
              }}
              onClick={handleSubmit}
            >
              <Save />
            </Fab>
            <Fab
              color="primary"
              sx={{
                marginLeft: 1,
                width: 30,
                height: 30,
              }}
              disabled={false}
              onClick={handleDelete}
            >
              <Delete />
            </Fab>
          </>
        )}
        {loading && (
          <CircularProgress
            size={52}
            sx={{
              color: green[500],
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
      <AlertDialog
        openAlert={openAlert}
        agree={agree}
        disAgree={disAgree}
        title={"Та категори устгахдаа итгалтэй байна уу?"}
        body={"Хэрэв категори устгавал уг категорийн мэдээлэл устах болно."}
      />
    </>
  );
}
