import React, { useState } from "react";
// import axios from "../axios/axios-for-backend";

const LayoutContext = React.createContext();

const initialState = {
  mainMenu: true,
  subMenu: false,
  footer: true,
};
export const LayoutStore = (props) => {
  const [state, setState] = useState(initialState);

  const initLayout = (mainMenu, subMenu, footer) => {
    setState({
      mainMenu: mainMenu,
      subMenu: subMenu,
      footer: footer,
    });
  };
  const clearLayout = () => {
    setState({
      mainMenu: true,
      subMenu: false,
      footer: true,
    });
  };
  return (
    <LayoutContext.Provider value={{ state, initLayout, clearLayout }}>
      {props.children}
    </LayoutContext.Provider>
  );
};
export default LayoutContext;
