import React, { useContext, useState, useEffect, useMemo } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import moment from "moment";

import grey from "@mui/material/colors/grey";
import ProductContext from "../../../context/ProductContext";
import ProductActions from "./ProductActions";
import PauseActions from "./PauseActions";
import { gridClasses } from "@mui/system";
import Spinner from "../../../components/Users/General/Spinner";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
export default function PreProductPage() {
  //contexts
  const productCtx = useContext(ProductContext);

  //states
  const [pageSize, setPageSize] = useState(100);
  const [rowId, setRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showStopModal, setShowStopModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [form, setForm] = useState({
    scheduleDate: "",
    priceType: "",
  });

  const editProduct = (Id, Date, Code) => {
    setForm((formBefore) => ({
      scheduleDate: Date,
    }));
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const editStatusInput = (event) => {
    setForm((formBefore) => ({
      scheduleDate: formBefore.scheduleDate,
      priceType: event.target.value,
    }));
  };

  const handleStartDateChange = (event) => {
    const selectedDate = event.target.value;
    console.log(selectedDate);
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (event) => {
    const selectedDate = event.target.value;
    console.log(selectedDate);
    setEndDate(selectedDate);
  };

  const handleSubmit = (event) => {

    event.preventDefault();
    console.log('Start Date:', startDate);
    console.log('End Date:', endDate);
    productCtx.getAdminFilterPreProducts(startDate, endDate);
  };


  const fetchStatusEdit = async () => {
    const result = await productCtx.updatePreProduct(form);
    if (result) {
      productCtx.getAdminPreProducts();
    }
    handleClose();
  };
  const columns = useMemo(
    () => [
      { field: "Id", hide: true },
      {
        field: "actions",
        width: 140,
        headerName: "Үнэ сонгох",
        type: "actions",
        renderCell: (params) => (
          <ProductActions {...{ params, rowId, setRowId, editProduct }} />
        ),
      },
      {
        field: "Qty",
        headerName: "Үлдэгдэл",
        width: 80,
      },
      {
        field: "Code",
        headerName: "Код",
        width: 80,
      },
      {
        field: "Name",
        headerName: "Нэр",
        width: 180,
      },
      {
        field: "CategoryName",
        headerName: "Категори нэр",
        width: 180,
      },
      {
        field: "PriceListName",
        headerName: "Барааны үнийн төрөл",
        width: 250,
      },
      {
        field: "ScheduledDate",
        headerName: "Бараа ирэх өдөр",
        width: 200,
        renderCell: (params) =>
          moment(params.row.ScheduledDate).format("YYYY-MM-DD"),
      },
      {
        field: "Stop",
        width: 140,
        headerName: "Түр захиалга хасахаа болих",
        type: "actions",
        renderCell: (params) => (
          <PauseActions {...{ params, rowId, setRowId }} />
        ),
      },
    ],

    [rowId]
  );
  useEffect(() => {
    productCtx.getAdminPreProducts();
  }, []);

  return (

    <div className="content-wrapper">
      <div className="content-header ml-2 d-inline-flex">
        <h1>Урьдчилсан бараа</h1>
      </div>
      <div className="content">

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col ml-2">
              <label htmlFor="startDate">Эхлэх өдөр: </label>
              <input
                type="date"
                id="startDate"
                value={startDate || ''}
                onChange={handleStartDateChange}
              />
            </div>
            <div className="col">
              <label htmlFor="endDate">Дуусах өдөр: </label>
              <input
                type="date"
                id="endDate"
                value={endDate || ''}
                onChange={handleEndDateChange}
              />
            </div>
            <div className="col">
              <input type="submit" value="Submit" />
            </div>
          </div>
        </form>
        {productCtx.state.loading && <Spinner />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div
                  className="card-header border-0"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="d-flex justify-content-center">
                    <Box sx={{ height: 730, width: "100%" }}>
                      <Typography
                        variant="h3"
                        component="h3"
                        sx={{ textAlign: "center", mt: 3, mb: 3 }}
                      />
                      <DataGrid
                        rowHeight={120}
                        columns={columns}
                        rows={productCtx.state.Products}
                        getRowId={(row) => row.Id + row.ScheduledDate}
                        rowsPerPageOptions={[100, 200, 500]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        getRowSpacing={(params) => ({
                          top: params.isFirstVisible ? 0 : 5,
                          bottom: params.isLastVisible ? 0 : 5,
                        })}
                        sx={{
                          [`& .${gridClasses.row}`]: {
                            bgcolor: grey[200],
                          },
                        }}
                        onCellEditCommit={(params) => setRowId(params.id)}
                      />
                    </Box>
                    <Dialog open={showModal} onClose={handleClose}>
                      <DialogTitle>Үнийн төлөв солих</DialogTitle>
                      <DialogContent align="center">
                        <DialogContentText>
                          Урьдчилсан захиалгын зарагдах үнэ сонгох.
                        </DialogContentText>

                        <Select
                          style={{ minWidth: 220, marginTop: "15px" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={form.priceType || ""}
                          label="Age"
                          onChange={editStatusInput}
                        >
                          <MenuItem value="Урьдчилсан захиалгын үнэ 1">
                            Урьдчилсан захиалгын үнэ 1
                          </MenuItem>
                          <MenuItem value="Урьдчилсан захиалгын үнэ 2">
                            Урьдчилсан захиалгын үнэ 2
                          </MenuItem>
                          <MenuItem value="Урьдчилсан захиалгын үнэ 3">
                            Урьдчилсан захиалгын үнэ 3
                          </MenuItem>
                          <MenuItem value="Урьдчилсан захиалгын үнэ 4">
                            Урьдчилсан захиалгын үнэ 4
                          </MenuItem>
                          <MenuItem value="Урьдчилсан захиалгын үнэ 5">
                            Урьдчилсан захиалгын үнэ 5
                          </MenuItem>
                        </Select>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Болих</Button>
                        <Button onClick={fetchStatusEdit}>Шинэчлэх</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
