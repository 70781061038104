import React from "react";
import css from "./style.module.css";

const Slide = (props) => {
  return (
    <>
      {props.sliders.link !== "" ? (
        <a className={css.slideImage} href={props.sliders.link} target="_blank">
          <img
            className={css.slideImage}
            src={
              process.env.REACT_APP_BASE_URL +
              "/public/image/banner/" +
              props.sliders.photo
            }
            alt="slider"
          />
        </a>
      ) : (
        <a className={css.slideImage} href="#">
          <img
            className={css.slideImage}
            src={
              process.env.REACT_APP_BASE_URL +
              "/public/image/banner/" +
              props.sliders.photo
            }
            alt="slider"
          />
        </a>
      )}
    </>
  );
};

export default Slide;
