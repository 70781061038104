import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";

import UserContext from "../../../context/UserContext";
import BucketContext from "../../../context/BucketContext";
import css from "./style.module.css";

import MenuItem from "../MenuItem";
import UserLogin from "../UserLogin";
import Shadow from "../General/Shadow";
import Basket from "../Basket";

import usericon from "../../../assets/images/auth/usericon.png";
import arrowicon from "../../../assets/images/auth/arrow.png";
import downArrow from "../../../assets/images/auth/arrowDown.png";
import upArrow from "../../../assets/images/auth/ArrowUp.png";
import preOrderImage from "../../../assets/images/auth/PngItem_3074569 1.png";
import bonusImage from "../../../assets/images/auth/5622939 1.png";
import OrderImage from "../../../assets/images/auth/orderICON 1.png";
import logoutImage from "../../../assets/images/auth/clipart2640924 1.png";
import bucket from "../../../assets/images/subMenu/carto.png";
import user from "../../../assets/images/auth/profile.png";
import info from "../../../assets/images/auth/Info cr-fr.png";


const Menu = () => {
  const navigate = useNavigate();

  //states
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [expandUserMenu, setExpandUserMenu] = useState(false);
  const [showBasket, setShowBasket] = useState(false);

  //contexts
  const userCtx = useContext(UserContext);
  const myBucketCtx = useContext(BucketContext);

  const toggleLoginForm = () => {
    setShowLoginForm((prevState) => !prevState);
  };

  const toggleRegisterForm = () => {
    window.open(
      "https://b24-tuh947.bitrix24.site/",
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
    );
  };

  const closeBasket = () => {
    setShowBasket((prevState) => !prevState);
  };

  const closeExpand = () => {
    setExpandUserMenu(false);
  };

  const openExpand = () => {
    setExpandUserMenu(true);
  };

  const handleLogout = () => {
    closeExpand();
    userCtx.logout();
  };

  const toMyPreOrders = () => {
    closeExpand();
    navigate("/myPreOrders");
  };

  const toMyOrders = () => {
    closeExpand();
    navigate("/myOrders");
  };

  return (
    <div>
      {expandUserMenu && (
        <Shadow opacity={true} show={expandUserMenu} darahad={closeExpand} />
      )}
      {userCtx.state.userId ? (
        <div className={css.Menu}>
          <ul className={css.Menu1}>
            <MenuItem link="/category">B2B Онлайн худалдаа</MenuItem>
            <MenuItem link="/blog">Блог</MenuItem>
            <MenuItem link="/about">Бидний тухай</MenuItem>
            {/* <MenuItem link="/help">Тусламж</MenuItem> */}
            <MenuItem link="/contact">Холбоо барих</MenuItem>
          </ul>
          <ul className={css.Menu2}>
          <li>
              <div className={css.bucketContainer}>
                <Link onClick={window.scrollTo(0, 0)} to="/help">
                  <span className={css.tooltip}>
                    <img className={css.bucketIcon} src={info} alt="info" />
                    <span className={css.tooltipText}>Тусламж</span>
                  </span>
                </Link>
              </div>
            </li>
            <li>
              <div className={css.bucketContainer}>
                <img
                  className={css.bucketIcon}
                  src={bucket}
                  alt="bucket"
                  onClick={() => {
                    closeBasket();
                  }}
                />
                {myBucketCtx.state.myBuckets.length > 0 && (
                  <p>{myBucketCtx.state.myBuckets.length}</p>
                )}
              </div>
            </li>
            <li>
              <div className={css.UserDetail}>
                <div onClick={() => {
                  openExpand();
                }} className={css.UserDetailTitle}>
                  <div className={css.UserProfile}>
                    <img src={user} alt="Profile" />
                    <p>{userCtx.state.userDetail.f_name}</p>
                  </div>
                </div>
                {expandUserMenu && (
                  <div className={css.expandedInfo}>
                    <div
                      onClick={() => {
                        toMyPreOrders();
                      }}
                    >
                      <img src={preOrderImage} alt="usericon" />
                      <p>Урьдчилсан захиалгын түүх</p>
                    </div>
                    {/* <div>
                      <img src={bonusImage} alt="usericon" />
                      <p style={{ cursor: "pointer" }}>
                        Миний бонус2{" "}
                        <span
                          style={{
                            color: "#ff4f58",
                            fontWeight: "600",
                            marginLeft: "10px",
                          }}
                        >
                          {userCtx.state.userDetail.bonusPoint &&
                            userCtx.state.userDetail.bonusPoint.toLocaleString()}
                        </span>
                      </p>
                    </div> */}
                    <div
                      onClick={() => {
                        toMyOrders();
                      }}
                    >
                      <img src={OrderImage} alt="usericon" />
                      <p>Миний захиалга</p>
                    </div>
                    <div
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      <img src={logoutImage} alt="usericon" />
                      <p>Системээс гарах</p>
                    </div>
                  </div>
                )}
              </div>
            </li>
            <div className={css.toolbarBasket}>
              {showBasket && <Basket closeBasket={closeBasket} />}
            </div>
          </ul>

        </div>
      ) : (
        <div className={css.Menu}>
          <ul className={css.Menu1}>
            <MenuItem link="/category">B2B Онлайн худалдаа</MenuItem>
            <MenuItem link="/blog">Блог</MenuItem>
            <MenuItem link="/about">Бидний тухай</MenuItem>
            {/* <MenuItem link="/help">Тусламж</MenuItem> */}
            <MenuItem link="/contact">Холбоо барих</MenuItem>
          </ul>
          <ul className={css.Menu2} >
            <li>
              <div className={css.bucketContainer}>
                <Link onClick={window.scrollTo(0, 0)} to="/help">
                  <img
                    className={css.bucketIcon}
                    src={info}
                    alt="info"
                  />
                </Link>
                {/* {myBucketCtx.state.myBuckets.length > 0 && (
                  <p>{myBucketCtx.state.myBuckets.length}</p>
                )} */}
              </div>
            </li>
            <li
              onClick={() => {
                toggleLoginForm();
              }}
              className={css.Login}
            >
              {/* <img src={usericon} alt="usericon" /> */}
              <span>Нэвтрэх</span>
            </li>
            {/* <li
              onClick={() => {
                toggleRegisterForm();
              }}
              className={css.Register}
            >
              <span>Бүртгүүлэх</span>
              <img src={arrowicon} alt="usericon" />
            </li> */}
          </ul>
        </div>
      )}

      {showLoginForm && (
        <UserLogin
          toggleLoginForm={toggleLoginForm}
          toRegisterForm={toggleRegisterForm}
        />
      )}
    </div>
  );
};

export default Menu;
