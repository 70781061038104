import React, { useContext, useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Fab } from "@mui/material";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import Spinner from "../../../components/Users/General/Spinner";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import OrderContext from "../../../context/OrderContext";
import ProductContext from "../../../context/ProductContext";

const OrderPage = (props) => {
  //states
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const [form, setForm] = useState({
    deliveryType: "",
    paymentType: "",
    id: "",
  });

  //edit order
  const handleOrderEdit = (id, deliveryType, paymentType) => {
    setForm((formBefore) => ({
      id: id,
      deliveryType: deliveryType,
      paymentType: paymentType,
    }));
    setOpenEditOrder(true);
  };
  const fetchOrderEdit = async () => {
    console.log("javhaa",form);
    await orderCtx.updateOrder(form);
    closeEditOrder();
  };
  const closeEditOrder = () => {
    setOpenEditOrder(false);
  };
  const editOrderInput = (event) => {
    setForm((formBefore) => ({
      id: formBefore.id,
      deliveryType: event.target.value,
      paymentType: formBefore.paymentType,
    }));
  };

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "Үүссэн огноо",
    },
    {
      name: "И-мэйл",
    },
    {
      name: "Утас",
    },
    {
      name: "Deal_ID",
    },
    {
      name: "Зарцуулсан Бонус",
    },
    // {
    //   name: "Хүргэлтээр авах",
    // },
    // {
    //   name: "Хүргэлтийн Хаяг",
    // },
    // {
    //   name: "Нэмэлт хэрэгсэл",
    // },
    {
      name: "Нийт төлбөр",
    },
    // {
    //   name: "Төлсөн дүн",
    // },
    // {
    //   name: "Төлбөр статус",
    // },
    {
      name: "Бараа баталгаажуулах",
    },
    {
      name: "Үйлдэл",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color="primary"
              sx={{
                width: 40,
                height: 40,
              }}
              onClick={() =>
                handleOrderEdit(
                  tableMeta.rowData[0],
                  tableMeta.rowData[7],
                  // tableMeta.rowData[8],
                )
              }
            >
              <Edit />
            </Fab>
          );
        },
      },
    },
  ];
  // {
  //   name: "Засах",
  //   options: {
  //     filter: true,
  //     sort: false,
  //     empty: true,
  //     customBodyRender: (value, tableMeta, updateValue) => {
  //       return (
  //         <Fab
  //           color="primary"
  //           sx={{
  //             width: 40,
  //             height: 40,
  //           }}
  //           onClick={() =>
  //             handleOrderEdit(
  //               tableMeta.rowData[0],
  //               tableMeta.rowData[11],
  //               tableMeta.rowData[10]
  //             )
  //           }
  //         >
  //           <Edit />
  //         </Fab>
  //       );
  //     },
  //   },
  // },
  //contexts
  const orderCtx = useContext(OrderContext);
  const productCtx = useContext(ProductContext);

  useEffect(() => {
    productCtx.clearProductData();
    orderCtx.clearOrderData();
    orderCtx.getAllOrder();
  }, []);

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {},
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={12}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Ширхэг</TableCell>
                      <TableCell align="center">Багц</TableCell>
                      <TableCell align="center">Нэгж үнэ</TableCell>
                      <TableCell align="center">Нийт үнэ</TableCell>
                      <TableCell align="center">Хямдрах тоо</TableCell>
                      <TableCell align="center">Код</TableCell>
                      <TableCell align="center">Нэр</TableCell>
                      <TableCell align="center">Категори</TableCell>
                      <TableCell align="center">Төрөл</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderCtx.state.myOrders
                      .filter((el) => el._id === rowData[0])[0]
                      .buckets.map((row) => (
                        <TableRow key={row._id}>
                          <TableCell align="center">{row.piece}ш</TableCell>
                          <TableCell align="center">
                            {row.QtyPerpackage}ш
                          </TableCell>
                          <TableCell align="center">
                            {row.listPrice.toLocaleString()}₮
                          </TableCell>
                          <TableCell align="center">
                            {row.totalPrice.toLocaleString()}₮
                          </TableCell>
                          <TableCell align="center">{row.WholeQty}</TableCell>
                          <TableCell align="center">{row.code}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">
                            {row.categoryName}
                          </TableCell>
                          <TableCell align="center">{row.type}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  return (
    <div className="content-wrapper">
      <div className="content-header ml-2 d-inline-flex"></div>
      <div className="content">
        {orderCtx.state.loading && <Spinner />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div
                  className="card-header border-0"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="d-flex justify-content-between"></div>
                  <MUIDataTable
                    title={"Энгийн захиалга"}
                    data={orderCtx.state.myOrders.map((item) => {
                      if (item.deliveryStatus) {
                        item.deliveryStatus = "Хүргэлтээр авна";
                      } else {
                        item.deliveryStatus = "Хүргэлтээр авахгүй";
                      }
                      return [
                        item._id,
                        // moment(item.createdAt).format("YYYY-MM-DD HH:MM:SS"),
                        moment(item.createdAt).format("YYYY-MM-DD HH:mm"),
                        item.createUser.email,
                        item.createUser.phone,
                        item.bonusEarned.bittrexId,
                        item.bonusEarned,
                        // item.deliveryStatus,
                        // item.deliveryAddress,
                        // item.orderExtraItem,
                        item.orderTotalPrice.toLocaleString() + "₮",
                        // item.payedAmount.toLocaleString() + "₮",
                        item.paymentType,
                        item.deliveryType,
                      ];
                    })}
                    columns={columns}
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={openEditOrder} onClose={closeEditOrder}>
        <DialogTitle>Захиалгын төлөв солих</DialogTitle>
        <DialogContent align="center">
          <DialogContentText>
            Бараа баталгаажуулах
          </DialogContentText>
          <Select
            style={{ minWidth: 220 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={form.deliveryType}
            label="Age"
            onChange={editOrderInput}
          >
            <MenuItem value="Баталгаажуулсан">Баталгаажуулсан</MenuItem>
            <MenuItem value="Баталгаажаагүй">Баталгаажаагүй</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditOrder}>Болих</Button>
          <Button onClick={fetchOrderEdit}>Шинэчлэх</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderPage;
