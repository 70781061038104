
import React, { useContext, useState, useEffect, useRef } from "react";
import { Fab } from "@mui/material";
import ProductContext from "../../../context/ProductContext";
import PreOrderContext from "../../../context/PreOrderContext";

export default function EqualPage() {
    const productContext = useContext(ProductContext);
    const preOrderCtx = useContext(PreOrderContext);
    
    const [selectedDate, setSelectedDate] = useState("");
     const [enteredDate, setEnteredDate] = useState("");
    const handleGetAllPrice = async () => {
        try {
            await productContext.getAllPrice();
            setTimeout(function() {
                alert('Амжилттай үлдэгдэл хадгаллаа.');
            }, 10000); 
        } catch (error) {
            console.error('Алдаа гарлаа:', error);
        }
    };
    const handleGetAllBalance = async () => {
        try {
            await productContext.getAllBalance();
            setTimeout(function() {
                alert('Амжилттай үлдэгдэл хадгаллаа.');
            }, 10000); 
        } catch (error) {
            console.error('Алдаа гарлаа:', error);
        }
    };
    const dateChange = async () => {
          try {
            await productContext.dateChange(selectedDate, enteredDate );
            setTimeout(function() {
                alert('Амжилттай үлдэгдэл хадгаллаа.');
            }, 10000); 
        } catch (error) {
            console.error('Алдаа гарлаа:', error);
        }
    }
    const handleSelectChange = (e) => {
        const selectedDate = e.target.value;
        setSelectedDate(selectedDate);
    };
    const handleInputDateChange = (e) => {
        const enteredDate = e.target.value;
        setEnteredDate(enteredDate);
    };
    useEffect(() => {
        preOrderCtx.getPreOrderSchedule();
    }, []);
    return (
        <div className="content-wrapper">
            <div className="content-header ml-2 d-inline-flex">
                <h1>Үлдэгдэл тэнцүүлэх</h1>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row mb-5 mt-5 p-5">
                                <div className="col">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleGetAllBalance}
                                    >
                                        Урьдчилсан бараа үлдэгдэл татах
                                    </button>
                                </div>
                                <div className="col">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleGetAllPrice}
                                    >
                                        Энгийн бараа үнэ үлдэгдэл татах
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row mb-5 mt-5 p-5 border rounded">
                                <div className="col-md-6">
                               <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    onChange={(e) => handleSelectChange(e)} // Implement handleSelectChange as needed
                                >
                                    <option value="">
                                        Урьдчилсан бараа өдөр сонгох
                                    </option>
                                    {preOrderCtx.state.preOrders.map((el, index) => (
                                        <option
                                            value={el.start}
                                            key={index}
                                            // You can add other attributes as needed
                                        >
                                            {new Date(el.start).toLocaleDateString('en-US')} {/* Format date as needed */}
                                        </option>
                                    ))}
                                </select>
                                </div>
                                <div className="col-md-6">
                                    <label for="changeDate">Шилжүүлэх өдөр сонгох: </label>
                                    <input type="date" id="changeDate" name="changeDate" onChange={handleInputDateChange} className="ml-5"/>
                                    <button
                                        type="button"
                                        className="btn btn-primary ml-2"
                                        onClick={dateChange}
                                    >
                                        Хадгалах
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
