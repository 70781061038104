import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"
import React, { useState, useContext, useEffect } from "react";
import CaleContext from "../../../context/CalendarContext";
import { v4 as uuid } from "uuid";
import css from "./style.module.css";

export function CalendarTime() {
  const calendarCtx = useContext(CaleContext);
  // const [events, setEvents] = useState([calendarCtx.state.days]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(calendarCtx.state.days);
  }, [calendarCtx.state.days]);

  const handleSelect = async (info) => {
    const { start, end } = info;
    const eventNamePrompt = prompt("Гарчиг оруулна уу");
    if (eventNamePrompt) {
      const title = eventNamePrompt;
      const result = await calendarCtx.createCalendar(start, end, title);
      if (result) {
        setEvents([
          ...events,
          {
            start: start,
            end: end,
            title: title,
            background: "linear-gradient(90deg, pink 80%, cyan 0%)",
            id: uuid(), // Generate a unique ID for the event
          },
        ]);
      }
    }
  };
  const handleDelete = (event) => {
    const eventId = event._def.extendedProps._id;
    calendarCtx
      .deleteCalendar(eventId)
      .then((result) => {
        if (result) {
          const updatedEvents = events.filter((evt) => evt._id !== eventId);
          setEvents(updatedEvents);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const eventContent = (eventInfo) => {
    return (
      <div className={css.itemContain}>
        {/* {eventInfo.timeText} */}
        <br />
        <div> {eventInfo.event.title}</div>
        <button onClick={() => handleDelete(eventInfo.event)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
          </svg>
        </button>
      </div>
    );
  };
  return (
    <div style={{ padding: "0 20px 60px 0", width: "50%", marginLeft: "30%" }}>
      <FullCalendar
        // editable
        height={700}
        selectable
        events={events}
        displayEventTime={false}
        eventContent={eventContent}
        select={handleSelect}
        plugins={[dayGridPlugin, interactionPlugin]}
        views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
      />
    </div>
  );
}
