import React from "react";

export default function Footer() {
  return (
    <div>
      {/* Control Sidebar */}
      <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
      </aside>
      {/* /.control-sidebar */}
      {/* Main Footer */}
      <footer className="main-footer">
        <strong>
          Copyright © 2023
          <p>Gardenia</p>
        </strong>
      </footer>
    </div>
  );
}
