import React, { useState, useContext, useEffect } from "react";

import { Box, CircularProgress, Fab } from "@mui/material";

import { Check, Delete, Save } from "@mui/icons-material";

import green from "@mui/material/colors/green";
import AlertDialog from "../../../components/Admin/AlertDialog";
import HelpBannerContext from "../../../context/HelpBannerContext";


export default function CategoryActions({ params,photo123, rowId, setRowId }) {
  //contexts
  const categoryCtx = useContext(HelpBannerContext);
  //states
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
 
  const handleSubmit = async () => {
    console.log(params.row.link);
    setLoading(true);
    const { isActive, _id } = params.row;
    const changePhoto = photo123;
    const photoName = params.row.photo;
    const link = params.row.link;

    const result = await categoryCtx.updateBanner(isActive, _id, changePhoto, photoName, link);
    if (result) {
      setSuccess(true);
      setRowId(null);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (rowId === params.id && success) {
      setSuccess(false);
    }
  }, [rowId]);
  useEffect(() => {
    if (photo123 === undefined) {
      setLoading(false);
    }
  }, [photo123]);

  return (
    <>
      <Box sx={{ m: 1, position: "relative" }}>
        {success ? (
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
              bgcolor: green[500],
              "&:hover": { bgcolor: green[700] },
            }}
          >
            <Check />
          </Fab>
        ) : (
          <>
            <Fab
              color="primary"
              sx={{
                width: 40,
                height: 40,
              }}
              disabled={ params.id !== rowId  || loading}
              onClick={handleSubmit}
            >
              <Save />
            </Fab>
          
          </>
        )}
        {loading && (
          <CircularProgress
            size={52}
            sx={{
              color: green[500],
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </>
  );
}
