import React, { useContext, useState, useEffect, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import grey from "@mui/material/colors/grey";
import HelperContext from "../../../context/HelperContext";
import HelperActions from "./HelperActions";
import { gridClasses } from "@mui/system";
import Image from "mui-image";
import Spinner from "../../../components/Users/General/Spinner";
import { Margin } from "@mui/icons-material";
import css from "./style.module.css";


export default function HelperPage() {
    //contexts
    useEffect(() => {
        helperCtx.Helper();
    }, []);
    const helperCtx = useContext(HelperContext);

    const [formData, setFormData] = useState({
        title1: helperCtx.state.categories[0].title1,
        text1: helperCtx.state.categories[0].text1,
        title2: helperCtx.state.categories[0].title2,
        text2: helperCtx.state.categories[0].text2,
        title3: helperCtx.state.categories[0].title3,
        text3: helperCtx.state.categories[0].text3,
        title4: helperCtx.state.categories[0].title4,
        text4: helperCtx.state.categories[0].text4,
    });
    const _id = "6440fd0ea8d4adb06a51d160"
    const [photoFile, setPhoto] = useState();
    const [photoURL, setPhotoURL] = useState("");
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    function handleFileChange(event, id) {
        const file = event.target.files[0];
        setPhoto(file);
        setPhotoURL(URL.createObjectURL(file));
    }

    return (
        <div className="content-wrapper">
            <div className="content-header ml-2 d-inline-flex">
                <h1>Тусламж</h1>
            </div>
            <div className="content">
                {helperCtx.state.loading && <Spinner />}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header border-0 helper-header">
                                    <div className="d-flex justify-content-center">
                                        <Box sx={{ height: "10%", width: "100%" }}>
                                            <Typography
                                                variant="h3"
                                                component="h3"
                                                sx={{ textAlign: "center", mt: 3, mb: 3 }}
                                            />
                                            <form
                                                className="helper-form"
                                            >
                                                <div className="row">
                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Title:</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title1"
                                                                value={formData.title1}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Text:</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="text1"
                                                                value={formData.text1}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Title2:</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title2"
                                                                value={formData.title2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Text2:</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="text2"
                                                                value={formData.text2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Title3:</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title3"
                                                                value={formData.title3}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Text3:</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="text3"
                                                                value={formData.text3}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Title4:</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title4"
                                                                value={formData.title4}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Text4:</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="text4"
                                                                value={formData.text4}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={css.rowSize}>
                                                    <div className="d-flex align-items-center">
                                                        <Image className={css.rowSize}
                                                            src={
                                                                photoURL !== "" ? photoURL :
                                                                    process.env.REACT_APP_BASE_URL +
                                                                    "/public/image/auth/" + "faq.png"
                                                            }
                                                        />
                                                        <input
                                                            type="file"
                                                            className="form-control-file ml-2"
                                                            onChange={(event) => handleFileChange(event)}
                                                        />
                                                    </div>
                                                </div>
                                                <HelperActions {...{ _id, formData, photoFile }} />
                                            </form>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
