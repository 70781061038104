import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserStore } from "./context/UserContext";
import { LayoutStore } from "./context/LayoutContext";
import { GlobalStore } from "./context/GlobalContext";
import { MyBucketStore } from "./context/BucketContext";
import { CategoryStore } from "./context/CategoyContext";
import { SpecialCategory } from "./context/SpecialCategory";
import { MyOrderStore } from "./context/OrderContext";
import { ProductStore } from "./context/ProductContext";
import { SpecialProductStore } from "./context/SpecialProductContext";
import { PreOrderStore } from "./context/PreOrderContext";
import { BlogStore } from "./context/BlogContext";
import { MainCategoryStore } from "./context/MainCategoryContext";
import { HelperStore } from "./context/HelperContext";
import { HelpBannerStore } from "./context/HelpBannerContext";
import { CalendarStore } from "./context/CalendarContext";
import { AboutUsStore } from "./context/AboutUsContext";
import { ContactUsStore } from "./context/ContactContext";
import { ProductStorePause } from "./context/PausePreOrderContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <LayoutStore>
      <GlobalStore>
        <UserStore>
          <MyBucketStore>
            <ProductStore>
              <SpecialProductStore>
                <MyOrderStore>
                  <PreOrderStore>
                    <CategoryStore>
                      <SpecialCategory>
                        <BlogStore>
                          <MainCategoryStore>
                            <HelperStore>
                              <HelpBannerStore>
                                <AboutUsStore>
                                  <ContactUsStore>
                                    <CalendarStore>
                                      <ProductStorePause>
                                        <App />
                                      </ProductStorePause>
                                    </CalendarStore>
                                  </ContactUsStore>
                                </AboutUsStore>
                              </HelpBannerStore>
                            </HelperStore>
                          </MainCategoryStore>
                        </BlogStore>
                      </SpecialCategory>
                    </CategoryStore>
                  </PreOrderStore>
                </MyOrderStore>
              </SpecialProductStore>
            </ProductStore>
          </MyBucketStore>
        </UserStore>
      </GlobalStore>
    </LayoutStore>
  </BrowserRouter>
);

reportWebVitals();
