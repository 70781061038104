import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const HelpBannerContext = React.createContext();

const initialState = {
  sliders: [],
  loading : false
};
export const HelpBannerStore = (props) => {
  const [state, setState] = useState(initialState);
  const HelpBanner = () => {
    axios
      .get("helpBanner")
      .then((result) => {
        setState({ ...state, sliders: result.data.data });
      })
      .catch((err) => {
        console.log(err);
        setState({
          error: err.response.data.error.message,
        });
      });
      console.log(state);
  };

  const updateBanner = async (body, categoryId, changePhoto, photoName, link) => {
    console.log("test",categoryId);
    var formData = new FormData();
    formData.append("file", changePhoto);
    formData.append("pname", photoName);
    formData.append("link", link);
    formData.append("categoryId", categoryId);
    setState({ ...state, loading: true });
    const res = await axios
      .put("helpbanner/" + categoryId, formData )
      .then((result) => {
        setState({ ...state, success: true, loading: false });
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
    return res;
  };

  return (
    <HelpBannerContext.Provider
      value={{ state, HelpBanner, updateBanner }}
    >
      {props.children}
    </HelpBannerContext.Provider>
  );
};
export default HelpBannerContext;
