import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import Spinner from "../../components/Users/General/Spinner";
import OrderContext from "../../context/OrderContext";
import css from "./style.module.css";

const OrderPage = (props) => {
  //states
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const [form, setForm] = useState({
    deliveryType: "",
    paymentType: "",
    id: "",
  });

  //edit order
  const handleOrderEdit = (id, deliveryType, paymentType) => {
    setForm((formBefore) => ({
      id: id,
      deliveryType: deliveryType,
      paymentType: paymentType,
    }));
    setOpenEditOrder(true);
  };
  const fetchOrderEdit = async () => {
    await orderCtx.updateOrder(form);
    closeEditOrder();
  };
  const closeEditOrder = () => {
    setOpenEditOrder(false);
  };
  const editOrderInput = (event) => {
    setForm((formBefore) => ({
      id: formBefore.id,
      deliveryType: event.target.value,
      paymentType: formBefore.paymentType,
    }));
  };

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "Үүссэн огноо",
    },
    {
      name: "И-мэйл",
    },
    {
      name: "Утас",
    },
    {
      name: "Зарцуулсан Бонус",
    },
    // {
    //   name: "Хүргэлтээр авах",
    // },
    // {
    //   name: "Хүргэлтийн Хаяг",
    // },
    // {
    //   name: "Нэмэлт хэрэгсэл",
    // },
    {
      name: "Нийт төлбөр",
    },
    {
      name: "Төлбөр статус",
    },
    {
      name: "Захиалгын статус",
    },
  ];

  //contexts
  const orderCtx = useContext(OrderContext);

  useEffect(() => {
    orderCtx.clearOrderData();
    orderCtx.getAllPreOrders();
  }, []);

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => { },
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={12}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Ширхэг</TableCell>
                      <TableCell align="center">Багц</TableCell>
                      <TableCell align="center">Нэгж үнэ</TableCell>
                      <TableCell align="center">Нийт үнэ</TableCell>
                      <TableCell align="center">Хямдрах тоо</TableCell>
                      <TableCell align="center">Код</TableCell>
                      <TableCell align="center">Нэр</TableCell>
                      <TableCell align="center">Категори</TableCell>
                      <TableCell align="center">Төрөл</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderCtx.state.myOrders
                      .filter((el) => el._id === rowData[0])[0]
                      .buckets.map((row) => (
                        <TableRow key={row._id}>
                          <TableCell align="center">{row.piece}ш</TableCell>
                          <TableCell align="center">
                            {row.QtyPerpackage}ш
                          </TableCell>
                          <TableCell align="center">
                            {row.listPrice.toLocaleString()}₮
                          </TableCell>
                          <TableCell align="center">
                            {row.totalPrice.toLocaleString()}₮
                          </TableCell>
                          <TableCell align="center">{row.WholeQty}</TableCell>
                          <TableCell align="center">{row.code}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">
                            {row.categoryName}
                          </TableCell>
                          <TableCell align="center">{row.type}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  return (
    <div className={css.PreOrderPage}>
      <div className="content-header ml-2 d-inline-flex"></div>
      <div className="content">
        {orderCtx.state.loading && <Spinner />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="card-header border-0"
                style={{ marginBottom: "30px" }}
              >
                <div className="d-flex justify-content-between"></div>
                <MUIDataTable
                  title={"Урьдчилсан захиалга"}
                  data={orderCtx.state.myOrders
                    .filter((el) => el.pre_order)
                    .map((item) => {
                      if (item.deliveryStatus) {
                        item.deliveryStatus = "Хүргэлтээр авна";
                      } else {
                        item.deliveryStatus = "Хүргэлтээр авахгүй";
                      }
                      return [
                        item._id,
                        moment(item.createdAt).format("YYYY-MM-DD HH:MM:SS"),
                        item.createUser.email,
                        item.createUser.phone,
                        item.bonusEarned,
                        // item.deliveryStatus,
                        // item.deliveryAddress,
                        // item.orderExtraItem,
                        item.orderTotalPrice.toLocaleString() + "₮",
                        item.paymentType,
                        item.deliveryType,
                      ];
                    })}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
