import React, { useState } from "react";
import axios from "../axios/axios-for-backend";

const CategoryContext = React.createContext();

const initialState = {
  categories: [],
  subCategories: [],
  specialCategory:[],
  success: false,
  loading: false,
};
export const CategoryStore = (props) => {
  const [state, setState] = useState(initialState);

  const getAllCategory = () => {
    axios
      .get("categories")
      .then((result) => {
        setState({ ...state, categories: result.data.categories });
        getSubCategory();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSpecialCategory = () => {
    axios
      .get("categories/getSpecialCategory")
      .then((result) => {
        setState({ ...state, specialCategory: result.data.categories });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubCategory = () => {
    axios
      .get("categories/sub")
      .then((result) => {
        setState((prevState) => ({
          categories: prevState.categories,
          subCategories: result.data.categories,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubCategoryAdmin = () => {
    setState({ ...state, loading: true });
    axios
      .get("categories/subadmin")
      .then((result) => {
        setState({ ...state, loading: false });
        setState({ ...state, subCategories: result.data.categories });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };

  const updateCategory = async (body, categoryId) => {
    console.log(body);
    setState({ ...state, loading: true });
    const res = await axios
      .put("categories/" + categoryId, body)
      .then((result) => {
        setState({ ...state, success: true, loading: false });
        return result;
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
    return res;
  };

  const deleteCategory = async (categoryId) => {
    const res = await axios
      .delete("categories/" + categoryId)
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((err) => console.log(err));
    return res;
  };

  return (
    <CategoryContext.Provider
      value={{
        state,
        getAllCategory,
        getSpecialCategory,
        deleteCategory,
        getSubCategory,
        updateCategory,
        getSubCategoryAdmin,
      }}
    >
      {props.children}
    </CategoryContext.Provider>
  );
};
export default CategoryContext;
