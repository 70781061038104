import { Paper } from "@mui/material";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

const AddImagesForBlog = ({ getFile }) => {
  const [files, setFiles] = useState();
  const [preview, setPreview] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    const objectUrl = URL.createObjectURL(acceptedFiles[0]);
    setPreview(objectUrl);
    getFile(acceptedFiles[0]);
    console.log(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });
  return (
    <>
      <Paper
        sx={{
          cursor: "pointer",
          background: "#fafafa",
          color: "#bdbdbd",
          border: "1px dashed #ccc",
          "&:hover": { border: "1px solid #ccc" },
        }}
      >
        <div style={{ padding: "16px" }} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p style={{ color: "green" }}>Drop the files here...</p>
          ) : (
            <p>Drag 'n' Drop some files here, or click to select files</p>
          )}
          <em>(images with *.jpeg, *.png, *.jpg extension will be accepted)</em>
        </div>
      </Paper>
      <div>
        {files && (
          <img style={{ heigth: "400px", width: "500px" }} src={preview} />
        )}
      </div>
    </>
  );
};

export default AddImagesForBlog;
