import { Cancel } from "@mui/icons-material";
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  CircularProgress, // Import the CircularProgress component
} from "@mui/material";
import React, { useContext, useState } from "react";
import ProductContext from "../../../context/ProductContext";

const ImagesList = ({ editImageId }) => {
  const productCtx = useContext(ProductContext);
  let images = productCtx.state.Products.filter((el) => el.Id === editImageId);

  const [deleteInProgress, setDeleteInProgress] = useState(false); // Step 1: Add a loader state

  const handleDelete = async (image) => {
    try {
      setDeleteInProgress(true); // Step 2: Toggle the loader state
      await productCtx.deleteProductImage(editImageId, image);
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteInProgress(false); // Step 2: Toggle the loader state
    }
  };

  return (
    <ImageList
      rowHeight={250}
      sx={{
        "&.MuiImageList-root": {
          gridTemplateColumns:
            "repeat(auto-fill, minmax(250px, 1fr))!important",
        },
      }}
    >
      {images[0].DetailImages.map((image, index) => (
        <ImageListItem key={index} cols={1} rows={1}>
          <img
            src={
              process.env.REACT_APP_BASE_URL + "/public/image/products/" + image
            }
            alt="rooms"
            loading="lazy"
            style={{ height: "100%" }}
          />
          <ImageListItemBar
            position="top"
            sx={{
              background:
                "transparent"
            }}
            actionIcon={
              deleteInProgress ? ( // Step 3: Conditionally render the loader
                <CircularProgress sx={{ color: "gray" }} size={24} />
              ) : (
                <IconButton
                  sx={{ color: "gray" }}
                  onClick={() => handleDelete(image)}
                >
                  <Cancel />
                </IconButton>
              )
            }
          ></ImageListItemBar>
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImagesList;
