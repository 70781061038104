import React, { useState, useContext, useEffect } from "react";
import css from "./style.module.css";
import noImageIcon from "../../../assets/images/product/no-img.png";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";

const NewProduct = (props) => {
  const globalCtx = useContext(GlobalContext);
  const navigate = useNavigate();


  const redirectToCategory = (categoryName, activeID) => {
    globalCtx.setActiveCategory(categoryName, activeID);
    navigate(`/category`);
  };

  return (
    <div className={css.NewProduct}>
      <img
        className={css.slideImage}
        src={
          props.newProduct.DetailImages[0]
            ? process.env.REACT_APP_BASE_URL +
            "/public/image/products/" +
            props.newProduct.DetailImages[0]
            : noImageIcon
        }
        alt="product"
      />
      <div className={css.ProductDetail}>
        <p className={css.ProductPriceText}>{props.newProduct.Name}</p>
        <div className={css.ProductPriceDetail}>
          {props.userId ? (
            <div className={css.ProductPrice}>
              <div className={css.negj}>Нэгж үнэ:</div>
              <p>₮{props.newProduct.ListPrice}</p>
            </div>
          ) : (
            <div className={css.ProductPrice}>
              <div className={css.negj}>Нэгж үнэ:</div>
              <p>--,-₮</p>
            </div>
          )}
          <div className={css.ProductOrder}>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                redirectToCategory("Онцгой бараа", "c1fd26b4-4a35-47d9-9032-278501d3ef72");
              }}
            >Захиалах</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProduct;