import React, { useState, useContext, useEffect } from "react";

import { Box, CircularProgress, Fab } from "@mui/material";

import { Check, Delete, Save } from "@mui/icons-material";

import green from "@mui/material/colors/green";
import AlertDialog from "../../../components/Admin/AlertDialog";
import HelperContext from "../../../context/HelperContext";
import css from "./style.module.css";



export default function HelperActions({ _id, formData, photoFile }) {
    //contexts
    const helperCtx = useContext(HelperContext);
    //states
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    console.log("==========================")
    console.log(_id)
    console.log(formData)
    console.log(photoFile)
    console.log("==========================")

    const handleSubmit = async () => {
        setLoading(true);
        const _id = '6440fd0ea8d4adb06a51d160';
        const changePhoto = photoFile;
        const passForm = formData

        const result = await helperCtx.updateHelper(_id, passForm, changePhoto);
        if (result) {
            setSuccess(true);
        }
        setLoading(false);
    };


    const handleDelete = async () => {
        setOpenAlert(true);
    };

    const agree = async () => {
        setOpenAlert(false);
        const result = await helperCtx.deleteCategory(params.id);
        if (result) {
            helperCtx.getSubCategory();
        }
    };
    const disAgree = () => {
        setOpenAlert(false);
    };
    // useEffect(() => {
    //     if ( success) {
    //         setSuccess(false);
    //     }
    // }, [changePhoto]);
    // useEffect(() => {
    //     if (photoFile === undefined) {
    //         setLoading(false);
    //     }
    // }, [photoFile]);
    return (
        <div className="d-flex justify-content-center mb-5">
            <Box sx={{ m: 1, position: "relative" }}>
                {success ? (
                    <Fab
                        color="primary"
                        sx={{
                            width: 40,
                            height: 40,
                            bgcolor: green[500],
                            "&:hover": { bgcolor: green[700] },
                        }}
                    >
                        <Check />
                    </Fab>
                ) : (
                    <>
                        <Fab
                            color="primary"
                            sx={{
                                width: 40,
                                height: 40,
                            }}
                            disabled={loading}
                            onClick={handleSubmit}
                        >
                            <Save />
                        </Fab>
                    </>
                )}
                {loading && (
                    <CircularProgress
                        size={52}
                        sx={{
                            color: green[500],
                            position: "absolute",
                            top: -6,
                            left: -6,
                            zIndex: 1,
                        }}
                    />
                )}
            </Box>
            <AlertDialog
                openAlert={openAlert}
                agree={agree}
                disAgree={disAgree}
                title={"Та категори устгахдаа итгалтэй байна уу?"}
                body={"Хэрэв категори устгавал уг категорийн мэдээлэл устах болно."}
            />
        </div>
    );
}
