import React, { useContext, useState, useEffect, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import grey from "@mui/material/colors/grey";
import ContactContext from "../../../context/ContactContext";
import ContactActions from "./ContactActions";
import { gridClasses } from "@mui/system";
import Image from "mui-image";
import Spinner from "../../../components/Users/General/Spinner";
import { Margin } from "@mui/icons-material";
import css from "./style.module.css";


export default function ContactUsPage() {
    //contexts
    useEffect(() => {
        contactCtx.ContactUs();
    }, []);
    const contactCtx = useContext(ContactContext);

    const [formData, setFormData] = useState({
        title1: contactCtx.state.categories[0].title1,
        text1: contactCtx.state.categories[0].text1,
        title2: contactCtx.state.categories[0].title2,
        text2: contactCtx.state.categories[0].text2,
        title3: contactCtx.state.categories[0].title3,
        text3: contactCtx.state.categories[0].text3,
        googleMap: contactCtx.state.categories[0].googleMap
    });
    const _id = "644f37c9bf890f505cf63a49"
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <div className="content-wrapper">
            <div className="content-header ml-2 d-inline-flex">
                <h1>Тусламж</h1>
            </div>
            <div className="content">
                {contactCtx.state.loading && <Spinner />}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header border-0 helper-header">
                                    <div className="d-flex justify-content-center">
                                        <Box sx={{ height: "10%", width: "100%" }}>
                                            <Typography
                                                variant="h3"
                                                component="h3"
                                                sx={{ textAlign: "center", mt: 3, mb: 3 }}
                                            />
                                            <form
                                                className="helper-form"
                                            >
                                                <div className="row">
                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Утас:</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title1"
                                                                value={formData.title1}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Утга:</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="text1"
                                                                value={formData.text1}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Манай хаяг:</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title2"
                                                                value={formData.title2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Утга:</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="text2"
                                                                value={formData.text2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>И-мэйл:</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title3"
                                                                value={formData.title3}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Утга:</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="text3"
                                                                value={formData.text3}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Google map :</label>
                                                            <textarea
                                                                rows="4"
                                                                className="form-control"
                                                                name="googleMap"
                                                                value={formData.googleMap}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <ContactActions {...{ _id, formData }} />
                                            </form>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
