import React, { useContext, useState, useEffect, useRef } from "react";
import css from "./style.module.css";
import rightArrow from "../../../assets/images/subMenu/Icon.png";
import GlobalContext from "../../../context/GlobalContext";
import PreOrderContext from "../../../context/PreOrderContext";
import ProductContext from "../../../context/ProductContext";
import { useNavigate } from "react-router-dom";
import searchIcon from "../../../assets/images/basket/CloseButton.png";

import ScheduleItem from "./schedulesItem";

//alert Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import calendarIcon from "../../../assets/images/subMenu/newCalendar.png"
import truckIcon from "../../../assets/images/subMenu/newTruck.png"

const SubMenu = () => {
  //states
  const [schedules, setSchedules] = useState(false);
  const [searchSchedule, setSeachSchedule] = useState(null);
  //contexts
  const globalCtx = useContext(GlobalContext);
  const preOrderCtx = useContext(PreOrderContext);
  const productCtx = useContext(ProductContext);

  //refs
  const preOrderClickRef = useRef();
  //navigate
  const navigate = useNavigate();

  const viewThree = () => {
    if (!globalCtx.state.threeView) {
      globalCtx.toggleThreeView();
    }
  };
  const viewFour = () => {
    if (!globalCtx.state.fourView) {
      globalCtx.toggleThreeView();
    }
  };
  const redirectToHome = () => {
    navigate("/");
  };
  const redirectToCalendar = () => {
    navigate("/calendar");
  };
  const closeSchedules = () => {
    setSchedules(false);
  };
  const openSchedules = () => {
    setSchedules(true);
  };
  const selectedSchedule = (value) => {
    setSeachSchedule(value);
  };
  const toPreOrderPage = () => {
    if (searchSchedule) {
      setSeachSchedule(null);
      globalCtx.setpreOrderSorted(searchSchedule);
      closeSchedules();
      productCtx.getAllPreProductProduct(searchSchedule);
      // navigate("/category");
    }
  };

  const searchProduct = (event) => {
    globalCtx.setSearchValue(event.target.value);
  };
  useEffect(() => {
    preOrderCtx.getPreOrderSchedule();
  }, []);

  useEffect(() => {
    if (globalCtx.state.activeCategory === "Урьдчилсан") {
    }
  }, [globalCtx.state.activeCategory]);

  return (
    <div className={css.SubMenu}>
      {/* <div className={css.Direction}>
        <button
          onClick={() => {
            redirectToHome();
          }}
        >
          Нүүр
        </button>
        <img src={rightArrow} alt="arrow" />
        <button>{globalCtx.state.activeCategory}</button>
      </div> */}
      <div className={css.SubMenuDelivery}>
        <div className={css.Plan}>
          <button
            onClick={redirectToCalendar}
            className={css.calendarButton}
          >
            <img src={calendarIcon} alt="Calendar" className={css.icon} />
            Төлөвлөгөө
          </button>
        </div>
        <div className={css.Dilivery}>
          <button
            ref={preOrderClickRef}
            className={css.deliveryCar}
            onClick={() => {
              openSchedules();
            }}
          >
            <img src={truckIcon} alt="Calendar" className={css.icon} />
            Урьдчилсан захиалга
          </button>
        </div>
      </div>
      <div className={css.Search}>
        <input
          onChange={searchProduct}
          type="text"
          name="search"
          placeholder="Хайлт хийх"
          value={globalCtx.state.searchValue}
        />
        {globalCtx.state.searchValue !== "" && (
          <img
            className={css.searchIcon}
            src={searchIcon}
            alt="logo"
            onClick={() => {
              globalCtx.clearSearchValue();
            }}
          />
        )}
        
      </div>
      <div className={css.Search}>
             <div className={css.Dilivery}>
          <button
            ref={preOrderClickRef}
            className={css.deliveryCar}
            onClick={() => {
              openSchedules();
            }}
          >
            <img src={truckIcon} alt="Calendar" className={css.icon} />
            Үнийн санал татах
          </button>
        </div>
      </div>
      <Dialog open={schedules} onClose={closeSchedules}>
        <DialogTitle>Бараа буух өдөр </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              gap: "17px",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              minWidth: "500px",
            }}
          >
            {preOrderCtx.state.preOrders.map((el, index) =>
              searchSchedule === el.start ? (
                <ScheduleItem
                  Schedule={el}
                  active={true}
                  key={index}
                  selectedValue={selectedSchedule}
                />
              ) : (
                <ScheduleItem
                  Schedule={el}
                  active={false}
                  key={index}
                  selectedValue={selectedSchedule}
                />
              )
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toPreOrderPage}>Үргэжлүүлэх</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubMenu;
