import React, { useContext, useState, useEffect, useMemo } from "react";
import Image from "mui-image";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import grey from "@mui/material/colors/grey";
import CheckAction from "./PreOrderAction";
import BlogContext from "../../../context/BlogContext";
import OrderContext from "../../../context/OrderContext";
import { gridClasses } from "@mui/system";


export default function BlogPage() {
  const ordContext = useContext(OrderContext)
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);

  const [isCreate, setIsCreate] = useState(false);
  const [isViewList, setIsViewList] = useState(true);
  const columns = useMemo(
    () => [
      { field: "_id", hide: true },
      {
        field: "createdAt",
        headerName: "Гүйлгээ хийсэн огноо",
        width: 180,
        renderCell: (params) =>
          moment(params.row.createdAt).format("YYYY-MM-DD HH:MM:SS"),
      },
      {
        field: "photo",
        headerName: "Цэцэг зураг",
        width: 350,
        renderCell: (params) => (
          <Image
            src={
              process.env.REACT_APP_BASE_URL +
              "/public/image/blog/" +
              params.row.photo
            }
          />
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: "name",
        headerName: "Нэр",
        width: 180,
      },
      {
        field: "code",
        headerName: "Код",
        width: 280,
        flex: 1,
      },
      {
        field: "orderTotalPrice",
        headerName: "Нийт төлөх дүн",
        width: 380,
        flex: 1,
      },
      {
        field: "bonusEarned",
        headerName: "Ашигласан хөнгөлөлт",
        width: 380,
        editable: true,
        flex: 1,
      },
      {
        field: "orderDescription",
        headerName: "Гүйлгээний утга",
        width: 380,
        flex: 1,
      },
      {
        field: "createUser",
        headerName: "Хэрэглэгч",
        width: 380,
        flex: 1,
      },
      {
        field: "action",
        headerName: "Үйлдэл",
        width: 380,
        flex: 1,
        renderCell: (params) => (
          <CheckAction {...{ params, rowId, setRowId }} />
        ),
      },

    ],
    [rowId]
  );
  useEffect(() => {
    ordContext.failedOrders();
    console.log(ordContext.state.myOrders);
  }, []);

  return (
    <div className="content-wrapper">
      {isViewList && (
        <div className="content-header ml-2 d-inline-flex">
          <h1>Урьдчилсан захиалга баталгаажуулах</h1>
        </div>
      )}
      {isCreate && <div className="content-header"></div>}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div
                  className="card-header border-0"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="d-flex justify-content-center">
                    {isViewList && (
                      <Box sx={{ height: 730, width: "100%" }}>
                        <Typography
                          variant="h3"
                          component="h3"
                          sx={{ textAlign: "center", mt: 3, mb: 3 }}
                        />
                        <DataGrid
                          rowHeight={220}
                          columns={columns}
                          rows={ordContext.state.myOrders}
                          getRowId={(row) => row._id}
                          rowsPerPageOptions={[5, 10, 20]}
                          pageSize={pageSize}
                          onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                          }
                          getRowSpacing={(params) => ({
                            top: params.isFirstVisible ? 0 : 5,
                            bottom: params.isLastVisible ? 0 : 5,
                          })}
                          sx={{
                            [`& .${gridClasses.row}`]: {
                              bgcolor: grey[200],
                            },
                          }}
                          onCellEditCommit={(params) => setRowId(params.id)}
                        />
                      </Box>
                    )}
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
