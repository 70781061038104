import React, { useState, useEffect } from "react";
import axios from "../axios/axios-for-backend";

const CalendarContext = React.createContext();

const initialState = {
    days: [],
    loading: false
};
export const CalendarStore = (props) => {
    const [state, setState] = useState(initialState);

    const calendar = () => {
        axios
            .get("calendar")
            .then((result) => {
                setState({ ...state, days: result.data.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        calendar();
    }, []);
    const deleteCalendar = async (calendarID) => {
        // console.log(calendarID)
        const res = await axios
            .delete("calendar/" + calendarID)
            .then((result) => {
                return result;
            })
            .catch((err) => console.log(err));
        return res;
    };

    const createCalendar = (startDate, endDate, title) => {
        const newEndDate = new Date(endDate);
        newEndDate.setDate(newEndDate.getDate() + 1);
        var formData = new FormData();
        formData.append("start", startDate);
        formData.append("end", newEndDate);
        formData.append("title", title);
        axios
            .post("calendar", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => {
                calendar();
            })
            .catch((err) => {
                console.log(err);
                setState({
                    error: err.response.data.error.message,
                });
            });
    };

    return (
        <CalendarContext.Provider
            value={{
                state,
                deleteCalendar,
                createCalendar,
                calendar,
            }}
        >
            {props.children}
        </CalendarContext.Provider>
    );
};
export default CalendarContext;
