import React, { useState, useEffect } from "react";
import axios from "../axios/axios-for-backend";

const HelperContext = React.createContext();

const initialState = {
    categories: [],
    loading: false
};
export const HelperStore = (props) => {
    const [state, setState] = useState(initialState);

    const Helper = () => {
        axios
            .get("helpers")
            .then((result) => {
                setState({ ...state, categories: result.data.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        Helper();
    }, []);

    const updateHelper = async (id, passForm, changePhoto) => {
   
        var formData = new FormData();
        formData.append("passForm", JSON.stringify(passForm));
        // formData.append("text1", text1);
        if (changePhoto) {
            formData.append("file", changePhoto);
        }
        setState({ ...state, loading: true });
        const res = await axios
            .put(`helpers/${id}`, formData)
            .then((result) => {
                setState({ ...state, success: true, loading: false });
                return result;
            })
            .catch((err) => {
                setState({ ...state, loading: false });
                console.log(err);
            });
        return res;
    };

    return (
        <HelperContext.Provider
            value={{
                state,
                Helper,
                updateHelper,
            }}
        >
            {props.children}
        </HelperContext.Provider>
    );
};
export default HelperContext;
