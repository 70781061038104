import React, { useState, useContext, useEffect } from "react";
import css from "./style.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import BlogContext from "../../context/BlogContext";
import noImageIcon from "../../assets/images/product/no-img.png";

const BlogPage = () => {
  //states
  const { blogId } = useParams().id;
  const [activeBlogId, setActiveBlogId] = useState(useParams().id);
  //Contexts
  const blogCtx = useContext(BlogContext);
  const navigate = useNavigate();

  //effect
  useEffect(() => {
    blogCtx.getBlogs();
  }, []);

  // Function to handle changing the active blog
  const changeBlog = (index) => {
    setActiveBlogId(index);
  };
  const getFirst4Words = (content) => {
    const words = content.split(" ");
    return words.slice(0, 7).join(" ");
  };
  const getFirst10Words = (content) => {
    const words = content.split(" ");
    return words.slice(0, 8).join(" ");
  };

  return (
    <div className={css.BlogPage}>
      <div className={css.Container}>
        {blogCtx.state.blogs.length > 0 ? (
          <div className={css.itemBlog}>
            <img
              className={css.slideImage}
              src={
                blogCtx.state.blogs[activeBlogId].photo !== "no-photo.jpg"
                  ? process.env.REACT_APP_BASE_URL +
                  "/public/image/blog/" +
                  blogCtx.state.blogs[activeBlogId].photo
                  : noImageIcon
              }
              alt="orderImage"
            />
            <p className={css.Title}>
              {blogCtx.state.blogs[activeBlogId].title}
            </p>
            <p className={css.contentText}>{blogCtx.state.blogs[activeBlogId].content}</p>
          </div>
        ) : (
          <div className={css.itemBlog}>
            <p>Шинэ мэдээ хараахан ороогүй байна.</p>
          </div>
        )}

        {blogCtx.state.blogs.length > 0 && (
          <div className={css.subBlog}>
            <div className={css.subTitle}>
              <div>Шинэ мэдээ</div>
            </div>
            <div className={css.gridScroll}>
              {blogCtx.state.blogs.map((el, index) => (
                <Link
                  to={`/blog/${index}`}
                  className={css.blogLink}
                  onClick={() => {
                    changeBlog(index);
                  }}
                  key={el._id}
                >
                  <div className={css.blogMeta} key={index}>
                    <div className={css.subImage}>
                      <img
                        className={css.img}
                        src={
                          el.photo !== "no-photo.jpg"
                            ? process.env.REACT_APP_BASE_URL +
                            "/public/image/blog/" +
                            el.photo
                            : noImageIcon
                        }
                        alt="orderImage"
                      />
                    </div>
                    <div className={css.meta}>
                      <h5>{getFirst4Words(el.title)}</h5>
                      <p>{getFirst10Words(el.content)}...</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPage;
