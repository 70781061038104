import React, { useContext, useState, useEffect, useMemo } from "react";
import Image from "mui-image";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import grey from "@mui/material/colors/grey";
import green from "@mui/material/colors/green";

import { Add } from "@mui/icons-material";
import { Fab } from "@mui/material";
import SliderContext from "../../../context/SlideContext";
import BannerActions from "./BannerActons";
import { gridClasses } from "@mui/system";
import CreateBanner from "./CreateBanner";

export default function BannerPage() {
  //contexts
  const swipeCtx = useContext(SliderContext);

  //states
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);

  const [isCreate, setIsCreate] = useState(false);
  const [isViewList, setIsViewList] = useState(true);

  const handleCreate = async () => {
    setIsViewList(false);
    setIsCreate(true);
  };

  const handleViews = async (handleViews) => {
    if (handleViews === "createBanner") {
      setIsViewList(true);
      setIsCreate(false);
    }
  };
  const columns = useMemo(
    () => [
      { field: "_id", hide: true },
      // {
      //   field: "createdAt",
      //   headerName: "Үүсгэсэн огноо",
      //   width: 250,
      //   renderCell: (params) =>
      //     moment(params.row.createdAt).format("YYYY-MM-DD HH:MM:SS"),
      // },
      {
        field: "photo",
        headerName: "Зураг",
        width: 350,
        renderCell: (params) => (
          <Image
            src={
              process.env.REACT_APP_BASE_URL +
              "/public/image/banner/" +
              params.row.photo
            }
          />
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: "link",
        headerName: "Үсрэх линк",
        width: 280,
        editable: true,
      },
      {
        field: "isActive",
        headerName: "Стасус",
        width: 280,
        type: "boolean",
        editable: true,
      },
      {
        field: "actions",
        width: 250,
        headerName: "Үйлдэл",
        type: "actions",
        renderCell: (params) => (
          <BannerActions {...{ params, rowId, setRowId }} />
        ),
      },
    ],
    [rowId]
  );
  useEffect(() => {
    swipeCtx.getBanners();
  }, []);

  return (
    <div className="content-wrapper">
      {isViewList && (
        <div className="content-header ml-2 d-inline-flex">
          <h1>Баннер</h1>
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
              marginLeft: 2,
              bgcolor: green[500],
              "&:hover": { bgcolor: green[700] },
            }}
            onClick={handleCreate}
          >
            <Add />
          </Fab>
        </div>
      )}
      {isCreate && <div className="content-header"></div>}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div
                  className="card-header border-0"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="d-flex justify-content-center">
                    {isViewList && (
                      <Box sx={{ height: 730, width: "100%" }}>
                        <Typography
                          variant="h3"
                          component="h3"
                          sx={{ textAlign: "center", mt: 3, mb: 3 }}
                        />
                        <DataGrid
                          rowHeight={220}
                          columns={columns}
                          rows={swipeCtx.state.sliders}
                          getRowId={(row) => row._id}
                          rowsPerPageOptions={[5, 10, 20]}
                          pageSize={pageSize}
                          onPageSizeChange={(newPageSize) =>
                            setPageSize(newPageSize)
                          }
                          getRowSpacing={(params) => ({
                            top: params.isFirstVisible ? 0 : 5,
                            bottom: params.isLastVisible ? 0 : 5,
                          })}
                          sx={{
                            [`& .${gridClasses.row}`]: {
                              bgcolor: grey[200],
                            },
                          }}
                          onCellEditCommit={(params) => setRowId(params.id)}
                        />
                      </Box>
                    )}
                    {isCreate && <CreateBanner handleViews={handleViews} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
