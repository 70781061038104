import React, { useState, useEffect } from "react";
import axios from "../axios/axios-for-backend";

const AboutUsContext = React.createContext();

const initialState = {
    categories: [],
    loading: false
};
export const AboutUsStore = (props) => {
    const [state, setState] = useState(initialState);

    const AboutUs = () => {
        axios
            .get("aboutus")
            .then((result) => {
                setState({ ...state, categories: result.data.data });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        AboutUs();
    }, []);

    const updateAboutUs = async (id, aboutData, changePhoto) => {

        var formData = new FormData();
        formData.append("id", id);
        formData.append("text", aboutData.about);
        formData.append("title1", aboutData.title1);
        formData.append("title2", aboutData.title2);
        formData.append("title3", aboutData.title3);
        formData.append("title4", aboutData.title4);
        formData.append("title5", aboutData.title5);
        formData.append("context1", aboutData.context1);
        formData.append("context2", aboutData.context2);
        formData.append("context3", aboutData.context3);
        formData.append("context4", aboutData.context4);
        formData.append("context5", aboutData.context5);

        if (changePhoto) {
            formData.append("file1", changePhoto);
        }
        // if (changePhoto2) {
        //     formData.append("file2", changePhoto2);
        // }
        setState({ ...state, loading: true });
        const res = await axios
            .put(`aboutus/${id}`, formData)
            .then((result) => {
                setState({ ...state, success: true, loading: false });
                return result;
            })
            .catch((err) => {
                setState({ ...state, loading: false });
                console.log(err);
            });
        return res;
    };

    return (
        <AboutUsContext.Provider
            value={{
                state,
                AboutUs,
                updateAboutUs,
            }}
        >
            {props.children}
        </AboutUsContext.Provider>
    );
};
export default AboutUsContext;
