import React, { useState } from "react";
import axios from "../axios/axios-for-backend";
import axiosTransaction from "../axios/axios-for-transaction";

const OrderContext = React.createContext();
const initialState = {
  myOrders: [],
  loading: false,
};
export const MyOrderStore = (props) => {
  const [state, setState] = useState(initialState);

  const addOrder = async (
    bonusEarned,
    orderTotalPrice,
    randomNumber,
    deliveryStatus,
    deliveryAddress,
    deliveryType,
    orderExtraItem,
    pre_order,
    paymentType
  ) => {
    const res = await axios
      .post("orders/", {
        bonusEarned : bonusEarned,
        orderTotalPrice: orderTotalPrice,
        orderDescription: randomNumber,
        deliveryStatus: deliveryStatus,
        deliveryAddress: deliveryAddress,
        deliveryType: deliveryType,
        orderExtraItem: orderExtraItem,
        pre_order: pre_order,
        paymentType: paymentType,
      })
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log(err);
      });
    return res;
  };

  
  const createLogOrder = async (
    bonusEarned,
    orderTotalPrice,
    randomNumber,
    deliveryStatus,
    deliveryAddress,
    deliveryType,
    orderExtraItem,
    pre_order,
    paymentType
  ) => {
    const res = await axios
      .post("orders/createLogOrder", {
        bonusEarned: bonusEarned,
        orderTotalPrice: orderTotalPrice,
        orderDescription: randomNumber,
        deliveryStatus: deliveryStatus,
        deliveryAddress: deliveryAddress,
        deliveryType: deliveryType,
        orderExtraItem: orderExtraItem,
        pre_order: pre_order,
        paymentType: paymentType,
      })
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log(err);
      });
    return res;
  };

  const deleteOrder = (order_id) => {
    axios
      .delete("orders/" + order_id)
      .then((result) => {
        // getAllOrder();
        failedOrders()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateOrder = async (form) => {
    axios
      .put("orders/" + form.id, {
        // deliveryType: form.deliveryType,
        deliveryType: form.deliveryType,
      })
      .then((result) => {
        if (form.type === "Урьдчилсан") {
          getAllPreOrders();
        } else {
          getAllOrder();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllOrder = () => {
    axios
      .get("orders")
      .then((result) => {
        setState({
          ...state,
          myOrders: result.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllPreOrders = async () => {
    const res = await axios
      .get("orders/preOrders")
      .then((result) => {
        setState({
          ...state,
          myOrders: result.data.data,
        });
        return result;
      })
      .catch((err) => {
        return result.data.error.message;
      });
    return res;
  };

  const preOrder = async() => {
    const res = await axios
    .get("orders/failedOrders") 
    .then((result)=>{
      console.log(result.data.data);
      setState({
        ...state, 
        myOrders:result.data.data,
      })
      return result;
    })
    .catch((err) =>{
      return result.data.error.message;
    })
    return res;
  }

  const failedOrders = async() => {
    const res = await axios
    .get("orders/failedOrders") 
    .then((result)=>{
      console.log(result.data.data);
      setState({
        ...state, 
        myOrders:result.data.data,
      })
      return result;
    })
    .catch((err) =>{
      return result.data.error.message;
    })
    return res;
  }

  const getBankTransaction = async (transaction_description) => {
    const res = await axiosTransaction
      .get("golomt/" + transaction_description)
      .then((result) => {
        return result.data.data;
      })
      .catch((err) => {
        return err.response.data.error.message;
      });
    return res;
  };
  const clearOrderData = () => {
    setState(initialState);
  };
  return (
    <OrderContext.Provider
      value={{
        state,
        addOrder,
        deleteOrder,
        getAllOrder,
        updateOrder,
        getAllPreOrders,
        getBankTransaction,
        clearOrderData,
        createLogOrder,
        failedOrders,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};
export default OrderContext;
