import React, { useState, useContext, useEffect } from "react";

import { Box, CircularProgress, Fab } from "@mui/material";

import { Check, Delete, Save } from "@mui/icons-material";

import green from "@mui/material/colors/green";
import AlertDialog from "../../../components/Admin/AlertDialog";

import BlogContext from "../../../context/BlogContext";

export default function BlogActions({ params, rowId, setRowId }) {
  //contexts
  const blogCtx = useContext(BlogContext);
  //states

  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const { title, content, _id } = params.row;
    const result = await blogCtx.updateBlog({ title, content }, _id);
    if (result) {
      setSuccess(true);
      setRowId(null);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setOpenAlert(true);
  };

  const agree = async () => {
    setOpenAlert(false);
    const result = await blogCtx.deleteBlog(params.id);
    if (result) {
      blogCtx.getBlogs();
    }
  };
  const disAgree = () => {
    setOpenAlert(false);
  };
  useEffect(() => {
    if (rowId === params.id && success) {
      setSuccess(false);
    }
  }, [rowId]);
  return (
    <>
      <Box sx={{ m: 1, position: "relative" }}>
        {success ? (
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
              bgcolor: green[500],
              "&:hover": { bgcolor: green[700] },
            }}
          >
            <Check />
          </Fab>
        ) : (
          <>
            <Fab
              color="primary"
              sx={{
                width: 40,
                height: 40,
              }}
              disabled={params.id !== rowId || loading}
              onClick={handleSubmit}
            >
              <Save />
            </Fab>
            <Fab
              color="primary"
              sx={{
                marginLeft: 1,
                width: 40,
                height: 40,
              }}
              disabled={false}
              onClick={handleDelete}
            >
              <Delete />
            </Fab>
          </>
        )}
        {loading && (
          <CircularProgress
            size={52}
            sx={{
              color: green[500],
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
      <AlertDialog
        openAlert={openAlert}
        agree={agree}
        disAgree={disAgree}
        title={"Та блог устгахдаа итгалтэй байна уу?"}
        body={"Хэрэв блогыг устгавал уг блогын мэдээлэл устах болно."}
      />
    </>
  );
}
