import React, { useState, useContext, useEffect } from "react";

import { Box, CircularProgress, Fab, Modal, Typography } from "@mui/material";

import { AddCircle, Check, Edit, RemoveCircle } from "@mui/icons-material";

import green from "@mui/material/colors/green";

import pauseOrderCtx from "../../../context/PausePreOrderContext";

export default function BannerActions({ params, rowId, setRowId }) {
  // const addProduct = async (id, scheduleDate, code) => {
  //   pauseCtx.updatePreProduct(id);
  // };

  useEffect(() => {
    pauseCtx.getPreProducts();
  }, []);

  const pauseCtx = useContext(pauseOrderCtx);
  const dataArray = pauseCtx.state.Products.map(item => item.ItemId);
  const dataTime = pauseCtx.state.Products.map(item => item.ScheduledDate.slice(0, 10));

  const groupedDates = dataTime.reduce((map, date) => {
    if (map.has(date)) {
      map.get(date);
    } else {
      map.set(date);
    }
    return map;
  }, new Map());
  

  if (dataArray.includes(params.row.Id) && dataTime.includes(params.row.ScheduledDate.slice(0, 10))  ) {
    console.log("asdf",params.row);
    return (
      <Box sx={{ m: 1, position: "relative" }}>
      <Fab
        color="primary"
        sx={{
          width: 40,
          height: 40,
          bgcolor: green[500],
          "&:hover": { bgcolor: green[700] },
        }}
        onClick={() => {
          pauseCtx.updatePreProduct(
            params.row.Id,
            params.row.ScheduledDate,
            params.row.Code,
            params.row.Qty
          );
        }}
      >
        <RemoveCircle />
      </Fab>
    </Box>
    );
  } else {
    return (
      <Box sx={{ m: 1, position: "relative" }}>
      <Fab
        color="primary"
        sx={{
          width: 40,
          height: 40,
          bgcolor: green[500],
          "&:hover": { bgcolor: green[700] },
        }}
        onClick={() => {
          pauseCtx.addStopProducts(
            params.row.Id,
            params.row.ScheduledDate,
            params.row.Code,
            params.row.Qty
          );
        }}
      >
        <AddCircle />
      </Fab>
    </Box>
    );
  }
}
