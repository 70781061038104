import React, { useContext, useState, useEffect, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import grey from "@mui/material/colors/grey";
import CategoryContext from "../../../context/CategoyContext";
import CategoryActions from "./CategoryActions";
import { gridClasses } from "@mui/system";
import Spinner from "../../../components/Users/General/Spinner";

export default function CategoryPage() {
  //contexts
  const categoryCtx = useContext(CategoryContext);

  //states
  const [pageSize, setPageSize] = useState(15);
  const [rowId, setRowId] = useState(null);

  const columns = useMemo(
    () => [
      { field: "_id", hide: true },
      {
        field: "createdAt",
        headerName: "Үүсгэсэн огноо",
        width: 250,
        renderCell: (params) =>
          moment(params.row.createdAt).format("YYYY-MM-DD HH:MM:SS"),
      },
      {
        field: "name",
        headerName: "Нэр",
        width: 280,
      },
      {
        field: "isActive",
        headerName: "Стасус",
        width: 280,
        type: "boolean",
        editable: true,
      },
      {
        field: "actions",
        width: 250,
        headerName: "Үйлдэл",
        type: "actions",
        renderCell: (params) => (
          <CategoryActions {...{ params, rowId, setRowId }} />
        ),
      },
    ],
    [rowId]
  );
  useEffect(() => {
    categoryCtx.getSubCategoryAdmin();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header ml-2 d-inline-flex">
        <h1>Категори</h1>
      </div>
      <div className="content">
        {categoryCtx.state.loading && <Spinner />}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div
                  className="card-header border-0"
                  style={{ marginBottom: "30px" }}
                >
                  <div className="d-flex justify-content-center">
                    <Box sx={{ height: 730, width: "100%" }}>
                      <Typography
                        variant="h3"
                        component="h3"
                        sx={{ textAlign: "center", mt: 3, mb: 3 }}
                      />
                      <DataGrid
                        rowHeight={90}
                        columns={columns}
                        rows={categoryCtx.state.subCategories}
                        getRowId={(row) => row._id}
                        rowsPerPageOptions={[5, 10, 20]}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        getRowSpacing={(params) => ({
                          top: params.isFirstVisible ? 0 : 5,
                          bottom: params.isLastVisible ? 0 : 5,
                        })}
                        sx={{
                          [`& .${gridClasses.row}`]: {
                            bgcolor: grey[200],
                          },
                        }}
                        onCellEditCommit={(params) => setRowId(params.id)}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
