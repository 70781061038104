import React, { useState, useContext, useEffect } from "react";

import { Box, CircularProgress, Fab } from "@mui/material";

import { Check, Delete, Save, Key } from "@mui/icons-material";

import green from "@mui/material/colors/green";
import AlertDialog from "../../../components/Admin/AlertDialog";

import UserContext from "../../../context/UserContext";
import AdminUserContext from "../../../context/admin/AdminUserContext";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

export default function UserActions({ params, rowId, setRowId }) {
  //contexts
  const adminUserCtx = useContext(AdminUserContext);
  const userCtx = useContext(UserContext);

  //states
  const [openAlert, setOpenAlert] = useState(false);
  const [openPassChange, setOpenPassChange] = useState(false);
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const { name, isActive, bonusPoint, bonusLevel, companyId, _id } =
      params.row;
    const result = await adminUserCtx.updateUser(
      { name, isActive, bonusPoint, bonusLevel, companyId },
      _id
    );
    if (result) {
      setSuccess(true);
      setRowId(null);
    }
    setLoading(false);
  };
  //delete user
  const handleDelete = async () => {
    setOpenAlert(true);
  };
  const handlePasswordChange = async () => {
    setOpenPassChange(true);
  };

  const agree = async () => {
    setOpenAlert(false);
    const result = await userCtx.deleteUser(params.id);
    if (result) {
      adminUserCtx.getAlluser();
    }
  };
  const disAgree = () => {
    setOpenAlert(false);
  };

  //change password
  const fetchPassChange = async () => {
    await adminUserCtx.adminChangePassword(params.id, password);
    closePassChange();
  };
  const closePassChange = () => {
    setOpenPassChange(false);
  };
  const changePasswordInput = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };
  useEffect(() => {
    if (rowId === params.id && success) {
      setSuccess(false);
    }
  }, [rowId]);
  return (
    <>
      <Box sx={{ m: 1, position: "relative" }}>
        {success ? (
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
              bgcolor: green[500],
              "&:hover": { bgcolor: green[700] },
            }}
          >
            <Check />
          </Fab>
        ) : (
          <>
            <Fab
              color="primary"
              sx={{
                width: 40,
                height: 40,
              }}
              disabled={params.id !== rowId || loading}
              onClick={handleSubmit}
            >
              <Save />
            </Fab>
            <Fab
              color="primary"
              sx={{
                marginLeft: 1,
                width: 40,
                height: 40,
              }}
              disabled={false}
              onClick={handleDelete}
            >
              <Delete />
            </Fab>
            <Fab
              color="primary"
              sx={{
                marginLeft: 1,
                width: 40,
                height: 40,
              }}
              disabled={false}
              onClick={handlePasswordChange}
            >
              <Key />
            </Fab>
          </>
        )}
        {loading && (
          <CircularProgress
            size={52}
            sx={{
              color: green[500],
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
      <AlertDialog
        openAlert={openAlert}
        agree={agree}
        disAgree={disAgree}
        title={"Та хэрэглэгч устгахдаа итгалтэй байна уу?"}
        body={
          "Хэрэв хэрэглэгчийг устгавал уг хэрэглэгчийн мэдээлэл устах болно."
        }
      />
      <Dialog open={openPassChange} onClose={closePassChange}>
        <DialogTitle>Нууц үг солих</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tа хэрэглэгчийн нууц үгийг солих форм нээсэн байна. Нууц үг хамгийн
            багадаа 4-н тэмдэгт байна (Хуучин нууц үг хэрэглэгчийн email тэй
            адил утагтай байгаа).
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Шинэ нууц үг"
            type="text"
            fullWidth
            variant="standard"
            onChange={changePasswordInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closePassChange}>Болих</Button>
          <Button onClick={fetchPassChange}>Солих</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
