import React, { useContext, useState, useEffect, useMemo } from "react";
import css from "./style.module.css";
import Accordion from "react-bootstrap/Accordion";
import faqImag from "../../assets/images/auth/faq.png";
import HelperContext from "../../context/HelperContext";

const HelpPage = () => {
    let formData = {
        title1: '',
        text1: '',
        title2: '',
        text2: '',
        title3: '',
        text3: '',
        title4: '',
        text4: '',
    };
    useEffect(() => {
        helperCtx.Helper();
        window.scrollTo(0, 0)
    }, []);
    const helperCtx = useContext(HelperContext);
    if (helperCtx.state.categories[0] != undefined) {
        formData = {
            title1: helperCtx.state.categories[0].title1,
            text1: helperCtx.state.categories[0].text1,
            title2: helperCtx.state.categories[0].title2,
            text2: helperCtx.state.categories[0].text2,
            title3: helperCtx.state.categories[0].title3,
            text3: helperCtx.state.categories[0].text3,
            title4: helperCtx.state.categories[0].title4,
            text4: helperCtx.state.categories[0].text4,
        };
    }
    return (
        <div className={css.contain}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col d-flex justify-content-start">
                        <h2 className={css.AboutTitle}>Тусламж</h2>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col d-flex justify-content-start">
                        <h2 className={css.AboutTitle2}>Түгээмэл асуулт, хариулт</h2>
                    </div>
                </div>
                <div className={css.accordion}>
                    {/* <div className="col">
                    <img className={css.faqImage} src={process.env.REACT_APP_BASE_URL +
                        "/public/image/auth/" + "faq.png"} alt="Icon" /></div> */}
                    <Accordion className={css.accord} >
                        <div className={css.customAcc}>
                            <Accordion.Item eventKey="0" className={css.item}>
                                <Accordion.Header className={css.question}>
                                    <p>{formData.title1}</p>
                                </Accordion.Header>
                                <Accordion.Body className={css.answer}>
                                    {formData.text1}
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className={css.customAcc}>
                            <Accordion.Item eventKey="1" className={css.item}>
                                <Accordion.Header className={css.question}>
                                    <p>{formData.title2}</p>
                                </Accordion.Header>
                                <Accordion.Body className={css.answer}>
                                    {formData.text2}
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className={css.customAcc}>
                            <Accordion.Item eventKey="2" className={css.item}>
                                <Accordion.Header className={css.question}>
                                    <p>{formData.title3}</p>
                                </Accordion.Header>
                                <Accordion.Body className={css.answer}>
                                    {formData.text3}
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                        <div className={css.customAcc}>
                            <Accordion.Item eventKey="3" className={css.item}>
                                <Accordion.Header className={css.question}>
                                    <p>{formData.title4}</p>
                                </Accordion.Header>
                                <Accordion.Body className={css.answer}>
                                    {formData.text4}
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default HelpPage;
