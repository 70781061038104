import React, { useState } from "react";
// import axios from "../axios/axios-for-backend";

const GlobalContext = React.createContext();

const initialState = {
  threeView: false,
  fourView: true,
  showSubToolbar: true,
  activeCategory: "",
  activeID: "",
  preOrderSorted: "",
  searchValue: "",
};

export const GlobalStore = (props) => {
  const [state, setState] = useState(initialState);

  const toggleThreeView = () => {
    setState({
      ...state,
      threeView: !state.threeView,
      fourView: !state.fourView,
    });
  };
  const showSubToolbar = () => {
    setState({
      ...state,
      showSubToolbar: true,
    });
  };
  const hideSubToolbar = () => {
    setState({
      ...state,
      showSubToolbar: false,
    });
  };

  const setActiveCategory = (activeCategory, activeID) => {
    setState({
      ...state,
      activeCategory: activeCategory,
      activeID: activeID,
    });
  };

  const setpreOrderSorted = (startTime) => {
    setState({
      ...state,
      activeCategory: "Урьдчилсан",
      preOrderSorted: startTime,
    });
  };
  const setSearchValue = (value) => {
    setState({
      ...state,
      searchValue: value,
    });
  };
  const clearSearchValue = (value) => {
    setState({
      ...state,
      searchValue: "",
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        state,
        toggleThreeView,
        showSubToolbar,
        hideSubToolbar,
        setActiveCategory,
        setpreOrderSorted,
        setSearchValue,
        clearSearchValue,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
export default GlobalContext;
