import React, { useContext, useState, useEffect } from "react";
import css from "./style.module.css";
import BucketContext from "../../../context/BucketContext";
import productIncrement from "../../../assets/images/product/AddButton.png";
import productDecrement from "../../../assets/images/product/RemoveButton.png";
import deleteProductImage from "../../../assets/images/payment/DeleteButton.png";
import noImageIcon from "../../../assets/images/product/no-img.png";
import Trash from "../../../assets/images/payment/Trash Can.png";

const PaymentProductList = (props) => {
  const myBucketCtx = useContext(BucketContext);

  const [qty, setQty] = useState(props.order.Qty);
  useEffect(() => {
    setQty(props.order.Qty);
  }, [props.order.Qty]);

  const handleDecrement = () => {
    const updatedQty = qty + props.order.QtyPerpackage;
    const newQty = updatedQty >= 0 ? updatedQty : 0;
    myBucketCtx.updateOneBucket({ ...props.order, Qty: newQty }, false);
    setQty(newQty);
  };

  const handleIncrement = () => {
    const newQty = qty - props.order.QtyPerpackage;
    myBucketCtx.updateOneBucket({ ...props.order, Qty: newQty }, true);
    setQty(newQty);
  };
  const handleDelete = (id) => {
    myBucketCtx.deleteBucket(id);
    props.sendDataToParent(id);
  }

  return (
    <div className={css.PaymentProductList}>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg">
          <div className={css.photoCard}>
            {props.order.image !== "no-photo.jpg" ? (
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  "/public/image/products/" +
                  props.order.image
                }
                alt="сагс"
              />
            ) : (
              <img className={css.photoCard} src={noImageIcon} alt="сагс" />
            )}
          </div>
        </div>

        <div className={`col-sm-12 col-md-6 col-lg ${css.phoneWidth}`}
          style={{ textAlign: "center" }}>
          <div className="col" style={{ textAlign: "start" }}>
            <p className={css.ProductName}>{props.order.categoryName}</p>
            <p>
              <span className={css.topNormalText}>Код:</span>
              <span className={css.topBoldText}>{props.order.code}</span>{" "}
              <span className={css.topBoldText}
                style={{
                  fontWeight: "700",
                  color: "#FF4F58",
                  fontSize: "15px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                }}>
                ({props.order.name})
              </span>
            </p>
          </div>
          <div className="row">
            <div className="col " style={{ textAlign: "start" }}>
              <div className="col">
                <span className={css.topNormalText}>
                  {" "}
                  Үлдэгдэл:<span className={css.topBoldText}> {props.order.Qty}</span>
                </span>
              </div>
              <div className="col">
                <span className={css.topNormalText}>
                  {" "}
                  1 багцанд:
                  <span className={css.topBoldText}> {props.order.QtyPerpackage}ш</span>
                </span>
              </div>
            </div>
            <div className="col" style={{ textAlign: "end" }}>
              <div className="col">
                <span className={css.topNormalText}>Нэгж үнэ:</span>
              </div>
              <div className="col">
                <span className={css.topBoldText}>
                  ₮{props.order.listPrice.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
          <div className={`row ${css.ProductDescription}`}>
            <span style={{
              fontWeight: "400",
              fontSize: "15px",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              color: "#2A2C38",
            }}>
              Та{" "}
              <span style={{
                fontWeight: "700",
                color: "#FF4F58",
              }}>
                {props.order.WholeQty}
              </span>{" "}
              ширхэгээс дээш бараа авбал{" "}
              <span style={{
                fontWeight: "700",
                color: "#FF4F58",
              }}>
                {props.order.Percentage ? props.order.Percentage : "0"}
                %</span>{" "}
              хөнгөлөлтөнд хамрагдах боломжтой.
            </span>
          </div>
          <div className={`row ${css.contAdd}`}>
            <button
              onClick={() => { handleDecrement() }}
              className={css.btnMinus}>-</button>
            <p style={{
              fontWeight: "600",
              margin: "4px 10px 0 10px",
              fontSize: "14px",
            }}>
              {props.order.piece} багц
            </p>
            <button
              onClick={() => { handleIncrement() }}
              className={css.btnPlus}>+</button>
          </div>
          <div className={`row mt-2 ${css.phoneWidth}`}>
            <div className="col">
              <div className="row">
                <p style={{
                  color: "#2A2C38",
                  fontSize: "12px",
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontStyle: "normal",
                  paddingRight: "2px"
                }}>
                  {props.order.QtyPerpackage * props.order.piece} ширхэг
                </p>
                {props.order.Percentage !== 0 ? (
                  <p style={{
                    color: "#FF4F58",
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontStyle: "normal",
                    marginLeft: "12px",
                  }}>
                    Хөнгөлөгдсөн
                  </p>
                ) : (
                  <p
                    style={{
                      color: "#FF4F58",
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontStyle: "normal",
                    }}
                  >
                    Хөнгөлөгдөөгүй
                  </p>
                )}
              </div>
              <div className="row">
                <p
                  style={{
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      lineHeight: "16px",
                      marginTop: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    ₮
                    {(
                      props.order.QtyPerpackage *
                      props.order.piece *
                      props.order.listPrice
                    ).toLocaleString()}
                    ₮
                  </span>
                  {/* <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "14px",
                      marginLeft: "10px",
                      color: "#AEAFB5",
                      fontFamily: "Montserrat",
                    }}
                  >
                    <s>
                      ₮
                      {(
                        props.order.QtyPerpackage *
                        props.order.piece *
                        props.order.listPrice
                      ).toLocaleString()}
                      ₮
                    </s>
                  </span> */}
                </p>

                {/* <p style={{ margin: "0", fontSize: "14px" }}>
                  {props.order.QtyPerpackage * props.order.piece} ширхэг x{" "}
                  {props.order.listPrice}₮ ={" "}
                  {(
                    props.order.QtyPerpackage *
                    props.order.piece *
                    props.order.listPrice
                  ).toLocaleString()}
                  ₮
                </p> */}
              </div>
            </div>
            <div className="col">
              <button
                className={css.deleteButton}
                onClick={() => {
                  handleDelete(props.order._id)
                }}
              // onClick={() => {
              //   myBucketCtx.deleteBucket(props.order._id);
              // }}
              >
                <img className={css.deleteBtnIcons} src={Trash} alt="counter" />
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "22px",
                    fontFamily: "Montserrat",
                    color: "#2A2C38",
                    margin: "auto",
                  }}
                >
                  Устах
                </p>
              </button>
            </div>
          </div>
          {/* <p
            style={{
              fontSize: "14px",
            }}
          >
            1 багцанд:{" "}
            <span
              style={{
                color: "#ff4f58",
                fontWeight: "600",
                marginRight: "17px",
              }}
            >
              {props.order.QtyPerpackage} ш
            </span>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default PaymentProductList;
