import React, { useState } from "react";
import axios from "../../axios/axios-for-transaction";

const BankContext = React.createContext();

const initialState = {
  laoding: false,
  transactions: [],
};
export const BankStore = (props) => {
  const [state, setState] = useState(initialState);

  const getBankTransactions = () => {
    setState({ ...state, loading: true });
    axios
      .get("golomt")
      .then((result) => {
        setState({
          ...state,
          loading: false,
          transactions: result.data.bankTransaction,
        });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  };

  // const updateBankTransaction = async (body, userId) => {
  //   setState({ ...state, loading: true });
  //   const dondon = await axios
  //     .put("golomt/" + userId, body)
  //     .then((result) => {
  //       setState({ ...state, success: true, loading: false });
  //       return result;
  //     })
  //     .catch((err) => {
  //       setState({ ...state, loading: false });
  //       console.log(err);
  //     });
  //   return dondon;
  // };

  return (
    <BankContext.Provider value={{ state, getBankTransactions }}>
      {props.children}
    </BankContext.Provider>
  );
};
export default BankContext;
