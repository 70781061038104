import React, { useState, useContext, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";

import css from "./style.module.css";
import Logo from "../../../assets/images/LogoNew.png";
import LogoWhite from "../../../assets/images/LogoWhitee.png";
import facebook from "../../../assets/images/face.png";
import insta from "../../../assets/images/insta.png";
import youtube from "../../../assets/images/youtube.png";
import twitter from "../../../assets/images/twtit.png";
import MainCategoryContext from "../../../context/MainCategoryContext";
import GlobalContext from "../../../context/GlobalContext";

const Footer = () => {

  const navigate = useNavigate();
  const mainCategories = useContext(MainCategoryContext);
  const globalCtx = useContext(GlobalContext);

  useEffect(() => {
    mainCategories.mainCategoryActive();
  }, []);

  const redirectToCategory = (categoryName, activeID) => {
    globalCtx.setActiveCategory(categoryName, activeID);
    navigate(`/category`);
  };
  return (
    <div className={css.Footer}>
      <div className={css.logoIcons}>
        <img className={css.footerLogo1} src={Logo} alt="logo" />
        <div className={css.SocialIcons}>
          <img src={facebook} alt="logo" />
          <img src={twitter} alt="logo" />
          <img src={youtube} alt="logo" />
          <img src={insta} alt="logo" />
        </div>
      </div>

      <div className={css.Info}>
        <div>
          <div className={css.title}>Бүтээгдэхүүн</div>
          {mainCategories.state.categories.map((el) => (
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                redirectToCategory(
                  el.name,
                  el.dayan_id
                );
              }}
            >{el.name}</p>
          ))}
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              redirectToCategory("Хямдарсан", null);
            }}
          >Хямдралтай цэцэг</p>
          {/* <p>Нидерланд гоёлын цэцэг</p>
        <p>Гоёлын цэцэг</p>
        <p>Тасалгааны цэцэг</p>
        <p>Хатаасан цэцэг</p>
        <p>Туслах материал чимэглэлүүд</p>
        <p>Тэжээл бордоо</p>
        <p>Хямдралтай цэцэг</p> */}
        </div>
        <div>
          <div className={css.title}>Тусламж</div>
          <Link to="/blog"><p>Блог</p></Link>
          <Link to="/help"><p>Тусламж</p></Link>
          {/* <Link to="/about"><p>Үйлчилгээний нөхцөл</p></Link> */}
        </div>
        <div>
          <div className={css.title}>Компани</div>
          <Link to="/contact"><p>Холбоо барих</p></Link>
          <Link to="/about"><p>Бидний тухай</p></Link>
        </div>
      </div>

      {/* <div className={css.Info}>
      <div>
        <p className={css.textColor}>Тусламж</p>
        <p>Борлуулалт</p>
        <p>Түгээмэл асуудаг асуулт & хариулт</p>
        <p>Хэрхэн урьдчилсан захиалга хийх вэ?</p>
      </div>
      <div>
        <p className={css.textColor}>Манай хаяг</p>
        <p>Хануул дүүрэг, Үйлдвэрчний гудамж, 3-р хороо, Peacetown-2</p>
        <p className={css.textColor}>Утас</p>
        <p>(976) 7070-8080</p>
      </div>
    </div> */}

      <div className={css.lowest}>
        <img className={css.footerLogo} src={LogoWhite} alt="logo" />
        <p>© 2023 - Зохиогчийн эрх хамгаалагдсан</p>
      </div>
    </div>
  )
}

export default Footer;
