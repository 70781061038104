import React from "react";

import css from "./style.module.css";

const Spinner = () => (
  <div className={css.Shadow}>
    <div className={css.Loader}></div>
  </div>
);

export default Spinner;
