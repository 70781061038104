import React, { useState } from "react";
import axios from "../../axios/axios-for-transaction";

const DashboardContext = React.createContext();

const initialState = {
  initValue: {
    Blog: 0,
    BankTransaction: 0,
    Banner: 0,
    Order: 0,
    PreOrder: 0,
    Product: 0,
    PreProduct: 0,
    User: 0,
  },
  loading: false,
};
export const DashboardStore = (props) => {
  const [state, setState] = useState(initialState);

  const initDashboard = () => {
    setState({ ...state, loading: true });
    axios
      .get("dashboard")
      .then((result) => {
        setState({
          ...state,
          loading: false,
          initValue: {
            ...state.initValue,
            Blog: result.data.data.Blog,
            BankTransaction: result.data.data.BankTransaction,
            Banner: result.data.data.Banner,
            Order: result.data.data.Order,
            PreOrder: result.data.data.PreOrder,
            Product: result.data.data.Product,
            PreProduct: result.data.data.PreProduct,
            User: result.data.data.User,
          },
        });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
      });
  };

  return (
    <DashboardContext.Provider value={{ state, initDashboard }}>
      {props.children}
    </DashboardContext.Provider>
  );
};
export default DashboardContext;
