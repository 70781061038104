import React, { useContext } from "react";

import logo from "../../assets/images/logo.png";
import css from "./style.module.css";
import UserContext from "../../context/UserContext";

export default function Menu(props) {
  //contexts
  const userCtx = useContext(UserContext);
  return (
    <aside style={{
      width: "260px"
    }} className="main-sidebar sidebar-dark-primary elevation-4">
      <button
        className="brand-link"
        style={{
          padding: "20px 5px",
          backgroundColor: "#343a40",
          border: "none",
          color: "#c2c7d0",
          width: "100%",
          textAlign: "start",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          className={css.logoCustom}
          style={{ opacity: "0.8" }}
        />
        <span className="brand-text font-weight-light">Gardenia</span>
      </button>
      <div className={css.widthCus}>
        <div className="sidebar ">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.dashboard ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.dashboard
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("dashboard");
                  }}
                >
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>Хянах самбар</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.user ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.user
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("user");
                  }}
                >
                  <i className="nav-icon far fa-user" />
                  <p>Хэрэглэгчид</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.product ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.product
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("product");
                  }}
                >
                  <i className="nav-icon fas fa-seedling" />
                  <p>Нийт Бараа</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.preProduct ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.preProduct
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("preProduct");
                  }}
                >
                  <i className="nav-icon fab fa-pagelines" />
                  <p>Урьдчилсан Бараа</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.order ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.order
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("order");
                  }}
                >
                  <i className="nav-icon far fas fa-truck" />
                  <p>Захиалга</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.check ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.check
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("check");
                  }}
                >
                  <i className="nav-icon fas fa-phone" />
                  <p>Амжилтгүй захиалга</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.preOrder ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.preOrder
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("preOrder");
                  }}
                >
                  <i className="nav-icon fas fa-boxes" />
                  <p>Урьдчилсан захиалга</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.blog ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.blog
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("blog");
                  }}
                >
                  <i className="nav-icon fas fa-pen-alt" />
                  <p>Блог</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.banner ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.banner
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("banner");
                  }}
                >
                  <i className="nav-icon fas fa-ad" />
                  <p>Баннер</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.bank ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.bank
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("bank");
                  }}
                >
                  <i className="nav-icon fas fa-money-bill" />
                  <p>Гүйлгээ</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.category ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.category
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("category");
                  }}
                >
                  <i className="nav-icon fas fa-stream" />
                  <p>Категори</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.mainCategory ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.mainCategory
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("mainCategory");
                  }}
                >
                  <i className="nav-icon fas fa-stream" />
                  <p>Main категори</p>
                </button>
              </li>

              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.help ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.help
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("help");
                  }}
                >
                  <i className="nav-icon fas fa-question-circle" />
                  <p>Тусламж</p>
                </button>
              </li>
              {/* <li className="nav-item">
              <button
                className={
                  !props.activeMenu.helpBanner ? "nav-link" : "nav-link active"
                }
                style={
                  !props.activeMenu.helpBanner
                    ? {
                      backgroundColor: "#343a40",
                      border: "none",
                      color: "#c2c7d0",
                      width: "100%",
                      textAlign: "start",
                    }
                    : {
                      backgroundColor: "#ff4f58",
                      border: "none",
                      color: "#c2c7d0",
                      width: "100%",
                      textAlign: "start",
                    }
                }
                onClick={() => {
                  props.setPageContent("helpBanner");
                }}
              >
                <i className="nav-icon fas fa-stream" />
                <p>Туслах баннер</p>
              </button>
            </li> */}
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.calendar ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.calendar
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("calendar");
                  }}
                >
                  <i className="nav-icon fas fa-stream" />
                  <p>Календар</p>
                </button>
              </li>

              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.helpBanner ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.helpBanner
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("helpBanner");
                  }}
                >
                  <i className="nav-icon fas fa-flag" />
                  <p>Туслах баннер</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.about ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.about
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("about");
                  }}
                >
                  <i className="nav-icon fas fa-info-circle" />
                  <p>Бидний тухай</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.contact ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.contact
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("contact");
                  }}
                >
                  <i className="nav-icon fas fa-phone" />
                  <p>Холбоо барих</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    !props.activeMenu.equal ? "nav-link" : "nav-link active"
                  }
                  style={
                    !props.activeMenu.equal
                      ? {
                        backgroundColor: "#343a40",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                      : {
                        backgroundColor: "#ff4f58",
                        border: "none",
                        color: "#c2c7d0",
                        width: "100%",
                        textAlign: "start",
                      }
                  }
                  onClick={() => {
                    props.setPageContent("equal");
                  }}
                >
                  <i className="nav-icon fas fa-equals" />
                  <p>Үлдэгдэл тэнцүүлэх</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  style={{
                    backgroundColor: "#343a40",
                    border: "none",
                    color: "#c2c7d0",
                    width: "100%",
                    textAlign: "start",
                  }}
                  onClick={() => {
                    userCtx.logout();
                  }}
                >
                  <i className="nav-icon fas fa-lock-open" />
                  <p>Гарах</p>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
}
