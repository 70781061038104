import React, { useContext, useState, useEffect, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import grey from "@mui/material/colors/grey";
import AboutUsContext from "../../../context/AboutUsContext";
import AboutActions from "./AboutActions";
import { gridClasses } from "@mui/system";
import Image from "mui-image";
import Spinner from "../../../components/Users/General/Spinner";
import { Margin } from "@mui/icons-material";
import css from "./style.module.css";


export default function AboutUsPage() {
    //contexts
    useEffect(() => {
        aboutCtx.AboutUs();
    }, []);
    const aboutCtx = useContext(AboutUsContext);
    // const [about, SetAbout] = useState(aboutCtx.state.categories[0].text)
    const _id = "643636e1cd7ecccb39bbd3f9"
    const [photoFile, setPhoto] = useState();
    // const [photoFile2, setPhoto2] = useState();
    const [photoURL, setPhotoURL] = useState("");
    // const [photoUR2L, setPhotoURL2] = useState("");

    // const handleInputChange = (event) => {
    //     const value = event.target.value;
    //     SetAbout(value)
    // };
    //===============================================
    const [formData, setFormData] = useState({
        about: aboutCtx.state.categories[0].text,
        title1: aboutCtx.state.categories[0].title1,
        title2: aboutCtx.state.categories[0].title2,
        title3: aboutCtx.state.categories[0].title3,
        title4: aboutCtx.state.categories[0].title4,
        title5: aboutCtx.state.categories[0].title5,
        context1: aboutCtx.state.categories[0].context1,
        context2: aboutCtx.state.categories[0].context2,
        context3: aboutCtx.state.categories[0].context3,
        context4: aboutCtx.state.categories[0].context4,
        context5: aboutCtx.state.categories[0].context5,
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    //==============================================

    function handleFileChange(event, id) {
        const file = event.target.files[0];
        setPhoto(file);
        setPhotoURL(URL.createObjectURL(file));
    }
    // function handleFileChange2(event, id) {
    //     const file2 = event.target.files[0];
    //     setPhoto2(file2);
    //     setPhotoURL2(URL.createObjectURL(file2));
    // }

    return (
        <div className="content-wrapper">
            <div className="content-header ml-2 d-inline-flex">
                <h1>Бидний тухай</h1>
            </div>
            <div className="content">
                {aboutCtx.state.loading && <Spinner />}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header border-0 helper-header">
                                    <div className="d-flex justify-content-center">
                                        <Box sx={{ height: "10%", width: "100%" }}>
                                            <Typography
                                                variant="h3"
                                                component="h3"
                                                sx={{ textAlign: "center", mt: 3, mb: 3 }}
                                            />
                                            <form
                                                className="helper-form"
                                            >
                                                <div className="row px-4">
                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Компанийн танилцуулга :</label>
                                                            <textarea
                                                                rows="5"
                                                                className="form-control"
                                                                name="about"
                                                                value={formData.about}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row px-4">
                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Гарчиг 1 :</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title1"
                                                                value={formData.title1}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Утга :</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="context1"
                                                                value={formData.context1}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Гарчиг 2 :</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title2"
                                                                value={formData.title2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Утга :</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="context2"
                                                                value={formData.context2}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Гарчиг 3 :</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title3"
                                                                value={formData.title3}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Утга :</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="context3"
                                                                value={formData.context3}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Гарчиг 4 :</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title4"
                                                                value={formData.title4}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Утга :</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="context4"
                                                                value={formData.context4}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg">
                                                        <div className="form-group">
                                                            <label>Гарчиг 5 :</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="title5"
                                                                value={formData.title5}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Утга :</label>
                                                            <textarea
                                                                rows="6"
                                                                className="form-control"
                                                                name="context5"
                                                                value={formData.context5}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={css.rowSize}>
                                                    <div className="row align-items-center">
                                                        <div className="col-7">
                                                            <Image width={350}
                                                                src={
                                                                    photoURL !== "" ? photoURL :
                                                                        process.env.REACT_APP_BASE_URL +
                                                                        "/public/image/about/" + "u1.png"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            <input
                                                                type="file"
                                                                className="form-control-file ml-2"
                                                                onChange={(event) => handleFileChange(event)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="row align-items-center">
                                                        <div className="col-7">
                                                            <Image width={350}
                                                                src={
                                                                    photoUR2L !== "" ? photoUR2L :
                                                                        process.env.REACT_APP_BASE_URL +
                                                                        "/public/image/about/" + "u2.png"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            <input
                                                                type="file"
                                                                className="form-control-file ml-2"
                                                                onChange={(event) => handleFileChange2(event)}
                                                            />
                                                        </div>
                                                    </div> */}

                                                </div>
                                                <AboutActions {...{ _id, formData, photoFile }} />
                                            </form>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
